import { useEffect, useState } from 'react';
import { ApptTab } from './AppointmentTabs';

const PatientColumn = ({
  patientName,
  appointment,
  tab,
}: {
  patientName: string;
  appointment: any;
  tab: ApptTab;
}): JSX.Element => {
  const showIncrementalTimer: boolean = ['arrived', 'checked-in'].includes(appointment.status);
  const [date, setDate] = useState<string>('');

  useEffect(() => {
    const interval = setInterval(
      () => setDate(formatElapsedTime(calculateElapsedTime(appointment.meta.lastUpdated))),
      1000
    );

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [appointment.meta.lastUpdated]);

  const calculateElapsedTime = (lastUpdated: string): { hours: number; minutes: number; seconds: number } => {
    const lastUpdatedTime = new Date(lastUpdated);
    const currentTime = new Date();

    const diffInMs = currentTime.getTime() - lastUpdatedTime.getTime();
    const hours = Math.floor(diffInMs / (1000 * 60 * 60));
    const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diffInMs % (1000 * 60)) / 1000);

    return { hours, minutes, seconds };
  };

  // Format elapsed time for display
  const formatElapsedTime = ({
    hours,
    minutes,
    seconds,
  }: {
    hours: number;
    minutes: number;
    seconds: number;
  }): string => {
    if (hours === 0 && minutes === 0) {
      return `${seconds}s`;
    } else if (hours === 0) {
      return `${minutes}m ${seconds.toString().padStart(2, '0')}s`;
    }

    return `${hours}h ${minutes}m ${seconds}s`;
  };

  let appointmentWaitingTime: number | string | null = null;
  let appointmentSessionTime: number | string | null = null;

  if (appointment?.extension) {
    const waitingTime = appointment.extension.find((ext: { url: string }) => ext.url === 'waiting-time');
    const sessionTime = appointment.extension.find((ext: { url: string }) => ext.url === 'session-time');
    appointmentWaitingTime = waitingTime?.valueDuration?.value || null;
    appointmentSessionTime = sessionTime?.valueDuration?.value || null;
  }

  const obj: { [key in ApptTab]: string } = {
    arrived: 'Wait Time: ',
    'checked-in': 'Session Time: ',
    [ApptTab['proposed']]: '',
    [ApptTab['prebooked']]: '',
    [ApptTab['booked']]: '',
    [ApptTab['pending']]: '',
    [ApptTab['fulfilled']]: '',
    [ApptTab['cancelled']]: '',
    [ApptTab['noshow']]: '',
    [ApptTab['entered-in-error']]: '',
    [ApptTab['waitlist']]: '',
    [ApptTab['in-office']]: '',
    [ApptTab['completed']]: '',
  };

  return (
    <>
      {patientName}
      {showIncrementalTimer && (
        <div style={{ fontSize: '14px' }}>
          {`${obj[tab]}`}
          {date}
        </div>
      )}
      {appointmentWaitingTime ? (
        <div style={{ fontSize: '14px' }}>Wait Time: {Math.round(Number(appointmentWaitingTime))}m</div>
      ) : (
        ''
      )}
      {appointmentSessionTime ? (
        <div style={{ fontSize: '14px' }}>Session Time: {Math.round(Number(appointmentSessionTime))}m</div>
      ) : (
        ''
      )}
    </>
  );
};

export default PatientColumn;
