/* eslint-disable @typescript-eslint/explicit-function-return-type */
// import { ProfileResource } from '@medplum/core';
import { Questionnaire, Organization } from '@medplum/fhirtypes';
import { Document, useMedplum } from '@medplum/react';
import { useEffect, useState } from 'react';
// import { showNotification } from '@mantine/notifications';
import { useParams } from 'react-router-dom';
// import { IntakeQuestionnaireContext } from '../Questionnaire.context';
// import { Loading } from '../../patient-intake/components/Loading';
// import { myAppLogo } from '../../../src/assets/index';
// import { Box, Grid } from '@mui/material';
// import CustomContainer from '../../components/CustomContainer';
// import ScheduleComponent from './schedulecomponent';
// import { ErrorDialog } from './components/ErrorDialog';
import { useTranslation } from 'react-i18next';
// import { IconCircleCheck, IconCircleOff } from '@tabler/icons-react';
// import { UserForm } from './user';
// import { Loading } from './patient-intake/components/Loading';
import { myAppLogo } from './assets';
import CustomContainer from './components/CustomContainer';
import { UserForm } from './pages/patient-intake-form-public/user';
import { Box } from '@mui/system';
import { ErrorDialog } from './pages/patient-intake-form-public/components/ErrorDialog';

// interface AppointmentState {
//   appointmentID?: string;
//   appointmentDate?: string;
//   visitType?: 'prebook' | 'now';
//   visitService?: 'in-person' | 'telemedicine';
//   selectedSlot?: string;
//   locationID?: string;
//   providerID?: string;
//   groupID?: string;
//   scheduleType?: 'location' | 'provider';
//   timezone: string;
// }

export function PatientIntakeForm(): JSX.Element {
  // const navigate = useNavigate();
  const medplum = useMedplum();
  const { organizationName } = useParams();
  const [choiceErrorDialogOpen, setChoiceErrorDialogOpen] = useState(false);
  const { t } = useTranslation();
  const [_questionnaire, setQuestionnaire] = useState<Questionnaire | null>(null);
  const [organizationData, setOrganizationData] = useState<Organization>();
  const [isUserSubmitted, _setIsUserSubmitted] = useState<boolean>(false);
  // const [user, setUser] = useState<User>();
  // const [profile, setProfile] = useState<ProfileResource>();

  const [locations, setLocations] = useState([]);
  const [providers, setProviders] = useState([]);

  // const [appointmentState, setAppointment] = useState<AppointmentState>({
  //   appointmentID: '',
  //   appointmentDate: '',
  //   visitType: 'prebook',
  //   visitService: 'in-person',
  //   selectedSlot: '',
  //   locationID: '',
  //   providerID: '',
  //   groupID: '',
  //   scheduleType: 'location',
  //   timezone: '',
  // });

  // const queryParams = new URLSearchParams(location.search);

  // const questionnaireFormName = queryParams.get('name');

  // const profile = useMedplumProfile();
  // const theme = useTheme();

  // const { patientId } = useParams();
  // const patient = useResource<Patient>({ reference: `Patient/${patientId}` });
  // const { questionnaire } = useContext(IntakeQuestionnaireContext);

  // const getTempToken = async () => {
  //   try {
  //     // const { codeChallenge, codeChallengeMethod } = await medplum.startPkce();

  //     // const pkceState = getRandomString();
  //     // sessionStorage.setItem('pkceState', pkceState);

  //     const codeVerifier = getRandomString();
  //     sessionStorage.setItem('codeVerifier', codeVerifier);

  //     const arrayHash = await encryptSHA256(codeVerifier);
  //     const codeChallenge = arrayBufferToBase64(arrayHash)
  //       .replaceAll('+', '-')
  //       .replaceAll('/', '_')
  //       .replaceAll('=', '');
  //     // sessionStorage.setItem('codeChallenge', codeChallenge);

  //     const codeChallengeMethod = 'S256';

  //     // const res = await medplum.botLogin({ codeChallenge, codeChallengeMethod });
  //     const res = await medplum.botLogin({ codeChallenge, codeChallengeMethod });
  //     const { login = null } = res;
  //     if (login) {
  //       await medplum.get('auth/login/' + login.id);
  //       const auth_token = await medplum.processCodeCustomBot(login.code, codeVerifier, login);
  //       // console.log('auth_token', auth_token);
  //       localStorage.setItem('temp_auth_token', auth_token);
  //       // eslint-disable-next-line @typescript-eslint/no-floating-promises
  //       await getForm(auth_token);

  //       setToken(auth_token);
  //     }
  //   } catch (error) {
  //     console.log('error', error);
  //   }
  // };

  // useEffect(() => {
  //   // eslint-disable-next-line @typescript-eslint/no-floating-promises
  //   getTempToken();
  // }, []);

  // const getForm = async (token: string): Promise<any> => {
  //   try {
  //     const QuestionnaireRes = await medplum.searchOne(
  //       'Questionnaire',
  //       {
  //         name: 'patient-intake',
  //         _sort: '-_lastUpdated',
  //       },
  //       {
  //         headers: {
  //           Authorization: 'Bearer ' + token,
  //         },
  //       }
  //     );

  //     console.log('QuestionnaireRes', QuestionnaireRes);
  //     if (QuestionnaireRes) {
  //       setQuestionnaire(QuestionnaireRes);
  //     }
  //   } catch (error) {
  //     console.log('error', error);
  //   }
  // };

  // useEffect(() => {
  //   console.log('token', token);
  //   if (token) {
  //     // eslint-disable-next-line no-void
  //     void getForm(token);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [token]);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const getQuestionnaire = async () => {
    try {
      const QuestionnaireRes = await medplum.get(`auth/get-intake-form/${organizationName}`);

      if (QuestionnaireRes) {
        const { questionnaire, organization } = QuestionnaireRes;
        setQuestionnaire(questionnaire);
        setOrganizationData(organization);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const fetchPublicData = async () => {
    try {
      const QuestionnaireRes = await medplum.get(`public/providers`);

      if (QuestionnaireRes) {
        const { locationsWithPractitioners, practitioners } = QuestionnaireRes;
        setLocations(locationsWithPractitioners);
        setProviders(practitioners);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getQuestionnaire();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchPublicData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleOnSubmit = useCallback(
  //   // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  //   async (response: QuestionnaireResponse | any) => {
  //     if ((appointmentState.selectedSlot?.length ?? 0) < 1) {
  //       return setChoiceErrorDialogOpen(true);
  //     } else {
  //       // Define a mapping for the keys you want
  //       // const keyMapping: any = {
  //       //   firstName: 'firstName',
  //       //   lastName: 'lastName',
  //       //   userName: 'userName',
  //       //   'patient-email': 'email',
  //       //   'patient-mobile': 'phone',
  //       //   dob: 'dob',
  //       // };

  //       // // Extract values
  //       // const result: any = {};
  //       // response.item.forEach((section: { item: any[] }) => {
  //       //   section.item.forEach((field) => {
  //       //     const key = keyMapping[field.linkId]; // Using the linkId as the key
  //       //     if (key) {
  //       //       const answer = field.answer[0]; // Assumes each field has at least one answer
  //       //       if (answer) {
  //       //         result[key] =
  //       //           answer.valueString || answer.valueDate || answer.valueDateTime || answer.valueBoolean || null;
  //       //       }
  //       //     }
  //       //   });
  //       // });

  //       const appointmentItem = {
  //         id: 'id-13',
  //         linkId: 'appointment-date',
  //         text: 'Appointment Date',
  //         answer: [
  //           {
  //             valueDateTime: appointmentState.selectedSlot?.toString(),
  //             id: appointmentState.timezone,
  //           },
  //         ],
  //         item: undefined,
  //       };

  //       const isPageExist = response.item[0]?.item;

  //       if (isPageExist) {
  //         const lastItem = response.item[0].item[response.item[0]?.item?.length - 1];
  //         if (lastItem?.linkId !== 'appointment-date') {
  //           response.item[0].item.push(appointmentItem);
  //         }
  //       } else {
  //         response.item.push(appointmentItem);
  //       }

  //       // await medplum
  //       //   .createResource<QuestionnaireResponse>(
  //       //     {
  //       //       ...response,
  //       //       subject: 'Patient/d07c5beb-9f7f-4f58-accb-97dc0bd1a1d6',
  //       //       author: 'Patient/d07c5beb-9f7f-4f58-accb-97dc0bd1a1d6',
  //       //       // subject: createReference(patient),
  //       //       // author: createReference(patient),
  //       //     },
  //       //     {
  //       //       headers: {
  //       //         'Content-Type': 'application/json',
  //       //         Authorization:
  //       //           'Bearer ' +
  //       //           'eyJhbGciOiJSUzI1NiIsImtpZCI6ImE3Y2NlMTkxLWY2MzItNDliYi1hOWFlLTNmODhkNGEzYjY3NSIsInR5cCI6IkpXVCJ9.eyJsb2dpbl9pZCI6Ijg1NzU2ZDBjLWM4YjItNGYwOS04ZDFmLTI1NzhiODhmMDcyMiIsInN1YiI6IjUzMjJiNzMwLTJkYzctNGNiZi05ZDMwLWEwN2QxN2ViNTJlZCIsInVzZXJuYW1lIjoiNTMyMmI3MzAtMmRjNy00Y2JmLTlkMzAtYTA3ZDE3ZWI1MmVkIiwic2NvcGUiOiJvcGVuaWQiLCJwcm9maWxlIjoiUHJhY3RpdGlvbmVyL2RkYTUyYmMyLTRiZmMtNDExOC05NTEzLTI2YzRmZDBiZjQyZCIsImlhdCI6MTcyNzMzNzQwNCwiaXNzIjoiaHR0cDovL2xvY2FsaG9zdDo4MTAzLyIsImV4cCI6MTcyNzM0MTAwNH0.o3Hb1b-Ep0jb-iFJ-zElGSC6TdeQ_q80YJv9PkhZ2TiY4BEkonV2KsSkwLD9cnaTVp8zg3Tl19dqDNCG1-jz732rJ0pY_xm65Q4OO0InXihkoB8en9-bAu0I9pKBCJ7ne7AjXErmEY64TDNMHVNQjg5shylNpCCzzOF_-3_bF46r0n2Kt7d1IK0RBLeqB3TjJfBpafHxGC_ZBp8w_HcqO7LfUuukpOH6icH0TrgWqgPy31DyShdbRuTF8yBMdd7Ms5_p5MIXB-DDW4_zq5kcNEEZKR5UR64S-i45kqfcOgaqJwrlpes4kGXooOxqKIFDl13F2ta6jufeC2hhzTZ13g',
  //       //       },
  //       //     }
  //       //   )
  //       //   .then(() => {
  //       //     showNotification({
  //       //       icon: <IconCircleCheck />,
  //       //       title: 'Success',
  //       //       message: 'Answers recorded',
  //       //     });
  //       //     // navigate(`Patient/d07c5beb-9f7f-4f58-accb-97dc0bd1a1d6`);
  //       //     window.scrollTo(0, 0);
  //       //   })
  //       //   .catch((err) => {
  //       //     console.log('err', err);
  //       //     showNotification({
  //       //       color: 'red',
  //       //       icon: <IconCircleOff />,
  //       //       title: 'Error',
  //       //       message: normalizeErrorString(err),
  //       //     });
  //       //   });

  //       try {
  //         await medplum.submitQuestionnaire({
  //           organization: organizationData?.id,
  //           intakeQuestionnaireResponse: response,
  //           questionnaireId: questionnaire?.id,
  //           userId: user?.id,
  //           profileId: profile?.id,
  //           email: user?.email ?? '',
  //           appointment: {
  //             resourceType: 'Appointment',
  //             status: 'booked',
  //             start: appointmentState.selectedSlot,
  //             end: appointmentState.selectedSlot,
  //             // serviceType: [{ coding: [answers['service-type'].valueCoding as Coding] }],
  //             participant: [
  //               {
  //                 actor: createReference(profile),
  //                 status: 'accepted',
  //               },
  //               {
  //                 actor: createReference(user),
  //                 status: 'accepted',
  //               },
  //             ],
  //           },
  //         });

  //         showNotification({
  //           icon: <IconCircleCheck />,
  //           title: 'Success',
  //           message: 'Intake form submitted successfully',
  //         });

  //         return navigate('/get-started');
  //       } catch (error) {
  //         console.log('error', error);
  //         showNotification({
  //           color: 'red',
  //           icon: <IconCircleOff />,
  //           title: 'Error',
  //           message: normalizeErrorString(error),
  //           // message: 'Something went wrong! Please try again later.',
  //         });
  //       }

  //       // console.log('membership_id', membership_id);

  //       // try {
  //       //   const projectMembership = await medplum.readResource('ProjectMembership', membership_id?.membership);
  //       //   console.log('projectMembership', projectMembership);
  //       // } catch (error) {
  //       //   console.log('error', error);
  //       // }

  //       // const patient = await medplum.readResource<ProjectMembership>({
  //       //   resourceType: 'ProjectMembership',
  //       //   id: membership_id.membership,
  //       // });
  //       // const patient = await medplum.readResource<ProjectMembership>({ resourceType: 'ProjectMembership', id: membership_id.membership });

  //       // medplum
  //       //   .startNewPatient({ login, projectId: projectId as string })
  //       //   .then((response) => medplum.processCode(response.code as string))
  //       //   .then(() => onSuccess())
  //       //   .catch((err) => setOutcome(normalizeOperationOutcome(err)));

  //       // console.log('handleOnSubmit questionnaire', questionnaire)
  //       // console.log('handleOnSubmit patient', patient)
  //       // console.log('handleOnSubmit patient', patient)
  //       // if (!questionnaire || !patient || !profile) {
  //       //   return;
  //       // }
  //       // medplum
  //       //   .createResource<QuestionnaireResponse>({
  //       //     ...response,
  //       //     // subject: createReference(patient),
  //       //     // author: createReference(profile),
  //       //   })
  //       //   .then(() => {
  //       //     showNotification({
  //       //       icon: <IconCircleCheck />,
  //       //       title: 'Success',
  //       //       message: 'Answers recorded',
  //       //     });
  //       //     // navigate(`/Patient/${patient.id}`);
  //       //     window.scrollTo(0, 0);
  //       //   })
  //       //   .catch((err) => {
  //       //     console.log('err', err);
  //       //     showNotification({
  //       //       color: 'red',
  //       //       icon: <IconCircleOff />,
  //       //       title: 'Error',
  //       //       message: normalizeErrorString(err),
  //       //     });
  //       //   });
  //     }
  //   },
  //   // [medplum, navigate, questionnaire, profile, patient]
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [appointmentState]
  // );

  // if (!questionnaire) {
  //   return <Loading />;
  // }
  const bgVariant = 'welcome';

  // const handleSubmit = (response: any): void => {
  //   if (response) {
  //     setUser(response.user);
  //     setProfile(response.profile);
  //     setIsUserSubmitted(true);
  //   }
  // };

  const [formData, _setFormData] = useState({
    firstName: '',
    lastName: '',
    dateOfBirth: null,
    phoneNumber: '',
    email: '',
    preferredProvider: '',
    preferredLocation: '',
    appointmentDate: null,
    appointmentTime: null,
    reason: '',
  });

  // const handleInputChange = (e: { target: { name: any; value: any } }) => {
  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  // const handleDateChange = (name: string, value: null) => {
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  const handleSubmit = () => {
    console.log('Form Data: ', formData);
  };

  return (
    <Document width={800}>
      <CustomContainer
        title={''}
        // subtitle={appointmentsLoading || locationLoading ? 'Loading...' : `${locObjJson?.name}`}
        subtitle={''}
        // subtext={appointmentsLoading || locationLoading ? '' : 'subtext'}
        subtext={''}
        isFirstPage
        img={myAppLogo}
        imgAlt="Ottehr Icon"
        imgWidth={90}
        bgVariant={bgVariant}
        // outsideCardComponent={
        //   visitType === VisitType.PreBook && officeOpen ? (
        //     <></>
        //   ) : // <WaitingEstimateCard waitingMinutes={waitingMinutes} />
        //   undefined
        // }
      >
        {/* <AppBar
          position="absolute"
          sx={{
            border: `1px solid ${theme.palette.primary.dark}`,
          }}
        > */}
        <Box
          component="img"
          sx={{ margin: 1, width: 200, alignSelf: 'center', minHeight: '39px', maxHeight: '160px' }}
          alt={'logo'}
          src={myAppLogo}
        />
        {/* </AppBar> */}
      </CustomContainer>
      {isUserSubmitted ? (
        <></>
      ) : (
        // <Grid
        //   container
        //   justifyItems="center"
        //   style={{
        //     marginTop: '30px',
        //   }}
        // >
        // <QuestionnaireForm
        //   questionnaire={questionnaire}
        //   onSubmit={handleOnSubmit}
        //   setChoiceErrorDialogOpen={setChoiceErrorDialogOpen}
        //   selectedSlot={appointmentState.selectedSlot}
        //   ScheduleComponent={<></>}
        // <ScheduleComponent appointmentState={appointmentState} setAppointment={setAppointment} />
        // />
        // {
        /* <ErrorDialog
            open={choiceErrorDialogOpen}
            title={t('welcome.dateError.title')}
            description={t('welcome.dateError.description')}
            closeButtonText={t('general.button.close')}
            handleClose={() => setChoiceErrorDialogOpen(false)}
          /> */
        // }
        // </Grid>
        <></>
        // <UserForm handleAuthResponse={handleSubmit} organization={organizationData?.id} />
      )}

      <UserForm
        handleAuthResponse={handleSubmit}
        organization={organizationData?.id}
        locations={locations}
        providers={providers}
        setProviders={setProviders}
        setChoiceErrorDialogOpen={setChoiceErrorDialogOpen}
      />
      <ErrorDialog
        open={choiceErrorDialogOpen}
        title={t('welcome.dateError.title')}
        description={t('welcome.dateError.description')}
        closeButtonText={t('general.button.close')}
        handleClose={() => setChoiceErrorDialogOpen(false)}
      />
    </Document>
  );
}
