import { Paper, ScrollArea, Tabs } from '@mantine/core';
import { Document, Loading, useMedplum } from '@medplum/react';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { InfoBar } from '../components/InfoBar';
import { getProjectId } from '../utils';

const tabs = ['Details', 'Users', 'Patients', 'Clients', 'Bots', 'Secrets', 'Sites'];

export function ProjectPage(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const currentTab = location.pathname.replace('/admin/', '') || tabs[0];
  const medplum = useMedplum();
  const projectId = getProjectId(medplum);

  const [result, setResult] = useState<{ project?: { name?: string } } | null>(null);
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const fetchProjects = async () => {
    const result = await medplum.get(`admin/projects/${projectId}`);

    setResult(result);
  };

  useEffect(() => {
    if (!result) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchProjects();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handles a tab change event.
   * @param newTabName - The new tab name.
   */
  function onTabChange(newTabName: string | null): void {
    navigate(`/admin/${newTabName}`);
  }

  if (!result) {
    return <Loading />;
  }

  return (
    <>
      <Paper>
        <InfoBar>
          <InfoBar.Entry>
            <InfoBar.Key>Project</InfoBar.Key>
            <InfoBar.Value>{result?.project?.name}</InfoBar.Value>
          </InfoBar.Entry>
        </InfoBar>
        <ScrollArea>
          <Tabs value={currentTab.toLowerCase()} onChange={onTabChange}>
            <Tabs.List style={{ whiteSpace: 'nowrap', flexWrap: 'nowrap' }}>
              {tabs.map((t) => (
                <Tabs.Tab key={t} value={t.toLowerCase()}>
                  {t}
                </Tabs.Tab>
              ))}
            </Tabs.List>
          </Tabs>
        </ScrollArea>
      </Paper>
      <Document>
        <Outlet />
      </Document>
    </>
  );
}
