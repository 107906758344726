export const palette = {
  text: {
    primary: '#212130',
    secondary: '#4F4F4F',
    disabled: '#C3C9D2',
    cancelled: '#D91B1B',
  },
  primary: {
    main: '#061B74',
    contrast: '#FFFFFF',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#2169F5',
    contrast: '#FFFFFF',
    contrastText: '#FFFFFF',
  },
  tertiary: { main: '#ECE4FB' },
  step: {
    main: '#17C4F3',
  },
  info: {
    main: '#C1FBEA',
  },
  success: {
    main: '#66BA70',
  },
  warning: {
    main: '#FFDF9A',
  },
  error: {
    main: '#EC6930',
  },
  destructive: { main: '#EB5757' },
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(0, 0, 0, 0.04)',
    selected: 'rgba(0, 0, 0, 0.08)',
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
    focus: 'rgba(0, 0, 0, 0.12)',
  },
  background: {
    default: '#15376A',
    paper: '#FFFFFF',
    cancelled: '#FCBDBD',
  },
  divider: '#C3C9D2',
};

export const otherColors = {
  appointmentInfoBackground: '#8F66EF',
  appbarBackground: '#0A2143',
  background: '#5324BE',
  borderGray: '#D6D8DF',
  borderLightBlue: '#4294F3',
  checkIcon: '#7045F2',
  clearImage: '#EB5757',
  coachingVisit: '#aed4fc',
  lightBlue: '#E2F0FF',
  darkPurple: '#301367',
  brightPurple: '#2169F5',
  darkGreen: '#0F5A4C',
  lightGreen: '#C1FBEA',
  purple: '#2169F5',
  brown: '#604203',
  white: '#FFFFFF',
  lightPurple: '#F5F2FF',
  languageIcon: 'rgba(15, 229, 189, 1)',
  lightGray: '#CED4DA',
  placeholder: '#A9A9A9',
  primaryBackground: '#F5F2FF',
  popupBackground: 'rgba(97, 97, 97, 0.9)',
  primaryBoxShadow: 'rgba(77, 21, 183, 0.25)',
  scheduleBorder: '#8F9AA7',
  translateIcon: '#0FE5BD',
  transparent: 'rgba(0, 0, 0, 0)',
  patientGreen: '#C7FDD3',
  patientSubtitle: '#545454',
  wrongPatient: '#8F9AA7',
  cardBackground: '#F7F8F9',
  cancel: '#B22020',
  lightCancel: '#FFD8D8',
  black: '#000000',
  toolTipGrey: '#F9FAFB',
  toolTipClose: '#938B7D',
  priorityHighText: '#E53935',
  priorityHighIcon: '#F44336',
  orange700: '#F57C00',
  orange800: '#EF6C00',
  orange100: '#FFE0B2',
  locationGeneralBlue: '#CFF5FF',
  warningIcon: '#ED6C02',
  warningText: '#5F2B00',
  warningBackground:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, #ED6C02, #ED6C02)',
  noteText: '#00000099',
  none: '#00000061',
  dialogNote: '#FCD29973',
  selectMenuHover: '#F8F6FC',
  insuranceChip: '#43A047',
  consentChip: '#1E88E5',
  cardChip: '#7CB342',
  idChip: '#00ACC1',
  badgeDot: '#FB8C00',
  closeCross: '#773602CC',
  apptHover: '#F4F6F8',
  tableRow: 'rgba(0,0,0,0.87)',
  headerBackground: '#15376a',
};
