/* eslint-disable @typescript-eslint/no-unused-vars */
// import FmdBadOutlinedIcon from '@mui/icons-material/FmdBadOutlined';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { Location } from 'fhir/r4';
import { DateTime } from 'luxon';
import { ReactElement, useState } from 'react';
// import { UCAppointmentInformation } from 'ehr-utils';
// import { otherColors } from '../CustomThemeProvider';
import AppointmentTable from './AppointmentTable';
import Loading from './Loading';
// import { otherColors } from '../../ottehr';

export enum ApptTab {
  'proposed' = 'proposed',
  'prebooked' = 'prebooked',
  'booked' = 'booked',
  'pending' = 'pending',
  'arrived' = 'arrived',
  'fulfilled' = 'fulfilled',
  'cancelled' = 'cancelled',
  'noshow' = 'noshow',
  'entered-in-error' = 'entered-in-error',
  'checked-in' = 'checked-in',
  'waitlist' = 'waitlist',
  'in-office' = 'in-office',
  'completed' = 'completed',
}

interface AppointmentsTabProps {
  location: Location | undefined;
  providers: string[] | undefined;
  groups: string[] | undefined;
  preBookedAppointments: any[]; //UCAppointmentInformation[];
  completedAppointments: any[]; //UCAppointmentInformation[];
  cancelledAppointments: any[]; //UCAppointmentInformation[];
  inOfficeAppointments: any[]; //UCAppointmentInformation[];
  proposedAppointments: any[];
  pendingAppointments: any[];
  bookedAppointments: any[];
  arrivedAppointments: any[];
  fulfilledAppointments: any[];
  noshowAppointments: any[];
  checkedInAppointments: any[];
  waitingAppointments: any[];
  enteredInErrorAppointments: any[];
  loading: boolean;
  updateAppointments: () => void;
  setEditingComment: (editingComment: boolean) => void;
}

export default function AppointmentTabs({
  location,
  providers,
  groups,
  // preBookedAppointments,
  // completedAppointments,
  cancelledAppointments,
  // inOfficeAppointments,
  proposedAppointments,
  pendingAppointments,
  bookedAppointments,
  arrivedAppointments,
  fulfilledAppointments,
  noshowAppointments,
  checkedInAppointments,
  waitingAppointments,
  enteredInErrorAppointments,
  loading,
  updateAppointments,
  setEditingComment,
}: AppointmentsTabProps): ReactElement {
  const [value, setValue] = useState<ApptTab>(ApptTab['booked']);
  const [now, _setNow] = useState<DateTime>(DateTime.now());

  const handleChange = (event: any, newValue: ApptTab): any => {
    setValue(newValue);
  };

  // React.useEffect(() => {
  //   function updateTime(): void {
  //     setNow(DateTime.now());
  //   }

  //   const timeInterval = setInterval(updateTime, 1000);
  //   // Call updateTime so we don't need to wait for it to be called
  //   updateTime();

  //   return () => clearInterval(timeInterval);
  // }, []);

  // const selectLocationMsg = !location && providers?.length === 0 && groups?.length === 0 && (
  //   <Grid container sx={{ width: '40%' }} padding={4}>
  //     <Grid item xs={2}>
  //       <FmdBadOutlinedIcon
  //         sx={{
  //           width: 62,
  //           height: 62,
  //           color: otherColors.orange700,
  //           borderRadius: '50%',
  //           backgroundColor: otherColors.orange100,
  //           padding: 1.4,
  //         }}
  //       ></FmdBadOutlinedIcon>
  //     </Grid>
  //     <Grid
  //       item
  //       xs={10}
  //       sx={{
  //         display: 'flex',
  //         flexDirection: 'column',
  //         justifyContent: 'center',
  //       }}
  //     >
  //       <Typography sx={{ fontWeight: 'bold' }}>Please select an office, provider, or group</Typography>
  //       <Typography>Please select an office, provider, or group to get appointments</Typography>
  //     </Grid>
  //   </Grid>
  // );

  return (
    <>
      <Box sx={{ width: '100%', marginTop: 3 }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="appointment tabs">
              <Tab
                label={`Proposed${proposedAppointments ? ` – ${proposedAppointments?.length}` : ''}`}
                value={ApptTab.proposed}
                sx={{ textTransform: 'none', fontWeight: 700 }}
              />
              <Tab
                label={`Pending${pendingAppointments ? ` – ${pendingAppointments?.length}` : ''}`}
                value={ApptTab.pending}
                sx={{ textTransform: 'none', fontWeight: 700 }}
              />
              <Tab
                label={`Booked${bookedAppointments ? ` – ${bookedAppointments?.length}` : ''}`}
                value={ApptTab.booked}
                sx={{ textTransform: 'none', fontWeight: 700 }}
              />
              <Tab
                label={`Arrived${arrivedAppointments ? ` – ${arrivedAppointments?.length}` : ''}`}
                value={ApptTab.arrived}
                sx={{ textTransform: 'none', fontWeight: 700 }}
              />
              <Tab
                label={`In-Session${checkedInAppointments ? ` – ${checkedInAppointments?.length}` : ''}`}
                value={ApptTab['checked-in']}
                sx={{ textTransform: 'none', fontWeight: 700 }}
              />
              <Tab
                label={`Fulfilled${fulfilledAppointments ? ` – ${fulfilledAppointments?.length}` : ''}`}
                value={ApptTab.fulfilled}
                sx={{ textTransform: 'none', fontWeight: 700 }}
              />
              {/* <Tab
                label={`Cancelled${cancelledAppointments ? ` – ${cancelledAppointments?.length}` : ''}`}
                value={ApptTab.cancelled}
                sx={{ textTransform: 'none', fontWeight: 700 }}
              /> */}
              <Tab
                label={`Cancelled${cancelledAppointments ? ` – ${cancelledAppointments?.length}` : ''}`}
                value={ApptTab.cancelled}
                sx={{ textTransform: 'none', fontWeight: 700 }}
              />
              <Tab
                label={`No Show${noshowAppointments ? ` – ${noshowAppointments?.length}` : ''}`}
                value={ApptTab.noshow}
                sx={{ textTransform: 'none', fontWeight: 700 }}
              />
              <Tab
                label={`Wait List${waitingAppointments ? ` – ${waitingAppointments?.length}` : ''}`}
                value={ApptTab.waitlist}
                sx={{ textTransform: 'none', fontWeight: 700 }}
              />

              <Tab
                label={`Entered in Error${enteredInErrorAppointments ? ` – ${enteredInErrorAppointments?.length}` : ''}`}
                value={ApptTab['entered-in-error']}
                sx={{ textTransform: 'none', fontWeight: 700 }}
              />
              {loading && <Loading />}
            </TabList>
          </Box>
          <TabPanel value={ApptTab.proposed} sx={{ padding: 0 }}>
            {/* {selectLocationMsg || ( */}
            <AppointmentTable
              appointments={proposedAppointments}
              location={location}
              tab={value}
              now={now}
              updateAppointments={updateAppointments}
              setEditingComment={setEditingComment}
            ></AppointmentTable>
            {/* )} */}
          </TabPanel>
          <TabPanel value={ApptTab.pending} sx={{ padding: 0 }}>
            {/* {selectLocationMsg || ( */}
            <AppointmentTable
              appointments={pendingAppointments}
              location={location}
              tab={value}
              now={now}
              updateAppointments={updateAppointments}
              setEditingComment={setEditingComment}
            ></AppointmentTable>
            {/* )} */}
          </TabPanel>
          <TabPanel value={ApptTab.booked} sx={{ padding: 0 }}>
            {/* {selectLocationMsg || ( */}
            <AppointmentTable
              appointments={bookedAppointments}
              location={location}
              tab={value}
              now={now}
              updateAppointments={updateAppointments}
              setEditingComment={setEditingComment}
            ></AppointmentTable>
            {/* )} */}
          </TabPanel>
          <TabPanel value={ApptTab.arrived} sx={{ padding: 0 }}>
            {/* {selectLocationMsg || ( */}
            <AppointmentTable
              appointments={arrivedAppointments}
              location={location}
              tab={value}
              now={now}
              updateAppointments={updateAppointments}
              setEditingComment={setEditingComment}
            ></AppointmentTable>
            {/* )} */}
          </TabPanel>
          <TabPanel value={ApptTab.fulfilled} sx={{ padding: 0 }}>
            {/* {selectLocationMsg || ( */}
            <AppointmentTable
              appointments={fulfilledAppointments}
              location={location}
              tab={value}
              now={now}
              updateAppointments={updateAppointments}
              setEditingComment={setEditingComment}
            ></AppointmentTable>
            {/* )} */}
          </TabPanel>
          <TabPanel value={ApptTab.cancelled} sx={{ padding: 0 }}>
            {/* {selectLocationMsg || ( */}
            <AppointmentTable
              appointments={cancelledAppointments}
              location={location}
              tab={value}
              now={now}
              updateAppointments={updateAppointments}
              setEditingComment={setEditingComment}
            ></AppointmentTable>
            {/* )} */}
          </TabPanel>
          <TabPanel value={ApptTab.noshow} sx={{ padding: 0 }}>
            {/* {selectLocationMsg || ( */}
            <AppointmentTable
              appointments={noshowAppointments}
              location={location}
              tab={value}
              now={now}
              updateAppointments={updateAppointments}
              setEditingComment={setEditingComment}
            ></AppointmentTable>
            {/* )} */}
          </TabPanel>
          <TabPanel value={ApptTab['entered-in-error']} sx={{ padding: 0 }}>
            {/* {selectLocationMsg || ( */}
            <AppointmentTable
              appointments={enteredInErrorAppointments}
              location={location}
              tab={value}
              now={now}
              updateAppointments={updateAppointments}
              setEditingComment={setEditingComment}
            ></AppointmentTable>
            {/* )} */}
          </TabPanel>
          <TabPanel value={ApptTab['checked-in']} sx={{ padding: 0 }}>
            {/* {selectLocationMsg || ( */}
            <AppointmentTable
              appointments={checkedInAppointments}
              location={location}
              tab={value}
              now={now}
              updateAppointments={updateAppointments}
              setEditingComment={setEditingComment}
            ></AppointmentTable>
            {/* )} */}
          </TabPanel>
          <TabPanel value={ApptTab.waitlist} sx={{ padding: 0 }}>
            {/* {selectLocationMsg || ( */}
            <AppointmentTable
              appointments={waitingAppointments}
              location={location}
              tab={value}
              now={now}
              updateAppointments={updateAppointments}
              setEditingComment={setEditingComment}
            ></AppointmentTable>
            {/* )} */}
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}
