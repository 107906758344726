/* eslint-disable @typescript-eslint/no-floating-promises */
import { Card, Group, Text, Avatar, Stack } from '@mantine/core';
import { formatSearchQuery, Operator } from '@medplum/core';
import { Patient, Practitioner, PractitionerRole, ResourceType } from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Appointment } from './SchedulePage';

const TodaysSurgeries: React.FC = () => {
  const medplum = useMedplum();
  const [todaysAppointments, setTodaysAppointments] = useState<Appointment[]>([]);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const getAppointments = async () => {
    const day = dayjs(new Date()).format('YYYY-MM-DD');
    const startOfDay = `${day}T00:00:00Z`;
    const endOfDay = `${day}T23:59:59Z`;

    const query = {
      // total: 'accurate',
      fields: undefined,
      count: 100000,
      resourceType: 'Appointment',
      sortRules: [{ code: 'date', descending: true }],
      filters: [
        { code: 'date', operator: Operator.GREATER_THAN_OR_EQUALS, value: startOfDay },
        { code: 'date', operator: Operator.LESS_THAN_OR_EQUALS, value: endOfDay },
      ],
    };

    // console.log('zeeee query', query);

    const response = await medplum.search('Appointment' as ResourceType, formatSearchQuery(query), {
      cache: 'no-store',
      priority: 'low',
    });

    // console.log('zeeee response', response);

    const getTimezoneFromExtension = (resource: { extension: any[] }): string => {
      const timezoneExtension = resource.extension?.find(
        (ext) => ext.url === 'http://hl7.org/fhir/StructureDefinition/timezone'
      );

      return timezoneExtension?.valueString || '';
    };

    const _count = 10000000;
    const [locations, practitioners, practitionerRoles, patients] = await Promise.allSettled([
      (await medplum.search('Location', { _count, _sort: 'name' })).entry?.map((entry) => entry.resource as Location) ??
        [],
      (await medplum.search('Practitioner', { _count, _sort: 'name' })).entry?.map(
        (entry) => entry.resource as Practitioner
      ) ?? [],
      (await medplum.search('PractitionerRole', { _count })).entry?.map(
        (entry) => entry.resource as PractitionerRole
      ) ?? [],
      (await medplum.search('Patient', { _count })).entry?.map((entry) => entry.resource as Patient) ?? [],
    ]);

    let allLocations: Location[] = [];
    let allPractitioners: Practitioner[] = [];
    let allPractitionerRoles: PractitionerRole[] = [];
    let allPatients: Patient[] = [];

    if (locations.status === 'fulfilled') {
      allLocations = locations.value;
    } else {
      console.error('Failed to load locations', locations.reason);
    }

    if (practitioners.status === 'fulfilled') {
      allPractitioners = practitioners.value;
    } else {
      console.error('Failed to load practitioners', practitioners.reason);
    }

    if (practitionerRoles.status === 'fulfilled') {
      allPractitionerRoles = practitionerRoles.value;
    } else {
      console.error('Failed to load practitionerRoles', practitionerRoles.reason);
    }

    if (patients.status === 'fulfilled') {
      allPatients = patients.value;
    } else {
      console.error('Failed to load patients', patients.reason);
    }

    for (const entry of response.entry || []) {
      const { participant = [] } = entry.resource || {};

      let patient = participant?.find((participant: { actor: { reference: string } }) =>
        participant.actor?.reference?.startsWith('Patient')
      );

      if (patient) {
        // const patientDetails = await medplum.readResource('Patient', patient.actor?.reference?.replace('Patient/', ''));

        // const email = patientDetails?.telecom?.find((telecom: { system: string }) => telecom.system === 'email')?.value;
        // let user: User | null = null;

        // if (email) {
        //   user = await medplum.searchOne('User', `email=${email}`);
        // }

        const patientDetailsObj = allPatients.find((pat: any) => pat?.id === patient.actor.reference.split('/')[1]);

        patient = patient
          ? {
              ...patientDetailsObj,
              ...patient,
              ...patient.actor,
              actor: { ...patient.actor, reference: patient.actor?.reference?.replace('Patient/', '') },
              // user: user,
              // patientDetails,
              // email,
            }
          : undefined;
      }

      let location = participant?.find((participant: { actor: { reference: string } }) =>
        participant.actor?.reference?.startsWith('Location')
      );

      const locationRecord: Location | undefined = allLocations.find(
        (loc: any) => loc?.id === location.actor.reference.split('/')[1]
      );

      let locationAverageWaitingTime = null;
      let locationAverageSessionTime = null;

      if (locationRecord) {
        // (locationRecord as any)?.
        const locationWaitTimeExtension = (locationRecord as any)?.extension?.find(
          (ext: { url: string }) => ext.url === 'average-waiting-time'
        );

        // console.log('locationWaitTimeExtension', locationWaitTimeExtension);
        if (locationWaitTimeExtension) {
          locationAverageWaitingTime = locationWaitTimeExtension?.valueDuration?.value;
        }
        const locationAverageSessionTimeExtension = (locationRecord as any)?.extension?.find(
          (ext: { url: string }) => ext.url === 'average-session-time'
        );

        if (locationAverageSessionTimeExtension) {
          locationAverageSessionTime = locationAverageSessionTimeExtension?.valueDuration?.value;
        }

        // console.log('locationAverageSessionTimeExtension', locationAverageSessionTimeExtension);
      }

      if (location) {
        location = location
          ? {
              ...location,
              ...location.actor,
              actor: { ...location.actor, reference: location.actor?.reference?.replace('Location/', '') },
              locationAverageWaitingTime,
              locationAverageSessionTime,
            }
          : undefined;
      }

      let practitioner = participant?.find((participant: { actor: { reference: string } }) =>
        participant.actor?.reference?.startsWith('Practitioner')
      );

      // console.log('allPractitioners', allPractitioners);

      const practitionerRecord = allPractitioners.find(
        (loc: any) => loc?.id === practitioner?.actor?.reference?.split('/')[1]
      );

      let practitionerAverageWaitingTime = null;
      let practitionerAverageSessionTime = null;

      let practitionerRoleAverageWaitingTime = null;
      let practitionerRoleAverageSessionTime = null;

      if (practitionerRecord) {
        const practitionerWaitTimeExtension = practitionerRecord?.extension?.find(
          (ext) => ext.url === 'average-waiting-time'
        );

        // console.log('practitionerWaitTimeExtension', practitionerWaitTimeExtension);
        if (practitionerWaitTimeExtension) {
          practitionerAverageWaitingTime = practitionerWaitTimeExtension.valueDuration?.value;
        }
        const practitionerAverageSessionTimeExtension = practitionerRecord?.extension?.find(
          (ext) => ext.url === 'average-session-time'
        );

        if (practitionerAverageSessionTimeExtension) {
          practitionerAverageSessionTime = practitionerAverageSessionTimeExtension.valueDuration?.value;
        }

        const practitionerRoleRecord = allPractitionerRoles.find((pr: any) => {
          const prFound = pr?.practitioner?.reference === practitioner?.actor?.reference;
          const locationFound = pr?.location?.find(
            (loc: any) =>
              loc.reference === location?.actor || loc.reference === `Location/${location?.actor?.reference}`
          );
          return prFound && locationFound;
        });

        if (practitionerRoleRecord) {
          const practitionerWaitTimeExtension = practitionerRoleRecord?.extension?.find(
            (ext) => ext.url === 'average-waiting-time'
          );

          // console.log('practitionerWaitTimeExtension', practitionerWaitTimeExtension);
          if (practitionerWaitTimeExtension) {
            practitionerRoleAverageWaitingTime = practitionerWaitTimeExtension.valueDuration?.value;
          }
          const practitionerAverageSessionTimeExtension = practitionerRoleRecord?.extension?.find(
            (ext) => ext.url === 'average-session-time'
          );

          if (practitionerAverageSessionTimeExtension) {
            practitionerRoleAverageSessionTime = practitionerAverageSessionTimeExtension.valueDuration?.value;
          }

          // console.log('practitionerAverageSessionTimeExtension', practitionerAverageSessionTimeExtension);
        }

        // console.log('practitionerAverageSessionTimeExtension', practitionerAverageSessionTimeExtension);
      }

      if (practitioner) {
        practitioner = practitioner
          ? {
              ...practitioner,
              ...practitioner.actor,
              actor: { ...practitioner.actor, reference: practitioner.actor?.reference?.replace('Practitioner/', '') },
              practitionerAverageWaitingTime,
              practitionerAverageSessionTime,
              practitionerRoleAverageWaitingTime,
              practitionerRoleAverageSessionTime,
              photo: practitionerRecord?.photo?.[0]?.url || undefined,
            }
          : undefined;
      }

      const userTimezone = getTimezoneFromExtension(entry.resource);

      entry.resource = {
        ...entry.resource,
        patient,
        location,
        practitioner,
        userTimezone,
      };
    }

    const appointments =
      response?.entry
        ?.map((entry, i) => {
          return {
            // id: entry.resource?.id,
            title: `Surgery ${i + 1}`,
            start: new Date(dayjs(entry.resource?.start).toDate()),
            end: new Date(dayjs(entry.resource?.end).add(1, 'hour').toDate()),
            resource: entry.resource,
          };
        })
        .filter((el) => dayjs(el.start).isSame(day, 'day')) || [];

    setTodaysAppointments(appointments);
  };

  useEffect(() => {
    getAppointments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medplum]);

  return (
    <Card padding="md" radius="lg" style={{ width: '95%', border: '1px solid #eee' }}>
      <Text fw={700} size="md" mb="md">
        Today's Surgeries
      </Text>

      <Stack gap="xl">
        {todaysAppointments.map((appointment: Appointment) => {
          const photoUrl = appointment.resource?.patient?.photo?.[0]?.url;

          const displayNameLetters = appointment.resource?.patient?.display?.split(' ').map((word: any[]) => word[0]);

          return (
            <Group key={appointment.resource.id}>
              <Avatar src={photoUrl} radius="xl" size="md">
                {displayNameLetters}
              </Avatar>

              <div>
                <Text fw={500} size="md" c="#000000">
                  {appointment.resource?.patient?.display}'s Surgery
                </Text>
                <Text
                  size="sm"
                  style={{
                    fontSize: '13px',
                    color: '#5C5F62',
                  }}
                >
                  {dayjs(appointment.start).format('hh:mmA')} - {dayjs(appointment.end).format('hh:mmA')}
                </Text>
              </div>
            </Group>
          );
        })}
      </Stack>
    </Card>
  );
};

export default TodaysSurgeries;

// import { Card, Avatar, Text } from '@mantine/core';

// interface Doctor {
//   id: string;
//   name: string;
//   avatar: string;
// }

// interface Surgery {
//   title: string;
//   time: string;
//   doctors: Doctor[];
// }

// // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
// const SurgeryDetails = ({ surgery }: { surgery: Surgery | null }) => {
//   if (!surgery) {
//     return <Text>Select a surgery to view details</Text>;
//   }

//   return (
//     <Card shadow="sm" padding="lg">
//       <Text size="lg" w={500}>
//         {surgery.title}
//       </Text>
//       <Text color="dimmed" size="sm">
//         {surgery.time}
//       </Text>
//       {surgery.doctors.map((doc) => (
//         <div key={doc.id} style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
//           <Avatar src={doc.avatar} size="sm" />
//           <Text ml="sm">{doc.name}</Text>
//         </div>
//       ))}
//     </Card>
//   );
// };

// export default SurgeryDetails;
