import { createReference, normalizeErrorString } from '@medplum/core';
import { Patient, Questionnaire, QuestionnaireResponse } from '@medplum/fhirtypes';
import { Document, QuestionnaireForm, useMedplum, useMedplumProfile, useResource } from '@medplum/react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { showNotification } from '@mantine/notifications';
import { Loading } from '../components/Loading';
import { useParams, useNavigate } from 'react-router-dom';
import { IconCircleCheck, IconCircleOff } from '@tabler/icons-react';
import ScheduleComponent from '../../pages/patient-intake-form-public/schedulecomponent';
import { IntakeQuestionnaireContext } from '../Questionnaire.context';
import { ErrorDialog } from '../../pages/patient-intake-form-public/components/ErrorDialog';
import { useTranslation } from 'react-i18next';

interface AppointmentState {
  appointmentID?: string;
  appointmentDate?: string;
  visitType?: 'prebook' | 'now';
  visitService?: 'in-person' | 'telemedicine';
  selectedSlot?: Date | string;
  locationID?: string;
  providerID?: string;
  groupID?: string;
  scheduleType?: 'location' | 'provider';
  timezone: string;
}

export function IntakeFormPageUpdate(): JSX.Element {
  const navigate = useNavigate();
  const medplum = useMedplum();
  const profile = useMedplumProfile();

  const { patientId, responseId = '' } = useParams();
  const patient = useResource<Patient>({ reference: `Patient/${patientId}` });
  const { questionnaire, setQuestionnaire } = useContext(IntakeQuestionnaireContext);
  const [choiceErrorDialogOpen, setChoiceErrorDialogOpen] = useState(false);
  const { t } = useTranslation();
  const [existingFormData, setExistingFormData] = useState<any>(questionnaire);
  const [appointmentState, setAppointment] = useState<AppointmentState>({
    appointmentID: '',
    appointmentDate: '',
    visitType: 'prebook',
    visitService: 'in-person',
    selectedSlot: '',
    locationID: '',
    providerID: '',
    groupID: '',
    scheduleType: 'location',
    timezone: '',
  });

  // function mergeAnswers(savedData: { item: { item: any }[] }, initialData: any): any {
  //   const savedItems = savedData.item[0].item; // Extract the nested 'item' array from savedData
  //   const initialItems = initialData.item[0].item; // Extract the nested 'item' array from initialData

  //   // Create a map of answers from savedData keyed by linkId
  //   const answerMap = savedItems.reduce((map: { [x: string]: any }, item: { answer: any; linkId: string | number }) => {
  //     if (item.answer) {
  //       map[item.linkId] = item.answer;
  //     }
  //     return map;
  //   }, {});

  //   // Iterate through initialData and merge answers based on linkId
  //   initialItems.forEach((item: { initial?: any; linkId: string | number; answer: any }) => {
  //     if (answerMap[item.linkId]) {
  //       item.answer = answerMap[item.linkId]; // Add the answer field if it exists in savedData
  //       item.initial = answerMap[item.linkId];
  //     }
  //   });

  //   const appointmentDateItem = savedItems.find((item: { linkId: string }) => item.linkId === 'appointment-date');
  //   if (appointmentDateItem) {
  //     setAppointment({
  //       ...appointmentState,
  //       selectedSlot: appointmentDateItem.answer[0].valueDateTime,
  //       timezone: appointmentDateItem.answer[0].id,
  //     });
  //   }

  //   return initialData;
  // }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function mergeQuestionnaireWithResponse(defaultQuestionnaire: Questionnaire, questionnaireResponse: { item: any }) {
    // Helper function to recursively find and merge items
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    function mergeItems(defaultItems: any[] | undefined, responseItems: any[]) {
      defaultItems?.forEach((defaultItem) => {
        // Find the corresponding response item by linkId
        const responseItem = responseItems.find((item) => item.linkId === defaultItem.linkId);

        if (responseItem) {
          // If the response item has an answer, merge it into the default item
          const appointmentDateItem = responseItem.item?.find(
            (item: { linkId: string }) => item.linkId === 'appointment-date'
          );
          // console.log('responseItem appointmentDateItem', appointmentDateItem)
          if (appointmentDateItem) {
            setAppointment({
              ...appointmentState,
              selectedSlot: appointmentDateItem.answer[0].valueDateTime,
              timezone: appointmentDateItem.answer[0].id,
            });
          }
          if (responseItem.answer && responseItem.answer.length > 0) {
            defaultItem.answer = responseItem.answer;

            // Also set the initial value based on the answer
            defaultItem.initial = responseItem.answer.map(
              (ans: { valueString: any; valueDateTime: any; valueBoolean: undefined }) => {
                if (ans.valueString) {
                  return { valueString: ans.valueString };
                } else if (ans.valueDateTime) {
                  return { valueDateTime: ans.valueDateTime };
                } else if (ans.valueBoolean !== undefined) {
                  return { valueBoolean: ans.valueBoolean };
                }
                // Add other data types as necessary
                return null; // Default return value
              }
            );
          } else {
            // console.log('defaultItem', defaultItem);
            // If the response item has no answer, set the initial value based on the item's initial value
            // defaultItem.initial = responseItem.initial;
          }

          // If the item has nested items, merge those recursively
          if (defaultItem.item && responseItem.item) {
            mergeItems(defaultItem.item, responseItem.item);
          } else {
            // console.log('else second defaultItem', defaultItem);
          }
        }
      });
    }

    // Start merging from the top-level items
    mergeItems(defaultQuestionnaire.item, questionnaireResponse.item);

    return defaultQuestionnaire;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const fetchItems = async () => {
    if (responseId) {
      try {
        const questionnaireUpdated = await medplum.searchOne('Questionnaire', {
          name: patient?.managingOrganization?.display,
        });

        if (setQuestionnaire) {
          if (questionnaire?.id !== questionnaireUpdated?.id) {
            setQuestionnaire(questionnaireUpdated);
          }
        }

        const questionnaireRes = await medplum.readResource('QuestionnaireResponse', responseId);
        // Call the function to merge savedData into initialData

        if (questionnaireRes?.item?.[0]) {
          questionnaireRes.item[0].extension = [
            {
              url: 'http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl',
              valueCodeableConcept: {
                coding: [
                  {
                    system: 'http://hl7.org/fhir/questionnaire-item-control',
                    code: 'page',
                  },
                ],
              },
            },
          ];
        }
        if (questionnaire) {
          // const mergedData = mergeAnswers(questionnaireRes, questionnaire);
          // const mergedItems = mergeInitialValues(questionnaire, questionnaireRes);
          const mergedQuestionnaire = mergeQuestionnaireWithResponse(questionnaire, questionnaireRes);

          setExistingFormData(mergedQuestionnaire);
        }
      } catch (error) {
        console.error('Error fetching questionnaire response:', error);
      }
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medplum, responseId, questionnaire]);

  const handleOnSubmit = useCallback(
    async (response: QuestionnaireResponse) => {
      if (!questionnaire || !patient || !profile) {
        return;
      }

      const appointmentItem = {
        id: 'appointment-date',
        linkId: 'appointment-date',
        text: 'Appointment Date',
        answer: [
          {
            valueDateTime: appointmentState.selectedSlot?.toString(),
            id: appointmentState.timezone,
          },
        ],
        item: undefined,
      };

      const lastItem = response.item?.[0]?.item?.[response.item?.[0]?.item?.length - 1];

      if (lastItem?.linkId !== 'appointment-date') {
        response.item?.[0]?.item?.push(appointmentItem);
      }

      try {
        const res = await medplum.createResource<QuestionnaireResponse>({
          ...response,
          subject: createReference(patient),
          author: createReference(profile),
        });
        console.log('res', res);
        showNotification({
          icon: <IconCircleCheck />,
          title: 'Success',
          message: 'Answers recorded',
        });
        navigate(`/Patient/${patient.id}`);
        // navigate(`/Patient/${patient.id}/intake/${res.id}/edit`);
        // window.location.href = `/Patient/${patient.id}`;
        window.scrollTo(0, 0);
      } catch (err) {
        showNotification({
          color: 'red',
          icon: <IconCircleOff />,
          title: 'Error',
          message: normalizeErrorString(err),
        });
      }
    },
    // [medplum, navigate, questionnaire, profile, patient]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [medplum, navigate, profile, patient, appointmentState]
  );

  if (!questionnaire) {
    return <Loading />;
  }

  return (
    <Document width={800}>
      <QuestionnaireForm
        questionnaire={existingFormData}
        onSubmit={handleOnSubmit}
        ScheduleComponent={
          <ScheduleComponent
            appointmentState={appointmentState}
            setAppointment={setAppointment}
            hoursOfOperation={[]}
          />
        }
      />
      <ErrorDialog
        open={choiceErrorDialogOpen}
        title={t('welcome.dateError.title')}
        description={t('welcome.dateError.description')}
        closeButtonText={t('general.button.close')}
        handleClose={() => setChoiceErrorDialogOpen(false)}
      />
    </Document>
  );
}
