export default {
  control: {
    backgroundColor: '#fff',
    fontSize: 14,
    // fontWeight: 'normal',
  },
  '&multiLine': {
    control: {
      fontFamily: 'poppins',
      minHeight: 36,
    },
    highlighter: {
      padding: 9,
      border: '1px solid transparent',
    },
    input: {
      padding: 9,
      border: '1px solid silver',
    },
  },
  '&singleLine': {
    display: 'inline-block',
    width: 180,
    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },
    input: {
      padding: 1,
      border: '2px inset',
    },
  },
  suggestions: {
    width: '100%',
    marginTop: 30,
    // left: 0,
    right: 0,
    maxHeight: 400,
    overflowY: 'scroll',
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 16,
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
};
