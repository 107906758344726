import { Document, useMedplum } from '@medplum/react';
import { ChangeEvent, useState, useEffect } from 'react';
import { showNotification } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';
// import { IntakeQuestionnaireContext } from '../Questionnaire.context';
import { Loading } from '../../patient-intake/components/Loading';
import { myAppLogo } from '../../../src/assets/index';
import { Box, Button, Grid } from '@mui/material';
import CustomContainer from '../../components/CustomContainer';
import { NativeSelect } from '@mantine/core';

export function SelectOrganization(): JSX.Element {
  const navigate = useNavigate();
  const medplum = useMedplum();
  const [selectedOrganization, setSelectedOrganization] = useState<any>(null);
  const [organizationsList, setOrganizationsList] = useState<{ label: string; value: string }[]>([]);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const getOrganizations = async () => {
    try {
      const organizations = await medplum.get('auth/get-organizations');

      if (organizations) {
        const organizationEntries = organizations.organizations.entry
          ? organizations.organizations.entry.map((entry: { resource: any }) => ({
              label: entry.resource.name,
              value: entry.resource.id,
            }))
          : [];

        setOrganizationsList([{ label: 'Select Organization', value: '' }, ...organizationEntries]);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSubmit = async () => {
    console.log('handleOnSubmit selectedOrganization', selectedOrganization);
    if (!selectedOrganization) {
      // return setChoiceErrorDialogOpen(true);
      showNotification({
        title: 'Please select an organization',
        message: 'Please select an organization',
        color: 'red',
      });
    } else {
      return navigate(`/${selectedOrganization.label}`);
    }
  };

  if (!organizationsList) {
    return <Loading />;
  }
  const bgVariant = 'welcome';

  return (
    <Document width={800}>
      <CustomContainer
        title={''}
        // subtitle={appointmentsLoading || locationLoading ? 'Loading...' : `${locObjJson?.name}`}
        subtitle={''}
        // subtext={appointmentsLoading || locationLoading ? '' : 'subtext'}
        subtext={''}
        isFirstPage
        img={myAppLogo}
        imgAlt="Ottehr Icon"
        imgWidth={90}
        bgVariant={bgVariant}
        // outsideCardComponent={
        //   visitType === VisitType.PreBook && officeOpen ? (
        //     <></>
        //   ) : // <WaitingEstimateCard waitingMinutes={waitingMinutes} />
        //   undefined
        // }
      >
        {/* <AppBar
          position="absolute"
          sx={{
            border: `1px solid ${theme.palette.primary.dark}`,
          }}
        > */}
        <Box
          component="img"
          sx={{ margin: 1, width: 200, alignSelf: 'center', minHeight: '39px', maxHeight: '160px' }}
          alt={'logo'}
          src={myAppLogo}
        />
        {/* </AppBar> */}
      </CustomContainer>
      <Grid
        container
        justifyItems="center"
        style={{
          marginTop: '30px',
        }}
        rowGap={6}
        justifyContent={'center'}
      >
        <Grid item xs={12}>
          <NativeSelect
            id={'organization'}
            name={'organization'}
            label={'Select Organization'}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              const index = e.currentTarget.selectedIndex;
              if (index === 0) {
                setSelectedOrganization(undefined);
                return;
              }
              setSelectedOrganization({
                label: e.currentTarget.options[index].text,
                value: e.currentTarget.value,
              });
            }}
            data={organizationsList}
          />
        </Grid>

        <Button variant="contained" onClick={handleOnSubmit}>
          Submit
        </Button>
      </Grid>
    </Document>
  );
}
