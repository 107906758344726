import { ActionIcon, Alert, Button, Grid, Group, Menu, Stack, Text, TextInput, useMantineTheme } from '@mantine/core';
import {
  AccessPolicyInteraction,
  applyDefaultValuesToResource,
  canWriteResourceType,
  isPopulated,
  satisfiedAccessPolicy,
  tryGetProfile,
} from '@medplum/core';
import { OperationOutcome, Reference, Resource, ResourceType } from '@medplum/fhirtypes';
import { useMedplum, useResource } from '@medplum/react-hooks';
import { IconAlertCircle, IconChevronDown, IconEdit, IconTrash } from '@tabler/icons-react';
import cx from 'clsx';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { BackboneElementInput } from '../BackboneElementInput/BackboneElementInput';
import { FormSection } from '../FormSection/FormSection';
import classes from './ResourceForm.module.css';

export interface ResourceFormProps {
  // readonly defaultValue: Partial<Resource> | Reference;
  defaultValue: Partial<Resource> | Reference;
  readonly outcome?: OperationOutcome;
  readonly onSubmit: (resource: Resource) => void;
  readonly onPatch?: (resource: Resource) => void;
  readonly onDelete?: (resource: Resource) => void;
  /** (optional) URL of the resource profile used to display the form. Takes priority over schemaName. */
  readonly profileUrl?: string;
  resType?: string;
  navigation?: string;
  showCustomFields?: boolean;
}

export function ResourceForm(props: ResourceFormProps): JSX.Element {
  const { outcome, showCustomFields = false } = props;
  const medplum = useMedplum();
  const defaultValue = useResource(props.defaultValue);
  const resourceType = defaultValue?.resourceType as ResourceType;
  const [schemaLoaded, setSchemaLoaded] = useState(false);
  const [value, setValue] = useState<Resource>();
  const accessPolicy = medplum.getAccessPolicy();
  const theme = useMantineTheme();

  let defaultBgColor = '#422DAD';
  let defaultTextColor = 'rgb(99,99,100)';

  if (props.resType === 'Practitioner') {
    const bgColorExtension = defaultValue?.extension?.find(
      (ext: { url: string }) => ext.url === 'http://hl7.org/fhir/StructureDefinition/practitioner-background-color'
    );

    const textColorExtension = defaultValue?.extension?.find(
      (ext: { url: string }) => ext.url === 'http://hl7.org/fhir/StructureDefinition/practitioner-text-color'
    );

    if (bgColorExtension) {
      defaultBgColor = bgColorExtension.valueString || '#422DAD';
    }

    if (textColorExtension) {
      defaultTextColor = textColorExtension.valueString || 'rgb(99,99,100)';
    }
  }

  const [bgColor, setBGColor] = useState(defaultBgColor);
  const [textColor, setTextColor] = useState(defaultTextColor);

  const hexToRgb = (hex: string): { r: number; g: number; b: number } | null => {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m: any, r: any, g: any, b: any) {
      return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  };

  useEffect(() => {
    const rgb = hexToRgb(bgColor);
    if (rgb) {
      const { r, g, b } = rgb;
      const targetColor = `rgb(${r},${g},${b})`;
      const colors = targetColor.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
      const brightness = 1;

      if (colors) {
        const R = parseInt(colors[1], 10);
        const G = parseInt(colors[2], 10);
        const B = parseInt(colors[3], 10);

        const ir = Math.floor((255 - R) * brightness);
        const ig = Math.floor((255 - G) * brightness);
        const ib = Math.floor((255 - B) * brightness);
        setTextColor(`rgb(${ir}, ${ig}, ${ib})`);
      }
    }
  }, [bgColor, setTextColor]);

  const handleBgChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setBGColor(e.target.value);
  };

  useEffect(() => {
    if (defaultValue) {
      if (props.profileUrl) {
        const profileUrl: string = props.profileUrl;
        medplum
          .requestProfileSchema(props.profileUrl, { expandProfile: true })
          .then(() => {
            const profile = tryGetProfile(profileUrl);
            if (profile) {
              setSchemaLoaded(true);
              const modifiedDefaultValue = applyDefaultValuesToResource(defaultValue, profile);
              setValue(modifiedDefaultValue);
            } else {
              console.error(`Schema not found for ${profileUrl}`);
            }
          })
          .catch((reason) => {
            console.error('Error in requestProfileSchema', reason);
          });
      } else {
        medplum
          .requestSchema(resourceType)
          .then(() => {
            setValue(defaultValue);
            setSchemaLoaded(true);
          })
          .catch(console.log);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medplum, defaultValue, props.profileUrl]);

  const accessPolicyResource = useMemo(() => {
    return defaultValue && satisfiedAccessPolicy(defaultValue, AccessPolicyInteraction.READ, accessPolicy);
  }, [accessPolicy, defaultValue]);

  const canWrite = useMemo<boolean>(() => {
    if (medplum.isSuperAdmin()) {
      return true;
    }

    if (!accessPolicy) {
      return true;
    }

    if (!isPopulated(value?.resourceType)) {
      return true;
    }

    return canWriteResourceType(accessPolicy, value?.resourceType);
  }, [medplum, accessPolicy, value?.resourceType]);

  if (!schemaLoaded || !value) {
    return <div>Loading...</div>;
  }

  if (!canWrite) {
    return (
      <Alert color="red" title="Permission denied" icon={<IconAlertCircle />}>
        Your access level prevents you from editing and creating {value.resourceType} resources.
      </Alert>
    );
  }

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        if (props.onSubmit) {
          if (props.resType === 'Practitioner') {
            const filteredExtensions =
              value?.extension?.filter(
                (ext: { url: string }) =>
                  ![
                    'http://hl7.org/fhir/StructureDefinition/practitioner-background-color',
                    'http://hl7.org/fhir/StructureDefinition/practitioner-text-color',
                  ].includes(ext.url)
              ) || [];

            value.extension = [
              ...filteredExtensions,
              {
                url: 'http://hl7.org/fhir/StructureDefinition/practitioner-background-color',
                valueString: bgColor,
              },
              {
                url: 'http://hl7.org/fhir/StructureDefinition/practitioner-text-color',
                valueString: textColor,
              },
            ];
          }
          props.onSubmit({
            ...value,
            resourceType: props.resType || 'Organization',
            navigation: props.navigation || 'Practice',
            ...(showCustomFields ? { authRole: props.navigation || 'Practice' } : {}),
          });
        }
      }}
    >
      <Stack mb="xl">
        <FormSection title="Resource Type" htmlFor="resourceType" outcome={outcome}>
          <TextInput name="resourceType" defaultValue={value.resourceType} disabled={true} />
        </FormSection>
        <FormSection title="ID" htmlFor="id" outcome={outcome}>
          <TextInput name="id" defaultValue={value.id} disabled={true} />
        </FormSection>
        {props.resType === 'Practitioner' && (
          // <BackgroundColorPicker
          //   bgColor={bgColor}
          //   setBGColor={setBGColor}
          //   textColor={textColor}
          //   setTextColor={setTextColor}
          // />
          <Grid>
            <Grid.Col span={3}>
              <p>Choose Background color</p>
              <input
                type="color"
                style={{
                  cursor: 'pointer',
                }}
                value={bgColor}
                onChange={handleBgChange}
              />
            </Grid.Col>
            <Grid.Col span={9}>
              <Text
                bg={bgColor}
                c={textColor}
                w="100%"
                h={100}
                px="20%"
                ta="center"
                m="auto"
                display="table-cell"
                style={{
                  verticalAlign: 'middle',
                }}
              >
                Choose Background Color
              </Text>
            </Grid.Col>
          </Grid>
        )}
      </Stack>
      <BackboneElementInput
        path={props.resType || 'Organization'} // value.resourceType
        valuePath={props.resType || 'Organization'} // value.resourceType
        typeName={props.resType || 'Organization'} // resourceType
        subType={props.navigation || 'Practice'}
        defaultValue={{ ...value, resourceType: props.resType || 'Organization' }}
        outcome={outcome}
        onChange={setValue}
        profileUrl={props.profileUrl}
        accessPolicyResource={accessPolicyResource}
        showCustomFields={showCustomFields}
      />
      <Group justify="flex-end" mt="xl" wrap="nowrap" gap={0}>
        <Button type="submit" className={cx((props.onPatch || props.onDelete) && classes.splitButton)}>
          {defaultValue?.id ? 'Update' : 'Create'}
        </Button>
        {(props.onPatch || props.onDelete) && (
          <Menu transitionProps={{ transition: 'pop' }} position="bottom-end" withinPortal>
            <Menu.Target>
              <ActionIcon
                variant="filled"
                color={theme.primaryColor}
                size={36}
                className={classes.menuControl}
                aria-label="More actions"
              >
                <IconChevronDown size={14} stroke={1.5} />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              {props.onPatch && (
                <Menu.Item
                  leftSection={<IconEdit size={14} stroke={1.5} />}
                  onClick={() => {
                    if (props.resType === 'Practitioner') {
                      const filteredExtensions = defaultValue?.extension?.filter(
                        (ext: { url: string }) =>
                          ![
                            'http://hl7.org/fhir/StructureDefinition/practitioner-background-color',
                            'http://hl7.org/fhir/StructureDefinition/practitioner-text-color',
                          ].includes(ext.url)
                      );

                      value.extension = [
                        ...filteredExtensions,
                        {
                          url: 'http://hl7.org/fhir/StructureDefinition/practitioner-background-color',
                          valueString: bgColor,
                        },
                        {
                          url: 'http://hl7.org/fhir/StructureDefinition/practitioner-text-color',
                          valueString: textColor,
                        },
                      ];
                    } else {
                      (props.onPatch as (resource: Resource) => void)(value);
                    }
                  }}
                >
                  Patch
                </Menu.Item>
              )}
              {props.onDelete && (
                <Menu.Item
                  color="red"
                  leftSection={<IconTrash size={14} stroke={1.5} color="red" />}
                  onClick={() => {
                    (props.onDelete as (resource: Resource) => void)(value);
                  }}
                >
                  Delete
                </Menu.Item>
              )}
            </Menu.Dropdown>
          </Menu>
        )}
      </Group>
    </form>
  );
}
