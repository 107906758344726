import { Title } from '@mantine/core';
import { DescriptionList, DescriptionListEntry, Loading, useMedplum } from '@medplum/react';
import { getProjectId } from '../utils';
import { useEffect, useState } from 'react';

export function ProjectDetailsPage(): JSX.Element {
  const medplum = useMedplum();
  const projectId = getProjectId(medplum);

  const [result, setResult] = useState<{ project?: { name?: string; id?: string } } | null>(null);
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const fetchProjects = async () => {
    const result = await medplum.get(`admin/projects/${projectId}`);

    setResult(result);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!result) {
    return <Loading />;
  }

  return (
    <>
      <Title>Details</Title>
      <DescriptionList>
        <DescriptionListEntry term="ID">{result?.project?.id}</DescriptionListEntry>
        <DescriptionListEntry term="Name">{result?.project?.name}</DescriptionListEntry>
      </DescriptionList>
    </>
  );
}
