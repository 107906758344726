import { Route, Routes } from 'react-router-dom';
import { BatchPage } from './BatchPage';
import { BulkAppPage } from './BulkAppPage';
import { ChangePasswordPage } from './ChangePasswordPage';
import { CreateResourcePage } from './CreateResourcePage';
import { ErrorPage } from './ErrorPage';
import { FormPage } from './FormPage';
import { HomePage } from './HomePage';
import { MfaPage } from './MfaPage';
// import { OAuthPage } from './OAuthPage';
// import { RegisterPage } from './RegisterPage';
import { ResetPasswordPage } from './ResetPasswordPage';
import { SecurityPage } from './SecurityPage';
import { SetPasswordPage } from './SetPasswordPage';
// import { SignInPage } from './SignInPage';
import { SmartSearchPage } from './SmartSearchPage';
import { BotsPage } from './admin/BotsPage';
import { ClientsPage } from './admin/ClientsPage';
import { CreateBotPage } from './admin/CreateBotPage';
import { CreateClientPage } from './admin/CreateClientPage';
import { EditMembershipPage } from './admin/EditMembershipPage';
import { InvitePage } from './admin/InvitePage';
import { PatientsPage } from './admin/PatientsPage';
import { ProjectAdminConfigPage } from './admin/ProjectAdminConfigPage';
import { ProjectDetailsPage } from './admin/ProjectDetailsPage';
import { ProjectPage } from './admin/ProjectPage';
import { SecretsPage } from './admin/SecretsPage';
import { SitesPage } from './admin/SitesPage';
import { SuperAdminPage } from './admin/SuperAdminPage';
import { UsersPage } from './admin/UsersPage';
import { AssaysPage } from './lab/AssaysPage';
import { PanelsPage } from './lab/PanelsPage';
import { ApplyPage } from './resource/ApplyPage';
import { AppsPage } from './resource/AppsPage';
import { AuditEventPage } from './resource/AuditEventPage';
import { BlamePage } from './resource/BlamePage';
import { BotEditor } from './resource/BotEditor';
import { BuilderPage } from './resource/BuilderPage';
import { ChecklistPage } from './resource/ChecklistPage';
import { DeletePage } from './resource/DeletePage';
import { DetailsPage } from './resource/DetailsPage';
import { EditPage } from './resource/EditPage';
import { FormCreatePage } from './resource/FormCreatePage';
import { HistoryPage } from './resource/HistoryPage';
import { JsonCreatePage } from './resource/JsonCreatePage';
import { JsonPage } from './resource/JsonPage';
import { PreviewPage } from './resource/PreviewPage';
import { ProfilesPage } from './resource/ProfilesPage';
import { QuestionnaireBotsPage } from './resource/QuestionnaireBotsPage';
import { QuestionnaireResponsePage } from './resource/QuestionnaireResponsePage';
import { ReferenceRangesPage } from './resource/ReferenceRangesPage';
import { ReportPage } from './resource/ReportPage';
import { ResourcePage } from './resource/ResourcePage';
import { ResourceVersionPage } from './resource/ResourceVersionPage';
import { SubscriptionsPage } from './resource/SubscriptionsPage';
import { TimelinePage } from './resource/TimelinePage';
import { ToolsPage } from './resource/ToolsPage';

import Practice from './pages/practice';
import { CreateResourcePage as PracticeCreateResourcePage } from './pages/practice/CreateResourcePage';
import { ApplyPage as PracticeApplyPage } from './practice-resource/ApplyPage';
import { AppsPage as PracticeAppsPage } from './practice-resource/AppsPage';
import { AuditEventPage as PracticeAuditEventPage } from './practice-resource/AuditEventPage';
import { BlamePage as PracticeBlamePage } from './practice-resource/BlamePage';
import { BotEditor as PracticeBotEditor } from './practice-resource/BotEditor';
import { BuilderPage as PracticeBuilderPage } from './practice-resource/BuilderPage';
import { ChecklistPage as PracticeChecklistPage } from './practice-resource/ChecklistPage';
import { DeletePage as PracticeDeletePage } from './practice-resource/DeletePage';
import { DetailsPage as PracticeDetailsPage } from './practice-resource/DetailsPage';
import { EditPage as PracticeEditPage } from './practice-resource/EditPage';
import { FormCreatePage as PracticeFormCreatePage } from './practice-resource/FormCreatePage';
import { HistoryPage as PracticeHistoryPage } from './practice-resource/HistoryPage';
// import { JsonCreatePage as PracticeJsonCreatePage } from './practice-resource/JsonCreatePage';
import { JsonPage as PracticeJsonPage } from './practice-resource/JsonPage';
import { PreviewPage as PracticePreviewPage } from './practice-resource/PreviewPage';
import { ProfilesPage as PracticeProfilesPage } from './practice-resource/ProfilesPage';
import { QuestionnaireBotsPage as PracticeQuestionnaireBotsPage } from './practice-resource/QuestionnaireBotsPage';
import { QuestionnaireResponsePage as PracticeQuestionnaireResponsePage } from './practice-resource/QuestionnaireResponsePage';
import { ReferenceRangesPage as PracticeReferenceRangesPage } from './practice-resource/ReferenceRangesPage';
import { ReportPage as PracticeReportPage } from './practice-resource/ReportPage';
import { ResourcePage as PracticeResourcePage } from './practice-resource/ResourcePage';
// import { ResourceVersionPage as PracticeResourceVersionPage } from './practice-resource/ResourceVersionPage';
import { SubscriptionsPage as PracticeSubscriptionsPage } from './practice-resource/SubscriptionsPage';
import { TimelinePage as PracticeTimelinePage } from './practice-resource/TimelinePage';
import { ToolsPage as PracticeToolsPage } from './practice-resource/ToolsPage';

// Practitioner Routes
import Practitioner from './pages/surgeon';
import { CreateResourcePage as SurgeonCreateResourcePage } from './pages/surgeon/CreateResourcePage';
// import { FormCreatePage as SurgeonFormCreatePage } from './pages/surgeon/FormCreatePage';
import { ApplyPage as SurgeonApplyPage } from './surgeon/ApplyPage';
import { AppsPage as SurgeonAppsPage } from './surgeon/AppsPage';
import { AuditEventPage as SurgeonAuditEventPage } from './surgeon/AuditEventPage';
import { BlamePage as SurgeonBlamePage } from './surgeon/BlamePage';
import { BotEditor as SurgeonBotEditor } from './surgeon/BotEditor';
import { BuilderPage as SurgeonBuilderPage } from './surgeon/BuilderPage';
import { ChecklistPage as SurgeonChecklistPage } from './surgeon/ChecklistPage';
import { DeletePage as SurgeonDeletePage } from './surgeon/DeletePage';
import { DetailsPage as SurgeonDetailsPage } from './surgeon/DetailsPage';
import { EditPage as SurgeonEditPage } from './surgeon/EditPage';
import { FormCreatePage as SurgeonFormCreatePage } from './surgeon/FormCreatePage';
import { HistoryPage as SurgeonHistoryPage } from './surgeon/HistoryPage';
import { JsonCreatePage as SurgeonJsonCreatePage } from './surgeon/JsonCreatePage';
import { JsonPage as SurgeonJsonPage } from './surgeon/JsonPage';
import { PreviewPage as SurgeonPreviewPage } from './surgeon/PreviewPage';
import { ProfilesPage as SurgeonProfilesPage } from './surgeon/ProfilesPage';
import { QuestionnaireBotsPage as SurgeonQuestionnaireBotsPage } from './surgeon/QuestionnaireBotsPage';
import { QuestionnaireResponsePage as SurgeonQuestionnaireResponsePage } from './surgeon/QuestionnaireResponsePage';
import { ReferenceRangesPage as SurgeonReferenceRangesPage } from './surgeon/ReferenceRangesPage';
import { ReportPage as SurgeonReportPage } from './surgeon/ReportPage';
import { ResourcePage as SurgeonResourcePage } from './surgeon/ResourcePage';
// import { ResourceVersionPage as SurgeonResourceVersionPage } from './surgeon/ResourceVersionPage';
import { SubscriptionsPage as SurgeonSubscriptionsPage } from './surgeon/SubscriptionsPage';
import { TimelinePage as SurgeonTimelinePage } from './surgeon/TimelinePage';
import { ToolsPage as SurgeonToolsPage } from './surgeon/ToolsPage';

// Staff Routes
import Staff from './pages/staff';
import { CreateResourcePage as StaffCreateResourcePage } from './pages/staff/CreateResourcePage';
// import { FormCreatePage as StaffFormCreatePage } from './pages/staff/FormCreatePage';
import { ApplyPage as StaffApplyPage } from './staff/ApplyPage';
import { AppsPage as StaffAppsPage } from './staff/AppsPage';
import { AuditEventPage as StaffAuditEventPage } from './staff/AuditEventPage';
import { BlamePage as StaffBlamePage } from './staff/BlamePage';
import { BotEditor as StaffBotEditor } from './staff/BotEditor';
import { BuilderPage as StaffBuilderPage } from './staff/BuilderPage';
import { ChecklistPage as StaffChecklistPage } from './staff/ChecklistPage';
import { DeletePage as StaffDeletePage } from './staff/DeletePage';
import { DetailsPage as StaffDetailsPage } from './staff/DetailsPage';
import { EditPage as StaffEditPage } from './staff/EditPage';
import { FormCreatePage as StaffFormCreatePage } from './staff/FormCreatePage';
import { HistoryPage as StaffHistoryPage } from './staff/HistoryPage';
import { JsonCreatePage as StaffJsonCreatePage } from './staff/JsonCreatePage';
import { JsonPage as StaffJsonPage } from './staff/JsonPage';
import { PreviewPage as StaffPreviewPage } from './staff/PreviewPage';
import { ProfilesPage as StaffProfilesPage } from './staff/ProfilesPage';
import { QuestionnaireBotsPage as StaffQuestionnaireBotsPage } from './staff/QuestionnaireBotsPage';
import { QuestionnaireResponsePage as StaffQuestionnaireResponsePage } from './staff/QuestionnaireResponsePage';
import { ReferenceRangesPage as StaffReferenceRangesPage } from './staff/ReferenceRangesPage';
import { ReportPage as StaffReportPage } from './staff/ReportPage';
import { ResourcePage as StaffResourcePage } from './staff/ResourcePage';
// import { ResourceVersionPage as StaffResourceVersionPage } from './staff/ResourceVersionPage';
import { SubscriptionsPage as StaffSubscriptionsPage } from './staff/SubscriptionsPage';
import { TimelinePage as StaffTimelinePage } from './staff/TimelinePage';
import { ToolsPage as StaffToolsPage } from './staff/ToolsPage';

// Oem Routes
import Oem from './pages/oem';
import { CreateResourcePage as OemCreateResourcePage } from './pages/oem/CreateResourcePage';
// import { FormCreatePage as OemFormCreatePage } from './pages/oem/FormCreatePage';
import { ApplyPage as OemApplyPage } from './oem/ApplyPage';
import { AppsPage as OemAppsPage } from './oem/AppsPage';
import { AuditEventPage as OemAuditEventPage } from './oem/AuditEventPage';
import { BlamePage as OemBlamePage } from './oem/BlamePage';
import { BotEditor as OemBotEditor } from './oem/BotEditor';
import { BuilderPage as OemBuilderPage } from './oem/BuilderPage';
import { ChecklistPage as OemChecklistPage } from './oem/ChecklistPage';
import { DeletePage as OemDeletePage } from './oem/DeletePage';
import { DetailsPage as OemDetailsPage } from './oem/DetailsPage';
import { EditPage as OemEditPage } from './oem/EditPage';
import { FormCreatePage as OemFormCreatePage } from './oem/FormCreatePage';
import { HistoryPage as OemHistoryPage } from './oem/HistoryPage';
import { JsonCreatePage as OemJsonCreatePage } from './oem/JsonCreatePage';
import { JsonPage as OemJsonPage } from './oem/JsonPage';
import { PreviewPage as OemPreviewPage } from './oem/PreviewPage';
import { ProfilesPage as OemProfilesPage } from './oem/ProfilesPage';
import { QuestionnaireBotsPage as OemQuestionnaireBotsPage } from './oem/QuestionnaireBotsPage';
import { QuestionnaireResponsePage as OemQuestionnaireResponsePage } from './oem/QuestionnaireResponsePage';
import { ReferenceRangesPage as OemReferenceRangesPage } from './oem/ReferenceRangesPage';
import { ReportPage as OemReportPage } from './oem/ReportPage';
import { ResourcePage as OemResourcePage } from './oem/ResourcePage';
// import { ResourceVersionPage as OemResourceVersionPage } from './oem/ResourceVersionPage';
import { SubscriptionsPage as OemSubscriptionsPage } from './oem/SubscriptionsPage';
import { TimelinePage as OemTimelinePage } from './oem/TimelinePage';
import { ToolsPage as OemToolsPage } from './oem/ToolsPage';

// Manufacturer Routes
import Manufacturer from './pages/manufacturer';
import { CreateResourcePage as ManufacturerCreateResourcePage } from './pages/manufacturer/CreateResourcePage';
// import { FormCreatePage as ManufacturerFormCreatePage } from './pages/manufacturer/FormCreatePage';
import { ApplyPage as ManufacturerApplyPage } from './manufacturer/ApplyPage';
import { AppsPage as ManufacturerAppsPage } from './manufacturer/AppsPage';
import { AuditEventPage as ManufacturerAuditEventPage } from './manufacturer/AuditEventPage';
import { BlamePage as ManufacturerBlamePage } from './manufacturer/BlamePage';
import { BotEditor as ManufacturerBotEditor } from './manufacturer/BotEditor';
import { BuilderPage as ManufacturerBuilderPage } from './manufacturer/BuilderPage';
import { ChecklistPage as ManufacturerChecklistPage } from './manufacturer/ChecklistPage';
import { DeletePage as ManufacturerDeletePage } from './manufacturer/DeletePage';
import { DetailsPage as ManufacturerDetailsPage } from './manufacturer/DetailsPage';
import { EditPage as ManufacturerEditPage } from './manufacturer/EditPage';
import { FormCreatePage as ManufacturerFormCreatePage } from './manufacturer/FormCreatePage';
import { HistoryPage as ManufacturerHistoryPage } from './manufacturer/HistoryPage';
import { JsonCreatePage as ManufacturerJsonCreatePage } from './manufacturer/JsonCreatePage';
import { JsonPage as ManufacturerJsonPage } from './manufacturer/JsonPage';
import { PreviewPage as ManufacturerPreviewPage } from './manufacturer/PreviewPage';
import { ProfilesPage as ManufacturerProfilesPage } from './manufacturer/ProfilesPage';
import { QuestionnaireBotsPage as ManufacturerQuestionnaireBotsPage } from './manufacturer/QuestionnaireBotsPage';
import { QuestionnaireResponsePage as ManufacturerQuestionnaireResponsePage } from './manufacturer/QuestionnaireResponsePage';
import { ReferenceRangesPage as ManufacturerReferenceRangesPage } from './manufacturer/ReferenceRangesPage';
import { ReportPage as ManufacturerReportPage } from './manufacturer/ReportPage';
import { ResourcePage as ManufacturerResourcePage } from './manufacturer/ResourcePage';
// import { ResourceVersionPage as ManufacturerResourceVersionPage } from './manufacturer/ResourceVersionPage';
import { SubscriptionsPage as ManufacturerSubscriptionsPage } from './manufacturer/SubscriptionsPage';
import { TimelinePage as ManufacturerTimelinePage } from './manufacturer/TimelinePage';
import { ToolsPage as ManufacturerToolsPage } from './manufacturer/ToolsPage';

// Patient Routes
import Patient from './pages/patient';
import { CreateResourcePage as PatientCreateResourcePage } from './pages/patient/CreateResourcePage';
// import { FormCreatePage as PatientFormCreatePage } from './pages/patient/FormCreatePage';
import { ApplyPage as PatientApplyPage } from './patient/ApplyPage';
import { AppsPage as PatientAppsPage } from './patient/AppsPage';
import { AuditEventPage as PatientAuditEventPage } from './patient/AuditEventPage';
import { BlamePage as PatientBlamePage } from './patient/BlamePage';
import { BotEditor as PatientBotEditor } from './patient/BotEditor';
import { BuilderPage as PatientBuilderPage } from './patient/BuilderPage';
import { ChecklistPage as PatientChecklistPage } from './patient/ChecklistPage';
import { DeletePage as PatientDeletePage } from './patient/DeletePage';
import { DetailsPage as PatientDetailsPage } from './patient/DetailsPage';
import { EditPage as PatientEditPage } from './patient/EditPage';
import { FormCreatePage as PatientFormCreatePage } from './patient/FormCreatePage';
import { HistoryPage as PatientHistoryPage } from './patient/HistoryPage';
import { JsonCreatePage as PatientJsonCreatePage } from './patient/JsonCreatePage';
import { JsonPage as PatientJsonPage } from './patient/JsonPage';
import { PreviewPage as PatientPreviewPage } from './patient/PreviewPage';
import { ProfilesPage as PatientProfilesPage } from './patient/ProfilesPage';
import { QuestionnaireBotsPage as PatientQuestionnaireBotsPage } from './patient/QuestionnaireBotsPage';
import { QuestionnaireResponsePage as PatientQuestionnaireResponsePage } from './patient/QuestionnaireResponsePage';
import { ReferenceRangesPage as PatientReferenceRangesPage } from './patient/ReferenceRangesPage';
import { ReportPage as PatientReportPage } from './patient/ReportPage';
import { ResourcePage as PatientResourcePage } from './patient/ResourcePage';
// import { ResourceVersionPage as PatientResourceVersionPage } from './patient/ResourceVersionPage';
import { SubscriptionsPage as PatientSubscriptionsPage } from './patient/SubscriptionsPage';
import { TimelinePage as PatientTimelinePage } from './patient/TimelinePage';
import { ToolsPage as PatientToolsPage } from './patient/ToolsPage';
import { PatientPage } from './pages/patient/PatientPage';
import { AppointmentsPage } from './pages/appointment/AppointmentsPage';
import { AppointmentDetailPage } from './pages/appointment/AppointmentDetailPage';
import AddPatient from './queue-management/pages/addPatient';

// import { SignUpPage } from './SignUpPage';

export function AppRoutes(): JSX.Element {
  return (
    <Routes>
      <Route errorElement={<ErrorPage />}>
        {/* <Route path="/signin" element={<SignInPage />} /> */}
        {/* <Route path="/oauth" element={<OAuthPage />} /> */}
        <Route path="/resetpassword" element={<ResetPasswordPage />} />
        <Route path="/setpassword/:id/:secret" element={<SetPasswordPage />} />
        {/* <Route path="/get-started" element={<SignUpPage />} /> */}
        {/* <Route path="/register" element={<RegisterPage />} /> */}
        <Route path="/changepassword" element={<ChangePasswordPage />} />
        <Route path="/security" element={<SecurityPage />} />
        <Route path="/mfa" element={<MfaPage />} />
        <Route path="/batch" element={<BatchPage />} />
        <Route path="/bulk/:resourceType" element={<BulkAppPage />} />
        <Route path="/smart" element={<SmartSearchPage />} />
        <Route path="/forms/:id" element={<FormPage />} />
        <Route path="/admin/super" element={<SuperAdminPage />} />
        <Route path="/admin/config" element={<ProjectAdminConfigPage />} />
        <Route path="/admin" element={<ProjectPage />}>
          <Route path="patients" element={<PatientsPage />} />
          <Route path="bots/new" element={<CreateBotPage />} />
          <Route path="bots" element={<BotsPage />} />
          <Route path="clients/new" element={<CreateClientPage />} />
          <Route path="clients" element={<ClientsPage />} />
          <Route path="details" element={<ProjectDetailsPage />} />
          <Route path="invite" element={<InvitePage />} />
          <Route path="users" element={<UsersPage />} />
          <Route path="project" element={<ProjectDetailsPage />} />
          <Route path="secrets" element={<SecretsPage />} />
          <Route path="sites" element={<SitesPage />} />
          <Route path="members/:membershipId" element={<EditMembershipPage />} />
        </Route>

        <Route path="/Practitioner/new" element={<SurgeonCreateResourcePage />}>
          <Route index element={<SurgeonFormCreatePage />} />
          <Route path="form" element={<SurgeonFormCreatePage />} />
          <Route path="json" element={<SurgeonJsonCreatePage />} />
          <Route path="profiles" element={<SurgeonFormCreatePage />} />
        </Route>

        <Route path="/Practitioner/:id" element={<SurgeonResourcePage />}>
          <Route index element={<SurgeonTimelinePage />} />
          <Route path="apply" element={<SurgeonApplyPage />} />
          <Route path="apps" element={<SurgeonAppsPage />} />
          <Route path="event" element={<SurgeonAuditEventPage />} />
          <Route path="blame" element={<SurgeonBlamePage />} />
          <Route path="bots" element={<SurgeonQuestionnaireBotsPage />} />
          <Route path="builder" element={<SurgeonBuilderPage />} />
          <Route path="checklist" element={<SurgeonChecklistPage />} />
          <Route path="delete" element={<SurgeonDeletePage />} />
          <Route path="details" element={<SurgeonDetailsPage />} />
          <Route path="edit" element={<SurgeonEditPage />} />
          <Route path="editor" element={<SurgeonBotEditor />} />
          <Route path="history" element={<SurgeonHistoryPage />} />
          <Route path="json" element={<SurgeonJsonPage />} />
          <Route path="preview" element={<SurgeonPreviewPage />} />
          <Route path="responses" element={<SurgeonQuestionnaireResponsePage />} />
          <Route path="report" element={<SurgeonReportPage />} />
          <Route path="ranges" element={<SurgeonReferenceRangesPage />} />
          <Route path="subscriptions" element={<SurgeonSubscriptionsPage />} />
          <Route path="timeline" element={<SurgeonTimelinePage />} />
          <Route path="tools" element={<SurgeonToolsPage />} />
          <Route path="profiles" element={<SurgeonProfilesPage />} />
        </Route>

        <Route path="/Practitioner" element={<Practitioner />}></Route>

        <Route path="/Staff/new" element={<StaffCreateResourcePage />}>
          <Route index element={<StaffFormCreatePage />} />
          <Route path="form" element={<StaffFormCreatePage />} />
          <Route path="json" element={<StaffJsonCreatePage />} />
          <Route path="profiles" element={<StaffFormCreatePage />} />
        </Route>

        <Route path="/Staff/:id" element={<StaffResourcePage />}>
          <Route index element={<StaffTimelinePage />} />
          <Route path="apply" element={<StaffApplyPage />} />
          <Route path="apps" element={<StaffAppsPage />} />
          <Route path="event" element={<StaffAuditEventPage />} />
          <Route path="blame" element={<StaffBlamePage />} />
          <Route path="bots" element={<StaffQuestionnaireBotsPage />} />
          <Route path="builder" element={<StaffBuilderPage />} />
          <Route path="checklist" element={<StaffChecklistPage />} />
          <Route path="delete" element={<StaffDeletePage />} />
          <Route path="details" element={<StaffDetailsPage />} />
          <Route path="edit" element={<StaffEditPage />} />
          <Route path="editor" element={<StaffBotEditor />} />
          <Route path="history" element={<StaffHistoryPage />} />
          <Route path="json" element={<StaffJsonPage />} />
          <Route path="preview" element={<StaffPreviewPage />} />
          <Route path="responses" element={<StaffQuestionnaireResponsePage />} />
          <Route path="report" element={<StaffReportPage />} />
          <Route path="ranges" element={<StaffReferenceRangesPage />} />
          <Route path="subscriptions" element={<StaffSubscriptionsPage />} />
          <Route path="timeline" element={<StaffTimelinePage />} />
          <Route path="tools" element={<StaffToolsPage />} />
          <Route path="profiles" element={<StaffProfilesPage />} />
        </Route>

        <Route path="/Staff" element={<Staff />}></Route>

        <Route path="/Oem/new" element={<OemCreateResourcePage />}>
          <Route index element={<OemFormCreatePage />} />
          <Route path="form" element={<OemFormCreatePage />} />
          <Route path="json" element={<OemJsonCreatePage />} />
          <Route path="profiles" element={<OemFormCreatePage />} />
        </Route>

        <Route path="/Oem/:id" element={<OemResourcePage />}>
          <Route index element={<OemTimelinePage />} />
          <Route path="apply" element={<OemApplyPage />} />
          <Route path="apps" element={<OemAppsPage />} />
          <Route path="event" element={<OemAuditEventPage />} />
          <Route path="blame" element={<OemBlamePage />} />
          <Route path="bots" element={<OemQuestionnaireBotsPage />} />
          <Route path="builder" element={<OemBuilderPage />} />
          <Route path="checklist" element={<OemChecklistPage />} />
          <Route path="delete" element={<OemDeletePage />} />
          <Route path="details" element={<OemDetailsPage />} />
          <Route path="edit" element={<OemEditPage />} />
          <Route path="editor" element={<OemBotEditor />} />
          <Route path="history" element={<OemHistoryPage />} />
          <Route path="json" element={<OemJsonPage />} />
          <Route path="preview" element={<OemPreviewPage />} />
          <Route path="responses" element={<OemQuestionnaireResponsePage />} />
          <Route path="report" element={<OemReportPage />} />
          <Route path="ranges" element={<OemReferenceRangesPage />} />
          <Route path="subscriptions" element={<OemSubscriptionsPage />} />
          <Route path="timeline" element={<OemTimelinePage />} />
          <Route path="tools" element={<OemToolsPage />} />
          <Route path="profiles" element={<OemProfilesPage />} />
        </Route>

        <Route path="/Oem" element={<Oem />}></Route>

        <Route path="/Manufacturer/new" element={<ManufacturerCreateResourcePage />}>
          <Route index element={<ManufacturerFormCreatePage />} />
          <Route path="form" element={<ManufacturerFormCreatePage />} />
          <Route path="json" element={<ManufacturerJsonCreatePage />} />
          <Route path="profiles" element={<ManufacturerFormCreatePage />} />
        </Route>

        <Route path="/Manufacturer/:id" element={<ManufacturerResourcePage />}>
          <Route index element={<ManufacturerTimelinePage />} />
          <Route path="apply" element={<ManufacturerApplyPage />} />
          <Route path="apps" element={<ManufacturerAppsPage />} />
          <Route path="event" element={<ManufacturerAuditEventPage />} />
          <Route path="blame" element={<ManufacturerBlamePage />} />
          <Route path="bots" element={<ManufacturerQuestionnaireBotsPage />} />
          <Route path="builder" element={<ManufacturerBuilderPage />} />
          <Route path="checklist" element={<ManufacturerChecklistPage />} />
          <Route path="delete" element={<ManufacturerDeletePage />} />
          <Route path="details" element={<ManufacturerDetailsPage />} />
          <Route path="edit" element={<ManufacturerEditPage />} />
          <Route path="editor" element={<ManufacturerBotEditor />} />
          <Route path="history" element={<ManufacturerHistoryPage />} />
          <Route path="json" element={<ManufacturerJsonPage />} />
          <Route path="preview" element={<ManufacturerPreviewPage />} />
          <Route path="responses" element={<ManufacturerQuestionnaireResponsePage />} />
          <Route path="report" element={<ManufacturerReportPage />} />
          <Route path="ranges" element={<ManufacturerReferenceRangesPage />} />
          <Route path="subscriptions" element={<ManufacturerSubscriptionsPage />} />
          <Route path="timeline" element={<ManufacturerTimelinePage />} />
          <Route path="tools" element={<ManufacturerToolsPage />} />
          <Route path="profiles" element={<ManufacturerProfilesPage />} />
        </Route>

        <Route path="/Manufacturer" element={<Manufacturer />}></Route>

        <Route path="/Patient/new" element={<PatientCreateResourcePage />}>
          <Route index element={<PatientFormCreatePage />} />
          <Route path="form" element={<PatientFormCreatePage />} />
          <Route path="json" element={<PatientJsonCreatePage />} />
          <Route path="profiles" element={<PatientFormCreatePage />} />
        </Route>

        <Route path="/Patient/detail/:id" element={<PatientResourcePage />}>
          <Route index element={<PatientTimelinePage />} />
          <Route path="apply" element={<PatientApplyPage />} />
          <Route path="apps" element={<PatientAppsPage />} />
          <Route path="event" element={<PatientAuditEventPage />} />
          <Route path="blame" element={<PatientBlamePage />} />
          <Route path="bots" element={<PatientQuestionnaireBotsPage />} />
          <Route path="builder" element={<PatientBuilderPage />} />
          <Route path="checklist" element={<PatientChecklistPage />} />
          <Route path="delete" element={<PatientDeletePage />} />
          <Route path="details" element={<PatientDetailsPage />} />
          <Route path="edit" element={<PatientEditPage />} />
          <Route path="editor" element={<PatientBotEditor />} />
          <Route path="history" element={<PatientHistoryPage />} />
          <Route path="json" element={<PatientJsonPage />} />
          <Route path="preview" element={<PatientPreviewPage />} />
          <Route path="responses" element={<PatientQuestionnaireResponsePage />} />
          <Route path="report" element={<PatientReportPage />} />
          <Route path="ranges" element={<PatientReferenceRangesPage />} />
          <Route path="subscriptions" element={<PatientSubscriptionsPage />} />
          <Route path="timeline" element={<PatientTimelinePage />} />
          <Route path="tools" element={<PatientToolsPage />} />
          <Route path="profiles" element={<PatientProfilesPage />} />
        </Route>

        <Route path="/Patient" element={<Patient />}></Route>

        <Route path="/Practice/new" element={<PracticeCreateResourcePage />}>
          <Route index element={<PracticeFormCreatePage />} />
          <Route path="form" element={<PracticeFormCreatePage />} />
          <Route path="json" element={<JsonCreatePage />} />
          <Route path="profiles" element={<PracticeFormCreatePage />} />
        </Route>

        <Route path="/Practice/:id" element={<PracticeResourcePage />}>
          <Route index element={<PracticeTimelinePage />} />
          <Route path="apply" element={<PracticeApplyPage />} />
          <Route path="apps" element={<PracticeAppsPage />} />
          <Route path="event" element={<PracticeAuditEventPage />} />
          <Route path="blame" element={<PracticeBlamePage />} />
          <Route path="bots" element={<PracticeQuestionnaireBotsPage />} />
          <Route path="builder" element={<PracticeBuilderPage />} />
          <Route path="checklist" element={<PracticeChecklistPage />} />
          <Route path="delete" element={<PracticeDeletePage />} />
          <Route path="details" element={<PracticeDetailsPage />} />
          <Route path="edit" element={<PracticeEditPage />} />
          <Route path="editor" element={<PracticeBotEditor />} />
          <Route path="history" element={<PracticeHistoryPage />} />
          <Route path="json" element={<PracticeJsonPage />} />
          <Route path="preview" element={<PracticePreviewPage />} />
          <Route path="responses" element={<PracticeQuestionnaireResponsePage />} />
          <Route path="report" element={<PracticeReportPage />} />
          <Route path="ranges" element={<PracticeReferenceRangesPage />} />
          <Route path="subscriptions" element={<PracticeSubscriptionsPage />} />
          <Route path="timeline" element={<PracticeTimelinePage />} />
          <Route path="tools" element={<PracticeToolsPage />} />
          <Route path="profiles" element={<PracticeProfilesPage />} />
        </Route>

        <Route path="/Practice" element={<Practice />}></Route>

        {/* Schedule Module */}

        <Route path="/Patient/:id/*" element={<PatientPage />} />
        <Route path="/Appointment" element={<AppointmentsPage />} />
        <Route path="/Appointment/add" element={<AddPatient />} />
        {/* <Route path="/Appointment/past" element={<AppointmentsPage />} /> */}
        <Route path="/Appointment/:id/*" element={<AppointmentDetailPage />} />

        <Route path="/lab/assays" element={<AssaysPage />} />
        <Route path="/lab/panels" element={<PanelsPage />} />
        <Route path="/:resourceType/:id/_history/:versionId/:tab" element={<ResourceVersionPage />} />
        <Route path="/:resourceType/:id/_history/:versionId" element={<ResourceVersionPage />} />
        <Route path="/:resourceType/new" element={<CreateResourcePage />}>
          <Route index element={<FormCreatePage />} />
          <Route path="form" element={<FormCreatePage />} />
          <Route path="json" element={<JsonCreatePage />} />
          <Route path="profiles" element={<FormCreatePage />} />
        </Route>
        <Route path="/:resourceType/:id" element={<ResourcePage />}>
          <Route index element={<TimelinePage />} />
          <Route path="apply" element={<ApplyPage />} />
          <Route path="apps" element={<AppsPage />} />
          <Route path="event" element={<AuditEventPage />} />
          <Route path="blame" element={<BlamePage />} />
          <Route path="bots" element={<QuestionnaireBotsPage />} />
          <Route path="builder" element={<BuilderPage />} />
          <Route path="checklist" element={<ChecklistPage />} />
          <Route path="delete" element={<DeletePage />} />
          <Route path="details" element={<DetailsPage />} />
          <Route path="edit" element={<EditPage />} />
          <Route path="editor" element={<BotEditor />} />
          <Route path="history" element={<HistoryPage />} />
          <Route path="json" element={<JsonPage />} />
          <Route path="preview" element={<PreviewPage />} />
          <Route path="responses" element={<QuestionnaireResponsePage />} />
          <Route path="report" element={<ReportPage />} />
          <Route path="ranges" element={<ReferenceRangesPage />} />
          <Route path="subscriptions" element={<SubscriptionsPage />} />
          <Route path="timeline" element={<TimelinePage />} />
          <Route path="tools" element={<ToolsPage />} />
          <Route path="profiles" element={<ProfilesPage />} />
        </Route>
        <Route path="/:resourceType" element={<HomePage />} />
        <Route path="/" element={<HomePage />} />
      </Route>
    </Routes>
  );
}
