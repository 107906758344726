/* eslint-disable @typescript-eslint/no-unused-vars */
// import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
// import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
// import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
// import ChatOutlineIcon from '@mui/icons-material/ChatOutlined';
// import EditNoteIcon from '@mui/icons-material/EditNote';
// import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
// import RememberMeOutlinedIcon from '@mui/icons-material/RememberMeOutlined';
// import { LoadingButton } from '@mui/lab';
import {
  // Badge,
  Box,
  // Chip,
  // Grid,
  // IconButton,
  // Input,
  // InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  // Tooltip,
  Typography,
  // capitalize,
  useTheme,
} from '@mui/material';
import { Location } from 'fhir/r4';
import { DateTime } from 'luxon';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import { UCAppointmentInformation } from 'ehr-utils';
// import { otherColors } from '../CustomThemeProvider';
// import { PriorityIconWithBorder } from '../components/PriorityIconWithBorder';
// import ChatModal from '../features/chat/ChatModal';
// import { checkinPatient, getUpdateTagOperation } from '../helpers';
// import { flagDateOfBirth } from '../helpers/flagDateOfBirth';
// import { formatDateUsingSlashes, getTimezone } from '../helpers/formatDateTime';
// import {
//   formatMinutes,
//   getDurationOfStatus,
//   getStatiForVisitTimeCalculation,
//   getVisitTotalTime,
// } from '../helpers/visitDurationUtils';
// import { useApiClients } from '../hooks/useAppClients';
// import useOttehrUser from '../hooks/useOttehrUser';
import { ApptTab } from './AppointmentTabs';
// import CustomChip from './CustomChip';
// import { GenericToolTip, PaperworkToolTipContent } from './GenericToolTip';
// import { VisitStatus, StatusLabel } from '../helpers/mappingUtils';
import { otherColors } from '../../ottehr';
import { VisitStatus } from '../helpers/mappingUtils';
import { convertIsoToLocal, sortByDateAndPriority, useMedplum, useResource } from '@medplum/react';
// import { notifications } from '@mantine/notifications';
import { useDisclosure } from '@mantine/hooks';
import { Drawer, Title, Badge, Tooltip } from '@mantine/core';
import { TimelinePage } from './dialogs/PatientTimeline';
import { Bundle, Resource, ResourceType } from '@medplum/fhirtypes';
import { createReference, MedplumClient, ProfileResource } from '@medplum/core';
import PatientColumn from './patientColumn';

interface AppointmentTableProps {
  appointment: any; //UCAppointmentInformation;
  location?: Location;
  actionButtons: boolean;
  showTime: boolean;
  now: DateTime;
  tab: ApptTab;
  updateAppointments: () => void;
  setEditingComment: (editingComment: boolean) => void;
}

// const FLAGGED_REASONS_FOR_VISIT: string[] = [
//   'Breathing problem',
//   'Injury to head or fall on head',
//   'Choked or swallowed something',
//   'Allergic reaction',
// ];

// function getAppointmentStatusChip(status: string, count?: number): ReactElement {
//   if (!status) {
//     return <span>todo1</span>;
//   }
//   if (!CHIP_STATUS_MAP[status as keyof typeof CHIP_STATUS_MAP]) {
//     return <span>todo2</span>;
//   }

//   return (
//     <Chip
//       size="small"
//       label={count ? `${status} - ${count}` : status}
//       sx={{
//         borderRadius: '4px',
//         textTransform: 'uppercase',
//         background: CHIP_STATUS_MAP[status as keyof typeof CHIP_STATUS_MAP].background.primary,
//         color: CHIP_STATUS_MAP[status as keyof typeof CHIP_STATUS_MAP].color.primary,
//       }}
//       variant="outlined"
//     />
//   );
// }

// eslint-disable-next-line react-refresh/only-export-components
export const CHIP_STATUS_MAP: {
  [status in VisitStatus]: {
    background: {
      primary: string;
      secondary?: string;
    };
    color: {
      primary: string;
      secondary?: string;
    };
  };
} = {
  pending: {
    background: {
      primary: '#FFFFFF',
    },
    color: {
      primary: '#555555',
    },
  },
  arrived: {
    background: {
      primary: '#EEEEE',
      secondary: '#444444',
    },
    color: {
      primary: '#444444',
    },
  },
  ready: {
    background: {
      primary: '#CEF9BA',
      secondary: '#43A047',
    },
    color: {
      primary: '#446F30',
    },
  },
  intake: {
    background: {
      primary: '#fddef0',
    },
    color: {
      primary: '#684e5d',
    },
  },
  'provider-ready': {
    background: {
      primary: '#EEEEEE',
      secondary: '#444444',
    },
    color: {
      primary: '#444444',
    },
  },
  provider: {
    background: {
      primary: '#FDFCB7',
    },
    color: {
      primary: '#6F6D1A',
    },
  },
  discharge: {
    background: {
      primary: '#B2EBF2',
    },
    color: {
      primary: '#006064',
    },
  },
  'checked-out': {
    background: {
      primary: '#FFFFFF',
    },
    color: {
      primary: '#555555',
    },
  },
  cancelled: {
    background: {
      primary: '#FECDD2',
    },
    color: {
      primary: '#B71C1C',
    },
  },
  'no-show': {
    background: {
      primary: '#DFE5E9',
    },
    color: {
      primary: '#212121',
    },
  },
  unknown: {
    background: {
      primary: '#FFFFFF',
    },
    color: {
      primary: '#000000',
    },
  },
  booked: {
    background: {
      primary: '',
      secondary: undefined,
    },
    color: {
      primary: '',
      secondary: undefined,
    },
  },
};

const linkStyle = {
  display: 'contents',
  color: otherColors.tableRow,
};

// const longWaitTimeFlag = (appointment: UCAppointmentInformation, statusTime: number): boolean => {
// const longWaitTimeFlag = (appointment: any, statusTime: number): boolean => {
//   if (
//     appointment.status === 'ready for provider' ||
//     appointment.status === 'intake' ||
//     (appointment.status === 'ready' && appointment.appointmentType !== 'now')
//   ) {
//     if (statusTime > 45) {
//       return true;
//     }
//   }
//   return false;
// };

export default function AppointmentTableRow({
  appointment,
  location,
  actionButtons,
  // showTime,
  // now,
  tab,
  updateAppointments,
  setEditingComment,
}: AppointmentTableProps): ReactElement {
  // const { fhirClient } = useApiClients();
  const medplum = useMedplum();
  const sender = medplum.getProfile() as ProfileResource;
  const theme = useTheme();
  const [_editingRow, _setEditingRow] = useState<boolean>(false);
  const [_apptComment, setApptComment] = useState<string>(appointment.comment || '');
  // const [statusTime, setStatusTime] = useState<string>('');
  const [_noteSaving, _setNoteSaving] = useState<boolean>(false);
  // const [arrivedStatusSaving, setArrivedStatusSaving] = useState<boolean>(false);
  // const [chatModalOpen, setChatModalOpen] = useState<boolean>(false);
  const [_, setHasUnread] = useState<boolean>(appointment.smsModel?.hasUnreadMessages || false);
  // const user = useOttehrUser();
  const [opened, { open, close }] = useDisclosure(false);
  // const [history, setHistory] = useState<Bundle>();
  const [items, setItems] = useState<Resource[]>([]);

  const patientId = appointment.patient.actor.reference;

  const resourceType = 'Patient';
  const reference = { reference: resourceType + '/' + patientId };

  const resource = useResource(reference);

  // const patientName = `${appointment?.patient?.lastName}, ${appointment?.patient?.firstName}` || 'Unknown';
  const patientName = appointment?.patient?.display || 'Unknown';
  let start;
  // if (appointment.start) {
  //   const locationTimeZone = getTimezone(location);
  //   const dateTime = DateTime.fromISO(appointment.start).setZone(locationTimeZone);
  //   start = dateTime.toFormat('h:mm a');
  // }

  const loadTimelineResources = useCallback((medplum: MedplumClient, resourceType: ResourceType, id: string) => {
    const ref = `${resourceType}/${id}`;
    const _count = 1000;
    return Promise.allSettled([
      // medplum.readHistory('Patient', id),
      medplum.search('Communication', { subject: ref, _count }),
      // medplum.search('Device', { patient: ref, _count }),
      // medplum.search('DeviceRequest', { patient: ref, _count }),
      // medplum.search('DiagnosticReport', { subject: ref, _count }),
      medplum.search('Media', { subject: ref, _count }),
      // medplum.search('ServiceRequest', { subject: ref, _count }),
      // medplum.search('Task', { subject: ref, _count }),
    ]);
  }, []);

  const sortAndSetItems = useCallback(
    (newItems: Resource[]): void => {
      sortByDateAndPriority(newItems, resource);
      newItems.reverse();
      setItems(newItems);
    },
    [resource]
  );

  const handleBatchResponse = useCallback(
    (batchResponse: PromiseSettledResult<Bundle>[]): void => {
      const newItems = [];

      for (const settledResult of batchResponse) {
        if (settledResult.status !== 'fulfilled') {
          // User may not have access to all resource types
          continue;
        }

        const bundle = settledResult.value;
        // console.log('bundle', bundle);
        // if (bundle.type === 'history') {
        //   setHistory(bundle);
        // }

        if (bundle.entry) {
          for (const entry of bundle.entry) {
            newItems.push(entry.resource as Resource);
          }
        }
      }

      sortAndSetItems(newItems);
    },
    [sortAndSetItems]
  );

  const loadTimeline = useCallback(() => {
    let resourceType: ResourceType;
    let id: string;
    if ('resourceType' in reference) {
      resourceType = reference.resourceType;
      id = reference.reference?.split('/')[1] as string;
    } else {
      [resourceType, id] = reference.reference?.split('/') as [ResourceType, string];
    }
    loadTimelineResources(medplum, resourceType, id).then(handleBatchResponse).catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => loadTimeline(), [loadTimeline]);

  let userTimeString;

  if (appointment.start) {
    // const locationTimeZone = getTimezone(location);
    // const dateTime = DateTime.fromISO(appointment.start).setZone(locationTimeZone);
    // start = dateTime.toFormat('dd LLL yy h:mm a');
    // start = convertIsoToLocal(appointment.start)

    start = DateTime.fromISO(convertIsoToLocal(appointment.start)).toFormat('dd LLL yy hh:mm a');
  }

  if (appointment.userTimezone) {
    const userTime = DateTime.fromISO(appointment.start, { zone: 'utc' }).setZone(appointment.userTimezone);
    userTimeString = userTime.toFormat('dd LLL yy h:mm a');
  }

  // ovrp indicator icon logic
  // const ovrpResons = [
  //   'throat pain',
  //   'rash or skin issue',
  //   'urinary problem',
  //   'eye concern',
  //   'vomiting and/or diarrhea',
  // ];
  // const isOvrpReason = ovrpResons.some((reason) => appointment?.reasonForVisit?.toLocaleLowerCase()?.includes(reason));
  // const ageIsGoodForOVRP =
  //   DateTime.now().diff(
  //     DateTime.fromISO(
  //       appointment.needsDOBConfirmatioselectLocationMsgn
  //         ? appointment.unconfirmedDOB
  //         : appointment.patient?.dateOfBirth
  //     ),
  //     'years'
  //   ).years >= 3;

  // const showChatIcon = appointment.smsModel !== undefined;
  // console.log('sms model', appointment.smsModel);

  // const saveNote = async (_event: React.MouseEvent<HTMLElement>): Promise<void> => {
  //   // if (!fhirClient) {
  //   //   throw new Error('error getting fhir client');
  //   // }
  //   if (!appointment.id) {
  //     throw new Error('error getting appointment id');
  //   }
  //   try {
  //     setNoteSaving(true);
  //     let patchOp: 'replace' | 'add' | 'remove';
  //     const patchOperations = [];
  //     if (apptComment !== '') {
  //       patchOp = appointment.comment ? 'replace' : 'add';
  //       patchOperations.push({ op: patchOp, path: '/comment', value: apptComment });
  //     } else {
  //       patchOp = 'remove';
  //       patchOperations.push({ op: patchOp, path: '/comment' });
  //     }

  //     const { patient, ...rest } = appointment;

  //     const fhirAppointment = {
  //       resourceType: 'Appointment',
  //       ...rest,
  //       comment: apptComment,
  //     };

  //     delete fhirAppointment.location;
  //     delete fhirAppointment.practitioner;
  //     delete fhirAppointment.userTimezone;

  //     // const fhirAppointment = await medplum.readResource('Appointment', appointment.id);
  //     await medplum.updateResource(fhirAppointment);

  //     notifications.show({
  //       title: 'Note saved',
  //       message: 'The note has been updated successfully.',
  //       color: 'Green',
  //     });

  //     // medplum.updateResource(fhirAppointment, 'comment', medplum.user);

  //     // const fhirAppointment = await fhirClient.readResource({
  //     //   resourceType: 'Appointment',
  //     //   resourceId: appointment.id,
  //     // });
  //     // const staffUpdateTagOp = getUpdateTagOperation(fhirAppointment, 'comment', user);
  //     // patchOperations.push(staffUpdateTagOp);
  //     // await fhirClient.patchResource({
  //     //   resourceType: 'Appointment',
  //     //   resourceId: appointment.id,
  //     //   operations: patchOperations,
  //     // });
  //   } catch (error: unknown) {
  //     // todo tell the user there was an error
  //     console.log('error adding comment: ', error);
  //     setApptComment(appointment.comment || '');
  //     notifications.show({
  //       title: 'Something went wrong',
  //       message: 'There was an error updating the note.',
  //       color: 'red',
  //     });
  //   }
  //   setNoteSaving(false);
  //   setEditingRow(false);
  //   setEditingComment(false);
  //   await updateAppointments();
  // };

  useEffect(() => {
    setApptComment(appointment.comment || '');
  }, [appointment]);

  useEffect(() => {
    setHasUnread(appointment.smsModel?.hasUnreadMessages || false);
  }, [appointment.smsModel?.hasUnreadMessages]);

  // const handleArrivedClick = async (_event: React.MouseEvent<HTMLElement>): Promise<void> => {
  //   // if (!fhirClient) {
  //   //   throw new Error('error getting fhir client');
  //   // }
  //   if (!appointment.id) {
  //     throw new Error('error getting appointment id');
  //   }
  //   setArrivedStatusSaving(true);
  //   // await checkinPatient(fhirClient, appointment.id);
  //   setArrivedStatusSaving(false);
  //   await updateAppointments();
  // };

  // const recentStatus = appointment?.visitStatusHistory[appointment?.visitStatusHistory?.length - 1];
  // const { totalMinutes } = useMemo(() => {
  //   // const totalMinutes = getVisitTotalTime(appointment, appointment.visitStatusHistory, now);
  //   // will surface this waiting estimate to staff in the future
  //   // const waitingMinutesEstimate = appointment?.waitingMinutes;
  //   return { totalMinutes: 10 };
  // }, [appointment, now]);

  // if (recentStatus && recentStatus.period) {
  //   const currentStatusTime = getDurationOfStatus(recentStatus, appointment, appointment.visitStatusHistory, now);

  //   let statusTimeTemp =
  //     tab === ApptTab.cancelled || tab === ApptTab.completed || recentStatus.label === 'ready for discharge'
  //       ? `${formatMinutes(totalMinutes)}m`
  //       : `${formatMinutes(currentStatusTime)}m`;

  //   if (
  //     tab !== ApptTab.cancelled &&
  //     tab !== ApptTab.completed &&
  //     statusTimeTemp !== `${formatMinutes(totalMinutes)}m` &&
  //     recentStatus.label !== 'ready for discharge' &&
  //     appointment.visitStatusHistory &&
  //     appointment?.visitStatusHistory.length > 1
  //   ) {
  //     statusTimeTemp += ` / ${formatMinutes(totalMinutes)}m`;
  //   }

  //   if (statusTimeTemp !== statusTime) {
  //     setStatusTime(statusTimeTemp);
  //   }
  // }

  // const flagDOB = flagDateOfBirth(
  //   appointment.needsDOBConfirmation ? appointment.unconfirmedDOB : appointment.patient?.dateOfBirth
  // );
  // const patientDateOfBirth = (warning: boolean): ReactElement => (
  //   <Typography variant="body2" sx={{ color: warning ? otherColors.priorityHighText : theme.palette.text.secondary }}>
  //     DOB:{' '}
  //     {/* {formatDateUsingSlashes(
  //       appointment.needsDOBConfirmation ? appointment.unconfirmedDOB : appointment.patient?.dateOfBirth
  //     )} */}
  //   </Typography>
  // );

  // const isLongWaitingTime = useMemo(() => {
  //   return longWaitTimeFlag(appointment, parseInt(statusTime, 10) || 0);
  // }, [appointment, statusTime]);

  // function displayReasonsForVisit(reasonsForVisit: string[]): ReactElement {
  //   const flaggedReasons: string[] = [];
  //   const nonFlaggedReasons: string[] = [];
  //   reasonsForVisit?.forEach((reason) => {
  //     (FLAGGED_REASONS_FOR_VISIT?.includes(reason) ? flaggedReasons : nonFlaggedReasons).push(reason);
  //   });

  //   const reasonForVisitReactElement = (
  //     <span>
  //       {flaggedReasons.length > 0 && (
  //         <>
  //           {formattedPriorityHighIcon}
  //           <Typography
  //             sx={{ fontSize: '14px', color: otherColors.priorityHighText, paddingLeft: '5px', display: 'inline' }}
  //           >
  //             {nonFlaggedReasons.length > 0 ? flaggedReasons.join(', ') + ', ' : flaggedReasons.join(', ')}
  //           </Typography>
  //         </>
  //       )}
  //       {nonFlaggedReasons.length > 0 && (
  //         <Typography sx={{ fontSize: '14px', display: 'inline' }}>{nonFlaggedReasons.join(', ')}</Typography>
  //       )}
  //     </span>
  //   );

  //   // If a tooltip is used, wrap entire ReactElement in tooltip
  //   return flaggedReasons.length > 0 ? (
  //     <GenericToolTip title="Alert clinical team for immediate evaluation">{reasonForVisitReactElement}</GenericToolTip>
  //   ) : (
  //     reasonForVisitReactElement
  //   );
  // }

  // const formattedPriorityHighIcon = (
  //   <PriorityHighRoundedIcon
  //     style={{
  //       height: '14px',
  //       width: '14px',
  //       padding: '2px',
  //       color: theme.palette.primary.contrastText,
  //       backgroundColor: otherColors.priorityHighIcon,
  //       borderRadius: '4px',
  //       marginRight: '3px',
  //     }}
  //   />
  // );

  // const longWaitFlag = (
  //   <Box
  //     sx={{
  //       display: 'flex',
  //       gap: 1,
  //       alignItems: 'center',
  //     }}
  //   >
  //     <PriorityIconWithBorder fill={theme.palette.warning.main} />
  //     <Typography
  //       variant="body2"
  //       color={theme.palette.getContrastText(theme.palette.background.default)}
  //       style={{ display: 'inline', fontWeight: 700 }}
  //     >
  //       Long wait: Please check on patient
  //     </Typography>
  //   </Box>
  // );

  // const timeToolTip = (
  //   <Grid container sx={{ width: '100%' }}>
  //     <Box
  //       sx={{
  //         display: 'flex',
  //         flexDirection: 'column',
  //         gap: 2,
  //       }}
  //     >
  //       {isLongWaitingTime && longWaitFlag}
  //       {/* {getStatiForVisitTimeCalculation(appointment.visitStatusHistory, appointment.start).map((statusTemp) => {
  //         return (
  //           <Box sx={{ display: 'flex', gap: 1 }}>
  //             <Typography
  //               variant="body2"
  //               color={theme.palette.getContrastText(theme.palette.background.default)}
  //               style={{ display: 'inline', fontWeight: 700, marginTop: 1 }}
  //             >
  //               {formatMinutes(getDurationOfStatus(statusTemp, appointment, appointment.visitStatusHistory, now))} mins
  //             </Typography>
  //             {getAppointmentStatusChip(statusTemp.label as keyof typeof CHIP_STATUS_MAP)}
  //           </Box>
  //         );
  //       })} */}

  //       <Typography
  //         variant="body2"
  //         color={theme.palette.getContrastText(theme.palette.background.default)}
  //         style={{ display: 'inline' }}
  //       >
  //         {/* Total waiting: {formatMinutes(totalMinutes)} mins */}
  //       </Typography>
  //     </Box>
  //   </Grid>
  // );

  let reason = '';

  // if ((appointment?.serviceType?.length ?? 0) > 0) {
  //   reason = appointment?.serviceType?.[0]?.coding?.[0]?.display ?? '';
  // }

  if ((appointment?.reasonCode?.length ?? 0) > 0) {
    reason = appointment?.reasonCode?.[0]?.coding?.[0].code ?? '';
  }

  const ITEM_HEIGHT = 34;
  const ITEM_PADDING_Y = 16;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_Y,
        width: 150,
      },
    },
  };

  const handleChange = async (event: SelectChangeEvent): Promise<void> => {
    const { value } = event.target;

    if (value) {
      try {
        const { patient, ...rest } = appointment;
        delete rest.location;
        delete rest.practitioner;
        delete rest.userTimezone;

        await medplum.updateResource({
          resourceType: 'Appointment',
          ...rest,
          status: value,
        });
        updateAppointments();

        try {
          const text = `Appointment status has been changed from ${appointment.status} to ${value}`;

          await medplum.createResource({
            resourceType: 'Communication',
            status: 'completed',
            subject: createReference(resource),
            sender: createReference(sender),
            sent: new Date().toISOString(),
            payload: [{ contentString: text }],
          });
        } catch (error) {
          console.log('error', error);
        }

        const patientDetails = await medplum.readResource('Patient', patient.actor?.reference?.replace('Patient/', ''));

        // const email = patientDetails?.telecom?.find((telecom: { system: string }) => telecom.system === 'email')?.value;
        const phone = patientDetails?.telecom?.find((telecom: { system: string }) => telecom.system === 'phone')?.value;

        if (phone) {
          const body = `Dear ${patientName}! Your appointment status has been changed from ${appointment.status} to ${value}`;
          await medplum.sendSms({
            to: phone,
            body,
          });
        }
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  const locationName = appointment?.location?.display || 'N/A';

  const PractitionerName = appointment?.practitioner?.display || 'N/A';

  const locationAverageWaitingTime = appointment.location?.locationAverageWaitingTime || null;
  const locationAverageSessionTime = appointment.location?.locationAverageSessionTime || null;
  // const practitionerAverageWaitingTime = appointment.practitioner?.practitionerAverageWaitingTime || null;
  // const practitionerAverageSessionTime = appointment.practitioner?.practitionerAverageSessionTime || null;
  // const practitionerRoleAverageWaitingTime = appointment.practitioner?.practitionerRoleAverageWaitingTime || null;
  const practitionerRoleAverageSessionTime = appointment.practitioner?.practitionerRoleAverageSessionTime || null;

  return (
    <>
      {opened && (
        <Drawer
          opened={opened}
          onClose={close}
          title={<Title pl={15}>{`Timeline for ${patientName}`}</Title>}
          position="right"
          overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
          size="lg"
          transitionProps={{ transition: 'rotate-right', duration: 150, timingFunction: 'linear' }}
        >
          <TimelinePage id={appointment.patient.actor.reference} setItems={setItems} />
        </Drawer>
      )}
      <TableRow
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
          '&:hover': {
            backgroundColor: otherColors.apptHover,
          },
          position: 'relative',
          ...(appointment.next && {
            // borderTop: '2px solid #43A047',
            boxShadow: `inset 0 0 0 1px ${theme.palette.secondary.main}`,
          }),
        }}
      >
        <TableCell sx={{ verticalAlign: 'top' }}>
          {appointment.next && (
            <Box
              sx={{
                backgroundColor: theme.palette.secondary.main,
                position: 'absolute',
                width: '25px',
                bottom: 0,
                left: '-25px',
                height: '100%',
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
              }}
            >
              <Typography
                variant="body1"
                fontSize={14}
                sx={{
                  writingMode: 'vertical-lr',
                  transform: 'scale(-1)',
                  position: 'absolute',
                  top: '28%',
                  left: '10%',
                  color: theme.palette.background.paper,
                }}
              >
                NEXT
              </Typography>
            </Box>
          )}
          <Box>
            {/* <Link to={`/visit/${appointment.id}`} style={linkStyle}> */}
            <Link to={`/Appointment/${appointment.id}`} style={linkStyle}>
              <Box sx={{ display: 'flex' }}>
                {/* <CustomChip
                type={'status bullet'}
                fill={
                  appointment.appointmentType === 'prebook' ? theme.palette.primary.main : theme.palette.secondary.main
                }
              ></CustomChip> */}
                {/* status will either be booked or walked in - pending finalization of how we track walk ins */}
                <Typography variant="body1">
                  {/* {appointment.appointmentType} */}
                  {/* &nbsp;&nbsp;<strong>{start}</strong> */}
                  {start}
                </Typography>
              </Box>
              {/* <Box mt={1}>{getAppointmentStatusChip(appointment.status)}</Box> */}
            </Link>
          </Box>
        </TableCell>
        <TableCell sx={{ verticalAlign: 'top', wordWrap: 'break-word' }}>
          <Link to={`/Appointment/${appointment?.id}`} style={linkStyle}>
            {userTimeString} <br /> {appointment.userTimezone}
          </Link>
        </TableCell>
        {/* placeholder until time stamps for waiting and in exam or something comparable are made */}
        {/* <TableCell sx={{ verticalAlign: 'top' }}><Typography variant="body1" aria-owns={hoverElement ? 'status-popover' : undefined} aria-haspopup='true' sx={{ verticalAlign: 'top' }} onMouseOver={(event) => setHoverElement(event.currentTarget)} onMouseLeave={() => setHoverElement(undefined)}>{statusTime}</Typography></TableCell>
          <Popover id='status-popover' open={hoverElement !== undefined} anchorEl={hoverElement} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} transformOrigin={{ vertical: 'bottom', horizontal: 'right' }} onClose={() => setHoverElement(undefined)}><Typography>test</Typography></Popover> */}
        {/* {showTime && (
        <TableCell sx={{ verticalAlign: 'top' }}>
          <Link to={`/Appointment/${appointment.id}`} style={linkStyle}>
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: {
                    width: '100%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    padding: 2,
                    backgroundColor: theme.palette.background.default,
                    boxShadow:
                      '0px 1px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.20)',
                    '& .MuiTooltip-arrow': { color: theme.palette.background.default },
                  },
                },
              }}
              title={timeToolTip}
              placement="top"
              arrow
            >
              <Grid sx={{ display: 'flex', alignItems: 'center' }} gap={1}>
                <Grid item>{isLongWaitingTime && <PriorityIconWithBorder fill={theme.palette.warning.main} />}</Grid>
                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography
                    variant="body1"
                    sx={{ display: 'inline', fontWeight: `${isLongWaitingTime ? '700' : ''}` }}
                  >
                    {statusTime}
                  </Typography>
                  {appointment.visitStatusHistory && appointment.visitStatusHistory.length > 1 && (
                    <span style={{ color: 'rgba(0, 0, 0, 0.6)', display: 'flex', alignItems: 'center' }}>
                      <InfoOutlinedIcon
                        style={{
                          height: '20px',
                          width: '20px',
                          padding: '2px',
                          borderRadius: '4px',
                          marginLeft: '2px',
                          marginTop: '1px',
                        }}
                      />
                    </span>
                  )}
                </Grid>
              </Grid>
            </Tooltip>
          </Link>
        </TableCell>
      )} */}
        <TableCell sx={{ verticalAlign: 'top', wordWrap: 'break-word' }}>
          <Link to={`/Appointment/${appointment.id}`} style={linkStyle}>
            <Typography variant="body1" sx={{ fontSize: '16px' }}>
              <PatientColumn patientName={patientName} appointment={appointment} tab={tab} />
            </Typography>
            {/* {flagDOB || appointment.needsDOBConfirmation ? (
            <GenericToolTip
              title={`${flagDOB ? 'Expedited intake indicated' : ''}
                ${flagDOB && appointment.needsDOBConfirmation ? '&' : ''}
                ${appointment.needsDOBConfirmation ? 'Date of birth for returning patient was not confirmed' : ''}`}
              customWidth={flagDOB ? '150px' : '170px'}
            >
              <span style={{ display: 'flex', maxWidth: '150px', alignItems: 'center' }}>
                {flagDOB && formattedPriorityHighIcon}
                {patientDateOfBirth(flagDOB)}
                {appointment.needsDOBConfirmation && <PriorityIconWithBorder fill={theme.palette.warning.main} />}
              </span>
            </GenericToolTip>
          ) : (
            <>{patientDateOfBirth(false)}</>
          )} */}
          </Link>
        </TableCell>
        <TableCell sx={{ verticalAlign: 'top', wordWrap: 'break-word' }}>
          <Link to={`/Appointment/${appointment?.id}`} style={linkStyle}>
            {/* {displayReasonsForVisit(appointment?.reasonForVisit?.split(','))} */}
            {locationName}
            <br />
            {locationAverageWaitingTime ? (
              <div style={{ fontSize: '14px' }}>Est. Wait Time: {Math.round(Number(locationAverageWaitingTime))}m</div>
            ) : (
              ''
            )}
            {locationAverageSessionTime ? (
              <div style={{ fontSize: '14px' }}>
                Est. Session Time: {Math.round(Number(locationAverageSessionTime))}m
              </div>
            ) : (
              ''
            )}
            {/* {`${locationAverageWaitingTime ? `${Math.round(locationAverageWaitingTime)} min` : ''} `}{' '}
            {`${locationAverageSessionTime ? `/ ${Math.round(locationAverageSessionTime)} min` : ''} `} */}
          </Link>
        </TableCell>
        <TableCell sx={{ verticalAlign: 'top', wordWrap: 'break-word' }}>
          <Link to={`/Appointment/${appointment?.id}`} style={linkStyle}>
            {/* {displayReasonsForVisit(appointment?.reasonForVisit?.split(','))} */}
            {PractitionerName}
            <br />
            {practitionerRoleAverageSessionTime ? (
              <div style={{ fontSize: '14px' }}>
                Est. Session Time: {Math.round(Number(practitionerRoleAverageSessionTime))}m
              </div>
            ) : (
              ''
            )}
          </Link>
        </TableCell>
        <TableCell sx={{ verticalAlign: 'top', wordWrap: 'break-word' }}>
          <Link to={`/Appointment/${appointment?.id}`} style={linkStyle}>
            {/* {displayReasonsForVisit(appointment?.reasonForVisit?.split(','))} */}
            {reason}
          </Link>
        </TableCell>
        {/* <TableCell sx={{ verticalAlign: 'top' }}>
        <Link to={`/Appointment/${appointment.id}`} style={linkStyle}>
          <GenericToolTip
            title={
              <PaperworkToolTipContent
                appointment={appointment}
                isOvrpReason={isOvrpReason}
                ageIsGoodForOVRP={ageIsGoodForOVRP}
              />
            }
            customWidth="none"
          >
            <Box sx={{ display: 'flex', gap: 1 }}>
              <AccountCircleOutlinedIcon
                sx={{ ml: 0, color: appointment?.paperwork?.demographics ? '#43A047' : '#BFC2C6' }}
                fill={otherColors.cardChip}
              ></AccountCircleOutlinedIcon>

              <HealthAndSafetyOutlinedIcon
                sx={{ color: appointment?.paperwork?.insuranceCard ? '#43A047' : '#BFC2C6' }}
                fill={otherColors.cardChip}
              ></HealthAndSafetyOutlinedIcon>

              <BadgeOutlinedIcon
                sx={{ color: appointment?.paperwork?.photoID ? '#43A047' : '#BFC2C6' }}
                fill={otherColors.cardChip}
              ></BadgeOutlinedIcon>

              <AssignmentTurnedInOutlinedIcon
                sx={{ color: appointment?.paperwork?.consent ? '#43A047' : '#BFC2C6' }}
                fill={otherColors.cardChip}
              ></AssignmentTurnedInOutlinedIcon>

              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <RememberMeOutlinedIcon
                  sx={{
                    color:
                      ageIsGoodForOVRP && isOvrpReason && appointment?.paperwork?.ovrpInterest ? '#43A047' : '#BFC2C6',
                  }}
                  fill={otherColors.cardChip}
                ></RememberMeOutlinedIcon>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontSize: '16px',
                      color: appointment?.paperwork?.ovrpInterest ? '#43A047' : '#BFC2C6',
                    }}
                  >
                    +
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontSize: '16px',
                      color: ageIsGoodForOVRP && isOvrpReason ? '#43A047' : '#BFC2C6',
                    }}
                  >
                    +
                  </Typography>
                </Box>
              </Box>
            </Box>
          </GenericToolTip>
        </Link>
      </TableCell> */}
        {/* <TableCell sx={{ verticalAlign: 'top' }}>
        <Link to={`/Appointment/${appointment.id}`} style={linkStyle}>
          <Typography sx={{ fontSize: 14, display: 'inline' }}>{appointment.provider}</Typography>
        </Link>
      </TableCell> */}
        {/* <TableCell sx={{ verticalAlign: 'top' }}>
        <Link to={`/Appointment/${appointment.id}`} style={linkStyle}>
          <Typography sx={{ fontSize: 14, display: 'inline' }}>{appointment.group}</Typography>
        </Link>
      </TableCell> */}
        <TableCell sx={{ verticalAlign: 'top' }}>
          <Tooltip
            label={
              items.length > 0
                ? `Click to view ${items.length} note${items.length > 1 ? 's' : ''}`
                : `Click to add note(s)`
            }
          >
            <Badge
              size="xl"
              circle
              onClick={open}
              radius="xl"
              style={{
                cursor: 'pointer',
              }}
            >
              {items.length}
            </Badge>
          </Tooltip>
          {/* <Input
          placeholder={'Add internal note...'}
          value={apptComment}
          onChange={(e) => setApptComment(e.target.value)}
          multiline
          disableUnderline={!editingRow}
          inputProps={{ maxLength: 160 }}
          onClick={(_event) => {
            setEditingRow(true);
            setEditingComment(true);
          }}
          fullWidth
          sx={{ alignItems: 'baseline' }}
          startAdornment={
            <InputAdornment position="start">
              <EditNoteIcon sx={{ fill: theme.palette.text.disabled }}></EditNoteIcon>
            </InputAdornment>
          }
        />
        {editingRow && (
          <LoadingButton loading={noteSaving} sx={{ marginTop: '8px', padding: '5px' }} onClick={saveNote}>
            Save
          </LoadingButton>
        )} */}
        </TableCell>
        {/* <TableCell sx={{ verticalAlign: 'top' }}> */}
        {/* {showChatIcon && (
          <IconButton
            sx={{
              backgroundColor: theme.palette.primary.main,
              width: '36px',
              height: '36px',
              borderRadius: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              '&:hover': {
                backgroundColor: theme.palette.primary.main,
              },
            }}
            onClick={(_event) => {
              setChatModalOpen(true);
            }}
            aria-label={hasUnread ? 'unread messages chat icon' : 'chat icon, no unread messages'}
          > */}
        {/* todo reduce code duplication */}
        {/* {hasUnread ? (
              <Badge
                variant="dot"
                color="warning"
                sx={{
                  '& .MuiBadge-badge': {
                    width: '14px',
                    height: '14px',
                    borderRadius: '10px',
                    border: '2px solid white',
                    top: '-4px',
                    right: '-4px',
                  },
                }}
              >
                <ChatOutlineIcon
                  sx={{
                    color: theme.palette.primary.contrastText,
                    height: '20px',
                    width: '20px',
                  }}
                ></ChatOutlineIcon>
              </Badge>
            ) : (
              <ChatOutlineIcon
                sx={{
                  color: theme.palette.primary.contrastText,
                  height: '20px',
                  width: '20px',
                }}
              ></ChatOutlineIcon>
            )} */}
        {/* </IconButton> */}
        {/* )} */}
        {/* </TableCell> */}
        {/* {actionButtons && (
        <TableCell sx={{ verticalAlign: 'top' }}>
          <LoadingButton
            onClick={handleArrivedClick}
            loading={arrivedStatusSaving}
            variant="contained"
            sx={{
              borderRadius: 8,
              textTransform: 'none',
              fontSize: '15px',
              fontWeight: '700',
            }}
          >
            Arrived
          </LoadingButton>
        </TableCell>
      )} */}
        <TableCell sx={{ verticalAlign: 'top' }}>
          <Select
            labelId="select-label"
            id="select"
            label="Cancelation reason"
            size="small"
            variant="standard"
            value={appointment.status}
            onChange={handleChange}
            // renderValue={(selected) => selected}
            MenuProps={MenuProps}
            style={{ width: '150px' }}
          >
            {[
              { label: 'Proposed', value: 'proposed' },
              { label: 'Pending', value: 'pending' },
              { label: 'Booked', value: 'booked' },
              { label: 'Arrived', value: 'arrived' },
              { label: 'In Session', value: 'checked-in' },
              { label: 'Fulfilled', value: 'fulfilled' },
              { label: 'Cancelled', value: 'cancelled' },
              { label: 'No-show', value: 'no-show' },
              { label: 'Entered in error', value: 'entered-in-error' },
              { label: 'Wait list', value: 'waitlist' },
            ].map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                <ListItemText primary={label} />
              </MenuItem>
            ))}
          </Select>
        </TableCell>
        {/* {chatModalOpen && (
        <ChatModal
          appointment={appointment}
          currentLocation={location}
          onClose={() => setChatModalOpen(false)}
          onMarkAllRead={() => setHasUnread(false)}
        />
      )} */}
      </TableRow>
    </>
  );
}
