import { i18nextCreator } from '../helpers/i18nextCreator';
import * as englishStrings from '../theme/ottehr/i18n-en.json';
// import * as defaultEnglishStrings from '../theme/ottehr/index';
import * as spanishStrings from '../theme/ottehr/i18n-es.json';
// import * as defaultSpanishStrings from '../theme/ottehr/i18n-es.json';

// const englishTranslation = JSON.stringify({ ...defaultEnglishStrings, ...englishStrings });
// const spanishTranslation = JSON.stringify({ ...defaultSpanishStrings, ...spanishStrings });

const englishTranslation = JSON.stringify({ ...englishStrings });
const spanishTranslation = JSON.stringify({ ...spanishStrings });

interface Language {
  nativeName: string;
}

export const languages: { [key: string]: Language } = {
  en: { nativeName: 'English' },
  es: { nativeName: 'Español' },
};

const i18n = i18nextCreator({
  en: {
    translation: JSON.parse(englishTranslation),
  },
  es: {
    translation: JSON.parse(spanishTranslation),
  },
});

export default i18n;
