import { Document } from '@medplum/react';
import { UserRegisterForm } from './UserRegisterForm';

export function SignUpForm(props: any): JSX.Element {
  return (
    <Document width={450}>
      <UserRegisterForm>{props.children}</UserRegisterForm>
    </Document>
  );
}
