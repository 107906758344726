// import { User } from '@zapehr/sdk';
import { Appointment, Period } from 'fhir/r4';
import { DateTime } from 'luxon';
import { VisitStatus } from '../helpers/mappingUtils';
import { Practitioner } from '@medplum/fhirtypes';
// import { ExamFieldsNames, PractitionerLicense, VisitStatus } from 'ehr-utils';

export interface GetAppointmentsParameters {
  searchDate?: DateTime | undefined;
  locationID: string | undefined;
  visitType?: string[];
  providerIDs?: string[];
  groupIDs?: string[];
}

export interface CreateAppointmentParameters {
  slot?: string | undefined;
  patient: PatientInfo | undefined;
  locationID?: string | undefined;
  visitType: VisitType | undefined;
  scheduleType: 'location' | 'provider' | 'group' | undefined;
  visitService: 'in-person' | 'telemedicine' | undefined;
}

export interface UpdateAppointmentAndEncounterParameters {
  appointmentId: string | null;
  appointmentStatus: string | null;
  encounterStatus: string | null;
}

export interface GetPatientParameters {
  patientId: string | undefined;
}

export interface UpdateUserParameters {
  userId: string | undefined;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  nameSuffix?: string;
  selectedRoles?: string[] | undefined;
  licenses?: PractitionerLicense[];
  // locations: Location[];
}

export interface GetUserParameters {
  userId: string | undefined;
}

export interface License {
  label: string;
  value: string;
}

export interface State {
  label: string;
  value: string;
}

export const AllStates = [
  { value: 'AL', label: 'AL', timeZone: 'America/Chicago' }, // Alabama
  { value: 'AK', label: 'AK', timeZone: 'America/Anchorage' }, // Alaska
  { value: 'AZ', label: 'AZ', timeZone: 'America/Phoenix' }, // Arizona
  { value: 'AR', label: 'AR', timeZone: 'America/Chicago' }, // Arkansas
  { value: 'CA', label: 'CA', timeZone: 'America/Los_Angeles' }, // California
  { value: 'CO', label: 'CO', timeZone: 'America/Denver' }, // Colorado
  { value: 'CT', label: 'CT', timeZone: 'America/New_York' }, // Connecticut
  { value: 'DE', label: 'DE', timeZone: 'America/New_York' }, // Delaware
  { value: 'DC', label: 'DC', timeZone: 'America/New_York' }, // Washington DC
  { value: 'FL', label: 'FL', timeZone: 'America/New_York' }, // Florida
  { value: 'GA', label: 'GA', timeZone: 'America/New_York' }, // Georgia
  { value: 'HI', label: 'HI', timeZone: 'Pacific/Honolulu' }, // Hawaii
  { value: 'ID', label: 'ID', timeZone: 'America/Boise' }, // Idaho
  { value: 'IL', label: 'IL', timeZone: 'America/Chicago' }, // Illinois
  { value: 'IN', label: 'IN', timeZone: 'America/Indiana/Indianapolis' }, // Indiana
  { value: 'IA', label: 'IA', timeZone: 'America/Chicago' }, // Iowa
  { value: 'KS', label: 'KS', timeZone: 'America/Chicago' }, // Kansas
  { value: 'KY', label: 'KY', timeZone: 'America/New_York' }, // Kentucky
  { value: 'LA', label: 'LA', timeZone: 'America/Chicago' }, // Louisiana
  { value: 'ME', label: 'ME', timeZone: 'America/New_York' }, // Maine
  { value: 'MD', label: 'MD', timeZone: 'America/New_York' }, // Maryland
  { value: 'MA', label: 'MA', timeZone: 'America/New_York' }, // Massachusetts
  { value: 'MI', label: 'MI', timeZone: 'America/Detroit' }, // Michigan
  { value: 'MN', label: 'MN', timeZone: 'America/Chicago' }, // Minnesota
  { value: 'MS', label: 'MS', timeZone: 'America/Chicago' }, // Mississippi
  { value: 'MO', label: 'MO', timeZone: 'America/Chicago' }, // Missouri
  { value: 'MT', label: 'MT', timeZone: 'America/Denver' }, // Montana
  { value: 'NE', label: 'NE', timeZone: 'America/Chicago' }, // Nebraska
  { value: 'NV', label: 'NV', timeZone: 'America/Los_Angeles' }, // Nevada
  { value: 'NH', label: 'NH', timeZone: 'America/New_York' }, // New Hampshire
  { value: 'NJ', label: 'NJ', timeZone: 'America/New_York' }, // New Jersey
  { value: 'NM', label: 'NM', timeZone: 'America/Denver' }, // New Mexico
  { value: 'NY', label: 'NY', timeZone: 'America/New_York' }, // New York
  { value: 'NC', label: 'NC', timeZone: 'America/New_York' }, // North Carolina
  { value: 'ND', label: 'ND', timeZone: 'America/Chicago' }, // North Dakota
  { value: 'OH', label: 'OH', timeZone: 'America/New_York' }, // Ohio
  { value: 'OK', label: 'OK', timeZone: 'America/Chicago' }, // Oklahoma
  { value: 'OR', label: 'OR', timeZone: 'America/Los_Angeles' }, // Oregon
  { value: 'PA', label: 'PA', timeZone: 'America/New_York' }, // Pennsylvania
  { value: 'RI', label: 'RI', timeZone: 'America/New_York' }, // Rhode Island
  { value: 'SC', label: 'SC', timeZone: 'America/New_York' }, // South Carolina
  { value: 'SD', label: 'SD', timeZone: 'America/Chicago' }, // South Dakota
  { value: 'TN', label: 'TN', timeZone: 'America/Chicago' }, // Tennessee
  { value: 'TX', label: 'TX', timeZone: 'America/Chicago' }, // Texas
  { value: 'UT', label: 'UT', timeZone: 'America/Denver' }, // Utah
  { value: 'VT', label: 'VT', timeZone: 'America/New_York' }, // Vermont
  { value: 'VA', label: 'VA', timeZone: 'America/New_York' }, // Virginia
  { value: 'VI', label: 'VI', timeZone: 'America/Port_of_Spain' }, // Virgin Islands
  { value: 'WA', label: 'WA', timeZone: 'America/Los_Angeles' }, // Washington
  { value: 'WV', label: 'WV', timeZone: 'America/New_York' }, // West Virginia
  { value: 'WI', label: 'WI', timeZone: 'America/Chicago' }, // Wisconsin
  { value: 'WY', label: 'WY', timeZone: 'America/Denver' }, // Wyoming
] as const;

export type StateType = (typeof AllStates extends readonly (infer TElementType)[] ? TElementType : never)['value'];

export const AllStatesToNames: {
  [value in StateType]: string;
} = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

// import { Practitioner } from 'fhir/r4';

export type User = {
  phoneNumber: string; // as of current version of zap sdk phoneNumber is absent but back-end returns it.
  roles: { name: string }[];
  profileResource?: Practitioner;
};

export interface DeactivateUserParameters {
  user: User | undefined;
  // locations: Location[];
}

export interface CancelAppointmentParameters {
  appointmentID: string;
  cancellationReason: CancellationReasonOptions;
}

export interface EmployeeDetails {
  id: string;
  profile: string;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  status: 'Active' | 'Deactivated';
  lastLogin?: string;
  licenses: PractitionerLicense[];
  seenPatientRecently: boolean;
  isProvider: boolean;
}

export interface PractitionerLicense {
  state: string;
  code: PractitionerQualificationCode;
  active: boolean;
}

export type PractitionerQualificationCode =
  | 'MD'
  | 'PA'
  | 'DO'
  | 'NP'
  | 'RN'
  | 'CNM'
  | 'PMHNP'
  | 'RNFA'
  | 'ACSW'
  | 'APCC'
  | 'BCBA'
  | 'BCaBA'
  | 'BHA'
  | 'BHARI'
  | 'COUNSELOR'
  | 'OD'
  | 'DPM'
  | 'DA'
  | 'DDS'
  | 'DEH'
  | 'DMD'
  | 'DPT'
  | 'PT'
  | 'PTA'
  | 'PBT'
  | 'LCADC'
  | 'LCAT'
  | 'LCMHC'
  | 'LCMHC-S'
  | 'LCMHCA'
  | 'LCPC'
  | 'LCSW'
  | 'LSW'
  | 'LCSWA'
  | 'LICSW'
  | 'LIMHP-CSW'
  | 'LIMHP-CMSW'
  | 'LIMHP'
  | 'LISW'
  | 'LMFT'
  | 'LMFT-S'
  | 'LMFTA'
  | 'LMHC'
  | 'LMSW'
  | 'LPC'
  | 'LPC-C'
  | 'LPCA'
  | 'LPCC'
  | 'LPCI'
  | 'LSCSW'
  | 'MFTA'
  | 'MHCA'
  | 'MHT'
  | 'OMS'
  | 'ORTH'
  | 'OT'
  | 'OTA'
  | 'OTHER'
  | 'RD'
  | 'RPh'
  | 'PhT'
  | 'PhD'
  | 'PsyD'
  | 'RBT'
  | 'RCSWI'
  | 'RHMCI'
  | 'SLP'
  | 'LPN'
  | 'LAc'
  | 'LMT'
  | 'DC'
  | 'ND'
  | 'MA'
  | 'LPCMH'
  | 'LIMHP-CPC'
  | 'LPC-MH'
  | 'LPC/MHSP'
  | 'CMHC'
  | 'ALC'
  | 'LAC'
  | 'LACMH'
  | 'LAPC'
  | 'ACMHC'
  | 'LMHCA'
  | 'LGPC'
  | 'CSW'
  | 'LCSW-C'
  | 'ISW'
  | 'LISW-CP'
  | 'CSW-PIP'
  | 'CISW'
  | 'MLSW'
  | 'CMSW'
  | 'LGSW'
  | 'LASW'
  | 'APSW'
  | 'RT'
  | 'AUD'
  | 'CRNA'
  | 'OPTHAMOLOGIST'
  | 'OPA'
  | 'CP-A'
  | 'AT'
  | 'FITNESS-PROFESSI'
  | 'EMR'
  | 'EMT'
  | 'EMT-A'
  | 'LVN'
  | 'MLT'
  | 'NMT'
  | 'LN'
  | 'OPT'
  | 'OTHER-ALLIED-HEA'
  | 'PAR'
  | 'PHARMACY-ASSISTA'
  | 'PI'
  | 'PODIATRIC-ASSIST'
  | 'RADIOLOGY-TECH'
  | 'ACU'
  | 'DT'
  | 'MOT'
  | 'MPT'
  | 'MT'
  | 'OTD'
  | 'OTR/L'
  | 'RDN'
  | 'SLP-CF'
  | 'SLPD'
  | 'ATC'
  | 'COTA'
  | 'LMPC'
  | 'ORT'
  | 'SLPA'
  | 'MT-BC';

export const PractitionerQualificationCodesLabels: Record<PractitionerQualificationCode, string> = {
  'CSW-PIP': 'Clinical Social Worker-Private Independent Practice',
  'FITNESS-PROFESSI': 'Fitness Professional (not a standardized code)',
  'LISW-CP': 'Licensed Independent Social Worker-Clinical Practice',
  'PHARMACY-ASSISTA': 'Pharmacy Assistant (not a standardized code)',
  'PODIATRIC-ASSIST': 'Podiatric Assistant (may vary by state)',
  CISW: 'Certified Independent Social Worker',
  CMSW: 'Clinical Master Social Worker',
  COUNSELOR: 'Counselor',
  ISW: 'Independent Social Worker',
  MLSW: 'Master of Social Work',
  ORTH: '',
  RHMCI: '',
  DO: 'Doctor of Osteopathic Medicine',
  MD: 'Doctor of Medicine',
  PA: 'Physician Assistant',
  RN: 'Registered Nurse',
  NP: 'Nurse Practitioner',
  CNM: 'Certified Nurse Midwife',
  PMHNP: 'Psychiatric Mental Health Nurse Practitioner',
  RNFA: 'Registered Nurse First Assistant',
  ACSW: "Master's level Clinical Social Worker",
  APCC: 'Advanced Practice Clinical Counselor',
  BCBA: 'Board Certified Behavior Analyst',
  BCaBA: 'Board Certified Assistant Behavior Analyst',
  BHA: 'Bachelor of Health Administration',
  BHARI: 'Behavioral Health Associate in Recovery',
  OD: 'Doctor of Optometry',
  DPM: 'Doctor of Podiatric Medicine',
  DA: 'Dental Assistant',
  DDS: 'Doctor of Dental Surgery',
  DEH: 'Dental Hygienist',
  DMD: 'Doctor of Dental Medicine',
  DPT: 'Doctor of Physical Therapy',
  PT: 'Physical Therapist',
  PTA: 'Physical Therapist Assistant',
  PBT: 'Physical Therapist Aide',
  LCADC: 'Licensed Clinical Alcohol and Drug Counselor',
  LCAT: 'Licensed Marriage and Family Therapist',
  LCMHC: 'Licensed Clinical Mental Health Counselor',
  'LCMHC-S': 'Licensed Clinical Mental Health Counselor Supervisor',
  LCMHCA: 'Licensed Clinical Mental Health Counselor Associate',
  LCPC: 'Licensed Clinical Professional Counselor',
  LCSW: 'Licensed Clinical Social Worker',
  LSW: 'Licensed Social Worker',
  LCSWA: 'Licensed Certified Social Worker Assistant',
  LICSW: 'Licensed Independent Clinical Social Worker',
  'LIMHP-CSW': 'Licensed Independent Mental Health Professional-Clinical Social Worker',
  'LIMHP-CMSW': 'Licensed Independent Mental Health Professional-Clinical Master Social Worker',
  LIMHP: 'Licensed Independent Mental Health Professional (followed by specific area)',
  LISW: 'Licensed Independent Social Worker',
  LMFT: 'Licensed Marriage and Family Therapist',
  'LMFT-S': 'Licensed Marriage and Family Therapist Supervisor',
  LMFTA: 'Licensed Marriage and Family Therapist Associate',
  LMHC: 'Licensed Mental Health Counselor (may vary by state)',
  LMSW: 'Licensed Master Social Worker',
  LPC: 'Licensed Professional Counselor',
  'LPC-C': 'Licensed Professional Counselor-Candidate/Provisional',
  LPCA: 'Licensed Professional Counselor Associate',
  LPCC: 'Licensed Professional Clinical Counselor',
  LPCI: 'Licensed Professional Counselor Intern',
  LSCSW: 'Licensed Specialist Clinical Social Worker',
  MFTA: 'Marriage and Family Therapist Assistant',
  MHCA: 'Mental Health Counselor Aide',
  MHT: 'Mental Health Technician',
  OMS: 'Oral and Maxillofacial Surgeon',
  OT: 'Occupational Therapist',
  OTA: 'Occupational Therapist Assistant',
  OTHER: 'Other Healthcare Professional (specify)',
  RD: 'Registered Dietitian',
  RPh: 'Registered Pharmacist',
  PhT: 'Pharmacy Technician',
  PhD: 'Doctor of Philosophy (in a healthcare field)',
  PsyD: 'Doctor of Psychology',
  RBT: 'Registered Behavior Technician',
  RCSWI: 'Registered Clinical Social Work Intern',
  SLP: 'Speech-Language Pathologist',
  LPN: 'Licensed Practical Nurse',
  LAc: 'Licensed Acupuncturist',
  LMT: 'Licensed Massage Therapist',
  DC: 'Doctor of Chiropractic',
  ND: 'Doctor of Naturopathic Medicine',
  MA: 'Medical Assistant',
  LPCMH: 'Licensed Professional Counselor in Mental Health',
  'LIMHP-CPC': 'Licensed Independent Mental Health Professional-Clinical Pastoral Counselor',
  'LPC-MH': 'Licensed Professional Counselor-Mental Health',
  'LPC/MHSP': 'Licensed Professional Counselor/Mental Health Service Provider',
  CMHC: 'Certified Mental Health Counselor',
  ALC: 'Addictions Counselor',
  LAC: 'Licensed Addiction Counselor',
  LACMH: 'Licensed Addiction Counselor-Mental Health',
  LAPC: 'Licensed Associate Professional Counselor',
  ACMHC: 'Associate Certified Mental Health Counselor',
  LMHCA: 'Licensed Master Mental Health Counselor',
  LGPC: 'Licensed Graduate Professional Counselor',
  CSW: 'Clinical Social Worker',
  'LCSW-C': 'Licensed Clinical Social',
  LGSW: 'Licensed Graduate Social Worker',
  LASW: 'Licensed Addiction Specialist Social Worker (may vary by state)',
  APSW: 'Academy of Certified Social Workers (not a specific license)',
  RT: 'Respiratory Therapist',
  AUD: 'Audiologist',
  CRNA: 'Certified Registered Nurse Anesthetist',
  OPTHAMOLOGIST: 'Ophthalmologist (M.D. specializing in the eye)',
  OPA: 'Ophthalmic Technician/Assistant',
  'CP-A': 'Unknown meaning** (not a recognized code in standard healthcare classification systems)',
  AT: 'Athletic Trainer',
  EMR: 'Electronic Medical Records (not a profession)',
  EMT: 'Emergency Medical Technician (basic level)',
  'EMT-A': 'Emergency Medical Technician-Advanced',
  LVN: 'Licensed Vocational Nurse',
  MLT: 'Medical Laboratory Technician',
  NMT: 'Nuclear Medicine Technologist',
  LN: 'Licensed Dietitian/Nutritionist (may vary by state)',
  OPT: 'Ophthalmic Technician (may vary from OPA)',
  'OTHER-ALLIED-HEA': 'Other Allied Health Professional (specify)',
  PAR: 'Physician Assistant (PA is more common)',
  PI: 'Physician Assistant (PA is more common)',
  'RADIOLOGY-TECH': 'Radiologic Technologist (general term)',
  ACU: 'Acupuncturist (may differ from LAc)',
  DT: 'Dance Therapist',
  MOT: 'Occupational Therapist (OT is more common)',
  MPT: 'Master of Physical Therapy (not a profession)',
  MT: 'Massage Therapist (may differ from LMT)',
  OTD: 'Doctor of Occupational Therapy (OT is more common)',
  'OTR/L': 'Occupational Therapist Registered/Licensed',
  RDN: 'Registered Dietitian Nutritionist',
  'SLP-CF': 'Speech-Language Pathologist-Clinical Fellow (student)',
  SLPD: 'Speech-Language Pathologist (general term)',
  ATC: 'Athletic Trainer (already mentioned)',
  COTA: 'Certified Occupational Therapist Assistant',
  LMPC: 'Licensed Marriage and Family Therapist (already mentioned)',
  ORT: 'Orthotist (may differ from CPO)',
  SLPA: 'Speech-Language Pathology Assistant',
  'MT-BC': 'Music Therapist-Board Certified',
};

export const PractitionerQualificationCodesDisplay = Object.keys(PractitionerQualificationCodesLabels).map((key) => ({
  value: key,
  label: `${key} (${PractitionerQualificationCodesLabels[key as PractitionerQualificationCode]})`,
}));

export interface PractitionerLicense {
  state: string;
  code: PractitionerQualificationCode;
  active: boolean;
}

export interface GetEmployeesResponse {
  message: string;
  employees: EmployeeDetails[];
}

export enum RoleType {
  NewUser = 'NewUser',
  Administrator = 'Administrator',
  AssistantAdmin = 'AssistantAdmin',
  RegionalTelemedLead = 'RegionalTelemedLead',
  CallCentre = 'CallCentre',
  Billing = 'Billing',
  Manager = 'Manager',
  Staff = 'Staff',
  Provider = 'Provider',
  FrontDesk = 'Front Desk',
  Inactive = 'Inactive',
}

export interface AccessPolicy {
  rule: {
    action: string | string[];
    resource: string | string[];
    effect: 'Allow' | 'Deny';
  }[];
}

export enum CancellationReasonOptions {
  'Patient improved' = 'Patient improved',
  'Wait time too long' = 'Wait time too long',
  'Prefer another urgent care provider' = 'Prefer another urgent care provider',
  'Changing location' = 'Changing location',
  'Changing to telemedicine' = 'Changing to telemedicine',
  'Financial responsibility concern' = 'Financial responsibility concern',
  'Insurance issue' = 'Insurance issue',
  'Service not offered at' = 'Service not offered at',
  'Duplicate visit or account error' = 'Duplicate visit or account error',
}

export interface SMSRecipient {
  relatedPersonId: string;
  smsNumber: string;
}
export interface SMSModel {
  recipients: SMSRecipient[];
  hasUnreadMessages: boolean;
}

export interface VisitStatusHistoryEntry {
  status: VisitStatus;
  label: VisitStatus;
  period: Period;
}

export type EmailUserValue = 'Patient (Self)' | 'Parent/Guardian';

export interface AppointmentInformation {
  id: string;
  start: string;
  // unreadMessage: boolean;
  patient: {
    id: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
  };
  reasonForVisit: string;
  comment: string | undefined;
  personID: string;
  appointmentType: string;
  appointmentStatus: string;
  status: VisitStatus;
  cancellationReason: string | undefined;
  paperwork: {
    demographics: boolean;
    photoID: boolean;
    insuranceCard: boolean;
    consent: boolean;
  };
  next: boolean;
  visitStatusHistory: VisitStatusHistoryEntry[];
  unconfirmedDateOfBirth: boolean | undefined;
}

export type PatientInfo = {
  id: string | undefined;
  newPatient: boolean;
  // pointOfDiscovery: boolean; // if this info has been obtained, true & 'How did you hear about us' will not show
  firstName: string | undefined;
  lastName: string | undefined;
  dateOfBirth: string | undefined;
  sex: PersonSex | undefined;
  phoneNumber: string | undefined;
  email: string | undefined;
  emailUser: EmailUserValue | undefined;
  reasonForVisit: string[] | undefined;
};

export const appointmentTypeLabels: { [type in FhirAppointmentType]: string } = {
  prebook: 'Pre-booked',
  walkin: 'Walk-in',
  virtual: 'Telemed',
};

export enum VisitType {
  Now = 'now',
  Prebook = 'prebook',
}

export const VisitTypeToLabel: { [visittype in VisitType]: string } = {
  now: 'now',
  prebook: 'prebook',
};

export enum PersonSex {
  Male = 'male',
  Female = 'female',
  Intersex = 'other',
}

export enum FhirAppointmentType {
  walkin = 'walkin',
  prebook = 'prebook',
  virtual = 'virtual',
}

export const getFhirAppointmentTypeForVisitType = (
  visitType: VisitType | undefined
): FhirAppointmentType | undefined => {
  if (visitType === VisitType.Now) {
    return FhirAppointmentType.walkin;
  } else if (visitType === VisitType.Prebook) {
    return FhirAppointmentType.prebook;
  } else {
    return undefined;
  }
};

export const getVisitTypeLabelForAppointment = (appointment: Appointment): string => {
  const fhirAppointmentType = appointment?.appointmentType?.text as FhirAppointmentType;

  if (fhirAppointmentType === FhirAppointmentType.walkin) {
    return 'Now Urgent Care Visit';
  } else if (fhirAppointmentType === FhirAppointmentType.prebook) {
    return 'Pre-booked Urgent Care Visit (4Online)';
  } else if (fhirAppointmentType === FhirAppointmentType.virtual) {
    return 'Telemed';
  }
  return '-';
};

export type DOW = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';
export type HourOfDay =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23;

export interface Capacity {
  hour: HourOfDay;
  capacity: number;
}

export interface ScheduleDay {
  open: HourOfDay;
  close: HourOfDay;
  openingBuffer: number;
  closingBuffer: number;
  workingDay: boolean;
  hours: Capacity[];
}
export interface Weekdays {
  [day: string]: Weekday;
}

export interface Overrides {
  [day: string]: Day;
}

export interface Day {
  open: number;
  close: number;
  openingBuffer: number;
  closingBuffer: number;
  hours: Capacity[];
}

export interface Weekday extends Day {
  workingDay: boolean;
}

export enum ClosureType {
  OneDay = 'one-day',
  Period = 'period',
}
export interface Closure {
  start: string;
  end: string;
  type: ClosureType;
}

export interface ScheduleExtension {
  schedule: DailySchedule | undefined;
  scheduleOverrides: ScheduleOverrides | undefined;
  closures: Closure[] | undefined;
}

export type DailySchedule = Record<DOW, ScheduleDay>;
export type ScheduleOverrides = Record<string, ScheduleDay>;

export interface lastModifiedCode {
  lastModifiedDate: DateTime;
  lastModifiedBy: string | undefined;
  lastModifiedByID: string | undefined;
}

export type ExamTabCardNames =
  | 'vitals'
  | 'general'
  | 'head'
  | 'eyes'
  | 'nose'
  | 'ears'
  | 'mouth'
  | 'neck'
  | 'chest'
  | 'back'
  | 'skin'
  | 'abdomen'
  | 'musculoskeletal'
  | 'neurological'
  | 'psych';

export type ExamTabProviderCardNames = Exclude<ExamTabCardNames, 'vitals'>;

export type ExamTabGroupNames =
  | 'normal'
  | 'abnormal'
  | 'rightEye'
  | 'leftEye'
  | 'rightEar'
  | 'leftEar'
  | 'form'
  | 'dropdown';

export type ExamObservationFieldItem = {
  field: ExamFieldsNames;
  defaultValue: boolean;
  abnormal: boolean;
  group: ExamTabGroupNames;
  card: ExamTabProviderCardNames;
  label: string;
};

export enum CardType {
  InsuranceFront = 'insurance-card-front',
  InsuranceBack = 'insurance-card-back',
  PhotoIdFront = 'photo-id-front',
  PhotoIdBack = 'photo-id-back',
}
export interface CardInfo {
  type: CardType;
  z3Url: string;
  presignedUrl: string | undefined;
}

export type ExamCardsNames =
  | 'general-comment'
  | 'head-comment'
  | 'eyes-comment'
  | 'nose-comment'
  | 'ears-comment'
  | 'mouth-comment'
  | 'neck-comment'
  | 'chest-comment'
  | 'back-comment'
  | 'skin-comment'
  | 'abdomen-comment'
  | 'extremities-musculoskeletal-comment'
  | 'neurological-comment'
  | 'psych-comment';

export type ExamFieldsNames =
  // >>> CARD = general
  // >> GROUP = normal
  | 'alert'
  | 'awake'
  | 'calm'
  | 'well-hydrated'
  | 'moist-mucous-membrane'
  | 'distress-none'
  | 'playful-and-active'
  // >> GROUP = abnormal
  | 'tired-appearing'
  | 'ill-appearing'
  | 'fussy'
  | 'dry-mucous-membranes'
  | 'sunken-eye'
  | 'mild-distress'
  | 'moderate-distress'
  | 'severe-distress'
  // >>> CARD = head
  // >> GROUP = normal
  | 'normocephaly'
  | 'atraumatic'
  // >>> CARD = eyes
  // >> GROUP = normal
  | 'pupils-symmetric'
  | 'eomi'
  // >> GROUP = abnormal
  | 'pupils-asymmetric'
  // >> GROUP = rightEye
  | 'right-eye-normal'
  | 'right-eye-injected'
  | 'right-eye-discharge'
  | 'right-eye-watering'
  | 'right-eye-puffy-eyelids'
  | 'right-eye-small-round-mass-in-eyelid'
  // >> GROUP = leftEye
  | 'left-eye-normal'
  | 'left-eye-injected'
  | 'left-eye-discharge'
  | 'left-eye-watering'
  | 'left-eye-puffy-eyelids'
  | 'left-eye-small-round-mass-in-eyelid'
  // >>> CARD = nose
  // >> GROUP = normal
  | 'no-drainage'
  // >> GROUP = abnormal
  | 'clear-rhinorrhea'
  | 'purulent-discharge'
  // >>> CARD = ears
  // >> GROUP = rightEar
  | 'normal-ear-right'
  | 'erythema-ear-right'
  | 'swelling-ear-right'
  | 'pain-with-movement-of-pinna-ear-right'
  | 'drainage-from-ear-canal-right'
  | 'clear-discharge-ear-right'
  // >> GROUP = leftEar
  | 'normal-ear-left'
  | 'erythema-ear-left'
  | 'swelling-ear-left'
  | 'pain-with-movement-of-pinna-ear-left'
  | 'drainage-from-ear-canal-left'
  | 'clear-discharge-ear-left'
  // >>> CARD = mouth
  // >> GROUP = normal
  | 'mouth-normal'
  | 'mouth-moist-mucous-membrane'
  | 'normal-tongue'
  | 'uvula-midline'
  | 'tonsils-symmetric-and-not-enlarged'
  | 'normal-voice'
  // >> GROUP = abnormal
  | 'mouth-dry-mucous-membranes'
  | 'erythema-of-pharynx'
  | 'white-patches-on-tongue-andor-buccal-mucosa-that-do-not-wipe-off'
  | 'strawberry-tongue'
  | 'uvula-deviated'
  | 'tonsils-erythematous'
  | 'exudate-on-tonsils'
  | 'hoarse-voice'
  | 'hot-potato-voice'
  // >>> CARD = neck
  // >> GROUP = normal
  | 'supple-neck'
  | 'moves-in-all-directions'
  // >>> CARD = chest
  // >> GROUP = normal
  | 'normal-respiratory-effort'
  | 'no-tachypnea'
  | 'no-retractions'
  | 'no-conversational-dyspnea'
  // >> GROUP = abnormal
  | 'tachypnea'
  | 'suprasternal-retractions'
  | 'intercostal-retractions'
  | 'subcostal-retractions'
  | 'abdominal-breathing'
  | 'grunting'
  | 'nasal-flaring'
  | 'wheeze'
  | 'barky-cough'
  | 'stridor-with-each-breath'
  // >>> CARD = back
  // >> GROUP = normal
  | 'back-normal'
  | 'able-to-flex-and-extend-back-and-move-side-to-side'
  // >> GROUP = abnormal
  | 'cva-tenderness'
  // >>> CARD = skin
  // >> GROUP = normal
  | 'no-rashes'
  // >> GROUP = form
  | 'consistent-with-viral-exam'
  | 'consistent-with-insect-bites'
  | 'consistent-with-urticaria'
  | 'consistent-with-coxsackievirus'
  | 'consistent-with-irritant-diaper-rash'
  | 'consistent-with-ringworm'
  | 'consistent-with-impetigo'
  | 'consistent-with-fifths-disease'
  | 'consistent-with-atopic-dermatitis'
  | 'consistent-with-paronychia'
  | 'consistent-with-poison-ivy-contact-dermatitis'
  | 'consistent-with-tinea-capitis'
  | 'consistent-with-pityriasis-rosea'
  | 'consistent-with-lyme-ecm'
  // >>> CARD = abdomen
  // >> GROUP = normal
  | 'normal-appearing-on-parental-exam'
  | 'non-tender-on-parental-exam'
  | 'able-to-jump-up-down-without-abdominal-pain'
  // >> GROUP = abnormal
  | 'left-lower-quadrant-abdomen'
  | 'right-lower-quadrant-abdomen'
  | 'right-upper-quadrant-abdomen'
  | 'left-upper-quadrant-abdomen'
  | 'epigastric-region-abdomen'
  | 'left-abdominal-lumbar-region-abdomen'
  | 'right-abdominal-lumbar-region-abdomen'
  | 'not-able-to-jump-up-down-due-to-abdominal-pain'
  // >>> CARD = musculoskeletal
  // >> GROUP = normal
  | 'moving-extemities-equally'
  | 'normal-gait'
  | 'normal-rom'
  | 'no-swelling'
  | 'no-bruising'
  | 'no-deformity'
  // >> GROUP = form
  | 'swelling-left-finger-index'
  | 'swelling-right-finger-index'
  | 'swelling-left-finger-middle'
  | 'swelling-right-finger-middle'
  | 'swelling-left-finger-ring'
  | 'swelling-right-finger-ring'
  | 'swelling-left-finger-little'
  | 'swelling-right-finger-little'
  | 'swelling-left-finger-thumb'
  | 'swelling-right-finger-thumb'
  | 'swelling-left-hand'
  | 'swelling-right-hand'
  | 'swelling-left-foot'
  | 'swelling-right-foot'
  | 'swelling-left-toe-great'
  | 'swelling-right-toe-great'
  | 'swelling-left-toe-second'
  | 'swelling-right-toe-second'
  | 'swelling-left-toe-third'
  | 'swelling-right-toe-third'
  | 'swelling-left-toe-fourth'
  | 'swelling-right-toe-fourth'
  | 'swelling-left-toe-fifth'
  | 'swelling-right-toe-fifth'
  | 'swelling-left-wrist'
  | 'swelling-right-wrist'
  | 'swelling-left-forearm'
  | 'swelling-right-forearm'
  | 'swelling-left-elbow'
  | 'swelling-right-elbow'
  | 'swelling-left-upperarm'
  | 'swelling-right-upperarm'
  | 'swelling-left-shoulder'
  | 'swelling-right-shoulder'
  | 'swelling-left-knee'
  | 'swelling-right-knee'
  | 'swelling-left-lowerleg'
  | 'swelling-right-lowerleg'
  | 'swelling-left-ankle'
  | 'swelling-right-ankle'
  | 'deformity-left-finger-index'
  | 'deformity-right-finger-index'
  | 'deformity-left-finger-middle'
  | 'deformity-right-finger-middle'
  | 'deformity-left-finger-ring'
  | 'deformity-right-finger-ring'
  | 'deformity-left-finger-little'
  | 'deformity-right-finger-little'
  | 'deformity-left-finger-thumb'
  | 'deformity-right-finger-thumb'
  | 'deformity-left-hand'
  | 'deformity-right-hand'
  | 'deformity-left-foot'
  | 'deformity-right-foot'
  | 'deformity-left-toe-great'
  | 'deformity-right-toe-great'
  | 'deformity-left-toe-second'
  | 'deformity-right-toe-second'
  | 'deformity-left-toe-third'
  | 'deformity-right-toe-third'
  | 'deformity-left-toe-fourth'
  | 'deformity-right-toe-fourth'
  | 'deformity-left-toe-fifth'
  | 'deformity-right-toe-fifth'
  | 'deformity-left-wrist'
  | 'deformity-right-wrist'
  | 'deformity-left-forearm'
  | 'deformity-right-forearm'
  | 'deformity-left-elbow'
  | 'deformity-right-elbow'
  | 'deformity-left-upperarm'
  | 'deformity-right-upperarm'
  | 'deformity-left-shoulder'
  | 'deformity-right-shoulder'
  | 'deformity-left-knee'
  | 'deformity-right-knee'
  | 'deformity-left-lowerleg'
  | 'deformity-right-lowerleg'
  | 'deformity-left-ankle'
  | 'deformity-right-ankle'
  | 'bruising-left-finger-index'
  | 'bruising-right-finger-index'
  | 'bruising-left-finger-middle'
  | 'bruising-right-finger-middle'
  | 'bruising-left-finger-ring'
  | 'bruising-right-finger-ring'
  | 'bruising-left-finger-little'
  | 'bruising-right-finger-little'
  | 'bruising-left-finger-thumb'
  | 'bruising-right-finger-thumb'
  | 'bruising-left-hand'
  | 'bruising-right-hand'
  | 'bruising-left-foot'
  | 'bruising-right-foot'
  | 'bruising-left-toe-great'
  | 'bruising-right-toe-great'
  | 'bruising-left-toe-second'
  | 'bruising-right-toe-second'
  | 'bruising-left-toe-third'
  | 'bruising-right-toe-third'
  | 'bruising-left-toe-fourth'
  | 'bruising-right-toe-fourth'
  | 'bruising-left-toe-fifth'
  | 'bruising-right-toe-fifth'
  | 'bruising-left-wrist'
  | 'bruising-right-wrist'
  | 'bruising-left-forearm'
  | 'bruising-right-forearm'
  | 'bruising-left-elbow'
  | 'bruising-right-elbow'
  | 'bruising-left-upperarm'
  | 'bruising-right-upperarm'
  | 'bruising-left-shoulder'
  | 'bruising-right-shoulder'
  | 'bruising-left-knee'
  | 'bruising-right-knee'
  | 'bruising-left-lowerleg'
  | 'bruising-right-lowerleg'
  | 'bruising-left-ankle'
  | 'bruising-right-ankle'
  | 'abletobearweight-left-finger-index'
  | 'abletobearweight-right-finger-index'
  | 'abletobearweight-left-finger-middle'
  | 'abletobearweight-right-finger-middle'
  | 'abletobearweight-left-finger-ring'
  | 'abletobearweight-right-finger-ring'
  | 'abletobearweight-left-finger-little'
  | 'abletobearweight-right-finger-little'
  | 'abletobearweight-left-finger-thumb'
  | 'abletobearweight-right-finger-thumb'
  | 'abletobearweight-left-hand'
  | 'abletobearweight-right-hand'
  | 'abletobearweight-left-foot'
  | 'abletobearweight-right-foot'
  | 'abletobearweight-left-toe-great'
  | 'abletobearweight-right-toe-great'
  | 'abletobearweight-left-toe-second'
  | 'abletobearweight-right-toe-second'
  | 'abletobearweight-left-toe-third'
  | 'abletobearweight-right-toe-third'
  | 'abletobearweight-left-toe-fourth'
  | 'abletobearweight-right-toe-fourth'
  | 'abletobearweight-left-toe-fifth'
  | 'abletobearweight-right-toe-fifth'
  | 'abletobearweight-left-wrist'
  | 'abletobearweight-right-wrist'
  | 'abletobearweight-left-forearm'
  | 'abletobearweight-right-forearm'
  | 'abletobearweight-left-elbow'
  | 'abletobearweight-right-elbow'
  | 'abletobearweight-left-upperarm'
  | 'abletobearweight-right-upperarm'
  | 'abletobearweight-left-shoulder'
  | 'abletobearweight-right-shoulder'
  | 'abletobearweight-left-knee'
  | 'abletobearweight-right-knee'
  | 'abletobearweight-left-lowerleg'
  | 'abletobearweight-right-lowerleg'
  | 'abletobearweight-left-ankle'
  | 'abletobearweight-right-ankle'
  | 'decreasedrom-left-finger-index'
  | 'decreasedrom-right-finger-index'
  | 'decreasedrom-left-finger-middle'
  | 'decreasedrom-right-finger-middle'
  | 'decreasedrom-left-finger-ring'
  | 'decreasedrom-right-finger-ring'
  | 'decreasedrom-left-finger-little'
  | 'decreasedrom-right-finger-little'
  | 'decreasedrom-left-finger-thumb'
  | 'decreasedrom-right-finger-thumb'
  | 'decreasedrom-left-hand'
  | 'decreasedrom-right-hand'
  | 'decreasedrom-left-foot'
  | 'decreasedrom-right-foot'
  | 'decreasedrom-left-toe-great'
  | 'decreasedrom-right-toe-great'
  | 'decreasedrom-left-toe-second'
  | 'decreasedrom-right-toe-second'
  | 'decreasedrom-left-toe-third'
  | 'decreasedrom-right-toe-third'
  | 'decreasedrom-left-toe-fourth'
  | 'decreasedrom-right-toe-fourth'
  | 'decreasedrom-left-toe-fifth'
  | 'decreasedrom-right-toe-fifth'
  | 'decreasedrom-left-wrist'
  | 'decreasedrom-right-wrist'
  | 'decreasedrom-left-forearm'
  | 'decreasedrom-right-forearm'
  | 'decreasedrom-left-elbow'
  | 'decreasedrom-right-elbow'
  | 'decreasedrom-left-upperarm'
  | 'decreasedrom-right-upperarm'
  | 'decreasedrom-left-shoulder'
  | 'decreasedrom-right-shoulder'
  | 'decreasedrom-left-knee'
  | 'decreasedrom-right-knee'
  | 'decreasedrom-left-lowerleg'
  | 'decreasedrom-right-lowerleg'
  | 'decreasedrom-left-ankle'
  | 'decreasedrom-right-ankle'
  // >>> CARD = neurological
  // >> GROUP = normal
  | 'normal-mental-status'
  // >>> CARD = psych
  // >> GROUP = normal
  | 'normal-affect'
  | 'good-eye-contact'
  // >> GROUP = abnormal
  | 'depressed-affect'
  | 'poor-eye-contact';

export const PRIVATE_EXTENSION_BASE_URL = 'https://fhir.zapehr.com/r4/StructureDefinitions';
export const PUBLIC_EXTENSION_BASE_URL = 'https://extensions.fhir.zapehr.com';
export const FHIR_IDENTIFIER_NPI = 'http://hl7.org/fhir/sid/us-npi';

export enum OTTEHR_MODULE {
  UC = 'OTTEHR-UC',
  BH = 'OTTEHR-BH',
  TM = 'OTTEHR-TM',
}

export const TIMEZONE_EXTENSION_URL = 'http://hl7.org/fhir/StructureDefinition/timezone';
