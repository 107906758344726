import { Autocomplete, AutocompleteRenderInputParams, TextField } from '@mui/material';
import { ReactElement, useEffect, useMemo, useState } from 'react';

// import { FhirClient } from '@zapehr/sdk';
import { Practitioner } from 'fhir/r4';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMedplum } from '@medplum/react';
import { MedplumClient } from '@medplum/core';
import { sortLocationsByLabel } from '../helpers';
import { PractitionerRole } from '@medplum/fhirtypes';
// import { useApiClients } from '../hooks/useAppClients';

type CustomFormEventHandler = (event: React.FormEvent<HTMLFormElement>, value: any, field: string) => void;

interface PractitionerSelectProps {
  practitioner?: Practitioner | undefined;
  setPractitioner: (practitioner: any) => void;
  // setPractitioner: Dispatch<SetStateAction<Practitioner | undefined>>
  updateURL?: boolean;
  storeLocationInLocalStorage?: boolean;
  required?: boolean;
  queryParams?: URLSearchParams;
  handleSubmit?: CustomFormEventHandler;
  renderInputProps?: Partial<AutocompleteRenderInputParams>;
}

enum LoadingState {
  initial,
  loading,
  loaded,
}

export default function PractitionerSelect({
  queryParams,
  practitioner,
  handleSubmit,
  setPractitioner,
  updateURL,
  storeLocationInLocalStorage,
  required,
  renderInputProps,
}: PractitionerSelectProps): ReactElement {
  // const { fhirClient } = useApiClients();
  const medplum = useMedplum();
  const [practitioners, setPractitioners] = useState<Practitioner[]>([]);
  const [practitionerRoles, setPractitionerRoles] = useState<PractitionerRole[]>([]);
  const [loadingState, setLoadingState] = useState(LoadingState.initial);
  const navigate = useNavigate();
  const practitionerHandler = useLocation();

  useEffect(() => {
    if (updateURL && localStorage.getItem('selectedPractitioner')) {
      queryParams?.set('practitioner', JSON.parse(localStorage.getItem('selectedPractitioner') ?? '')?.id);
      navigate(`?${queryParams?.toString()}`);
    }
  }, [navigate, queryParams, updateURL]);

  useEffect(() => {
    async function getPractitionerRoleResults(medplum: MedplumClient): Promise<void> {
      if (!medplum) {
        return;
      }

      try {
        const practitionerRolesResults = await medplum.searchResources('PractitionerRole', {
          _count: 100000,
        });
        setPractitionerRoles(practitionerRolesResults);
      } catch (e) {
        console.error('error loading practitioners roles', e);
      }
    }

    async function getPractitionerResults(medplum: MedplumClient): Promise<void> {
      if (!medplum) {
        return;
      }

      setLoadingState(LoadingState.loading);

      try {
        const practitionersResults = await medplum.searchResources('Practitioner', {
          _count: 100000,
          _sort: 'name',
        });
        setPractitioners(practitionersResults);
      } catch (e) {
        console.error('error loading practitioners', e);
      } finally {
        setLoadingState(LoadingState.loaded);
      }
    }

    if (medplum && loadingState === LoadingState.initial) {
      // eslint-disable-next-line no-void
      void getPractitionerRoleResults(medplum);
      // eslint-disable-next-line no-void
      void getPractitionerResults(medplum);
    }
  }, [loadingState, medplum]);

  const options = useMemo(() => {
    const allPractitioner = practitioners.map((practitioner) => {
      const name = `${practitioner?.name?.[0]?.prefix?.[0] ?? ''} ${practitioner?.name?.[0]?.given?.[0] ?? ''} ${practitioner?.name?.[0]?.family ?? ''}`;

      // console.log('practitioner', practitioner);

      const practitionerRole = practitionerRoles.find(
        (practitionerRoleTemp) => practitionerRoleTemp.practitioner?.reference === `Practitioner/${practitioner.id}`
      );

      if (!practitionerRole) {
        return { label: name, value: practitioner.id };
      }

      const practitionerRoleExtension = practitionerRole.extension?.find((ext) => ext.url === 'average-session-time');
      if (practitionerRoleExtension) {
        const value = practitionerRoleExtension?.valueDuration?.value ?? undefined;
        const averageSessionTime = value !== undefined && !isNaN(value) ? Math.round(value) : 0;

        if (averageSessionTime > 0) {
          return {
            label: `${name} - Est. Session ${averageSessionTime} min`,
            value: practitioner.id,
          };
        }
      }

      return { label: name, value: practitioner.id };
    });

    return sortLocationsByLabel(allPractitioner as { label: string; value: string }[]);
  }, [practitioners, practitionerRoles]);

  const handlePractitionerChange = (event: any, newValue: any): void => {
    const selectedPractitioner = newValue
      ? practitioners.find((practitionerTemp) => practitionerTemp.id === newValue.value)
      : undefined;
    console.log('selected practitioner in handle practitioner change', selectedPractitioner);
    if (selectedPractitioner?.id) {
      localStorage.setItem('selectedPractitioner', selectedPractitioner.id);
    } else {
      localStorage.removeItem('selectedPractitioner');
      localStorage.removeItem('selectedPractitionerID');
      const queryParams = new URLSearchParams(practitionerHandler.search);
      queryParams.delete('practitioner');
      setTimeout(() => {
        navigate(`?${queryParams?.toString()}`);
      }, 1000);
    }
    setPractitioner(selectedPractitioner);

    if (storeLocationInLocalStorage) {
      if (newValue) {
        localStorage.setItem('selectedPractitioner', JSON.stringify(selectedPractitioner));
      } else {
        localStorage.removeItem('selectedPractitioner');
      }
    }

    if (handleSubmit) {
      handleSubmit(event, selectedPractitioner, 'practitioner');
    }
  };

  return (
    <Autocomplete
      disabled={renderInputProps?.disabled}
      value={
        practitioner
          ? {
              label: `${practitioner?.name?.[0]?.prefix?.[0] ?? ''} ${practitioner?.name?.[0]?.given?.[0] ?? ''} ${practitioner?.name?.[0]?.family ?? ''}`,
              value: practitioner?.id,
            }
          : null
      }
      onChange={handlePractitionerChange}
      isOptionEqualToValue={(option, tempValue) => option.value === tempValue.value}
      options={options}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.value}>
            {option.label}
          </li>
        );
      }}
      fullWidth
      renderInput={(params) => (
        <TextField placeholder="Search surgeon" name="practitioner" {...params} label="Surgeon" required={required} />
      )}
    />
  );
}
