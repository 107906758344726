import React from 'react';
import { Card, Text, Title, Divider, List, Anchor, Group } from '@mantine/core';

const PrivacyPage: React.FC = () => {
  return (
    <Card shadow="sm" padding="xl" radius="lg" withBorder mt={50} maw={840} style={{ margin: 'auto' }}>
      <Title order={3} ta="center" mb={20}>
        Privacy Policy
      </Title>
      <Text size="md" ta="center" style={{ marginBottom: 20 }}>
        We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy
        Policy outlines how we collect, use, and safeguard the information you provide when using our services.{' '}
      </Text>

      <Divider my="lg" />

      <Title order={2} style={{ marginBottom: 10 }}>
        1. Information We Collect
      </Title>
      <List spacing="sm" withPadding>
        <List.Item
        // icon={
        //   <ThemeIcon color="blue" size="lg" radius="xl">
        //     <IconUser size={18} />
        //   </ThemeIcon>
        // }
        >
          <Text>
            <strong>Personal Information</strong>
          </Text>
          <Text size="sm">
            <strong>Contact Details:</strong> Your name and mobile phone number provided during registration.
            <br />
            <strong>Account Information:</strong> Details necessary for authentication and account access, such as
            one-time passwords (OTPs).
          </Text>
        </List.Item>
        <List.Item
        // icon={
        //   <ThemeIcon color="blue" size="lg" radius="xl">
        //     <IconShieldLock size={18} />
        //   </ThemeIcon>
        // }
        >
          <Text size="sm">
            <strong>Usage Data</strong> – We may collect minimal data on how you interact with our app to improve
            functionality and user experience. This does not include tracking user analytics or behavior beyond
            essential service operations.
          </Text>
        </List.Item>
      </List>

      <Divider my="lg" />

      <Title order={2} style={{ marginBottom: 10 }}>
        2. How We Use Your Information
      </Title>
      <List spacing="sm" withPadding>
        <List.Item>
          <Text size="sm">
            <strong>Service Delivery:</strong> To provide and maintain our services, including sending notifications and
            OTPs.
          </Text>
        </List.Item>
        <List.Item>
          <Text size="sm">
            <strong>Communication:</strong> To contact you with important information about your account or changes to
            our services.
          </Text>
        </List.Item>
        <List.Item>
          <Text size="sm">
            <strong>Compliance:</strong> To comply with legal obligations and protect our rights.
          </Text>
        </List.Item>
      </List>

      <Divider my="lg" />

      <Title order={2} style={{ marginBottom: 10 }}>
        3. Information Sharing and Disclosure
      </Title>
      <List spacing="sm" withPadding>
        <List.Item>
          <Text size="sm">
            <strong>Third-Party Service Providers:</strong> We may share your information with trusted service providers
            solely for the purpose of operating our services (e.g., SMS delivery). These providers are obligated to
            maintain the confidentiality and security of your information.
          </Text>
        </List.Item>
        <List.Item>
          <Text size="sm">
            <strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in
            response to valid requests by public authorities.
          </Text>
        </List.Item>
      </List>

      <Divider my="lg" />

      <Title order={2} style={{ marginBottom: 10 }}>
        4. Data Security
      </Title>
      <Text size="sm">
        We implement reasonable security measures to protect your personal information from unauthorized access,
        alteration, disclosure, or destruction. These measures include encryption, secure servers, and regular security
        assessments.
      </Text>

      <Divider my="lg" />

      <Title order={2} style={{ marginBottom: 10 }}>
        5. Cookies and Tracking Technologies
      </Title>
      <Text size="sm">
        We do not use cookies or similar tracking technologies to collect information about your online activities
        across different websites or services. Our app operates without tracking user analytics beyond what is necessary
        for service functionality.{' '}
      </Text>

      <Divider my="lg" />

      <Title order={2} style={{ marginBottom: 10 }}>
        6. Your Choices and Rights
      </Title>
      <List spacing="sm" withPadding>
        <List.Item>
          <Text size="sm">
            <strong>Opt-Out:</strong> You can opt out of receiving SMS communications at any time by replying STOP to
            any of our messages.
          </Text>
        </List.Item>
        <List.Item>
          <Text size="sm">
            <strong>Access & Correction:</strong> You have the right to access and update your personal information by
            contacting us directly.
          </Text>
        </List.Item>
        <List.Item>
          <Text size="sm">
            <strong>Data Deletion:</strong> You may request the deletion of your personal information, subject to our
            legal obligations.
          </Text>
        </List.Item>
      </List>

      <Divider my="lg" />

      <Title order={2} style={{ marginBottom: 10 }}>
        7. Children's Privacy
      </Title>
      <Text size="sm">
        Our services are not intended for individuals under the age of 13. We do not knowingly collect personal
        information from children under this age. If you become aware that a child has provided us with personal
        information, please contact us, and we will take steps to remove such information.
      </Text>

      <Divider my="lg" />

      <Title order={2} style={{ marginBottom: 10 }}>
        8. Changes to This Privacy Policy
      </Title>
      <Text size="sm">
        We may update this Privacy Policy from time to time. Any changes will be effective when posted, and we will
        notify you of significant changes by email or through our app.
      </Text>

      <Divider my="lg" />

      <Title order={2} style={{ marginBottom: 10 }}>
        9. Contact Us
      </Title>
      <Group align="center">
        <Text size="sm">
          If you have any questions or concerns about this Privacy Policy or our practices, please contact us at:{' '}
          <Anchor href="mailto:dev@yndr.com" color="blue" w={500}>
            dev@yndr.com
          </Anchor>
          . We are dedicated to maintaining your trust and will continually work to protect your personal information.
        </Text>
      </Group>
    </Card>
  );
};

export default PrivacyPage;
