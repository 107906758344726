import { Button, Stack } from '@mantine/core';
import { getReferenceString } from '@medplum/core';
import { Patient } from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IntakeQuestionnaireContext } from '../Questionnaire.context';
import { IconEdit, IconForms } from '@tabler/icons-react';
import { Typography } from '@mui/material';

interface PatientActionsProps {
  patient: Patient;
  onChange: (patient: Patient) => void;
}

export function PatientActions(props: PatientActionsProps): JSX.Element {
  const medplum = useMedplum();
  const navigate = useNavigate();
  const [questionnaireResponse, setQuestionnaireResponse] = useState<any>(null);

  const { setQuestionnaire } = useContext(IntakeQuestionnaireContext);
  // const questionnaireResponse = null;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  // const handleFetchQuestionnaireResponse = async () => {
  //   console.log('questionnaire', questionnaire);
  //   try {
  //     const questionnaireRes = await medplum
  //       .searchOne('QuestionnaireResponse', {
  //         subject: getReferenceString(props.patient),
  //         questionnaire: getReferenceString(questionnaire),
  //       })
  //       .read();

  //     console.log('questionnaireRes', questionnaireRes);

  //     setQuestionnaireResponse(questionnaireRes);
  //   } catch (error) {
  //     console.log('error', await error);
  //   }
  // };

  // useEffect(() => {
  //   if (questionnaire) {
  //     handleFetchQuestionnaireResponse();
  //   }
  //   // eslint-disable-next-line @typescript-eslint/no-floating-promises
  // }, []);

  // let questionnaireResponse: any = null;

  // if (questionnaire) {
  //   questionnaireResponse = await medplum
  //     .searchOne('QuestionnaireResponse', {
  //       subject: getReferenceString(props.patient),
  //       questionnaire: getReferenceString(questionnaire),
  //     })
  //     .read();
  // }

  // const questionnaireResponse = questionnaire
  //   ? medplum
  //       .searchOne('QuestionnaireResponse', {
  //         subject: getReferenceString(props.patient),
  //         questionnaire: getReferenceString(questionnaire),
  //       })
  //       .read()
  //   : null;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const fetchQuestionnaireResponse = async () => {
      if (props.patient.managingOrganization) {
        const questionnaireRes = await medplum.searchOne('Questionnaire', {
          publisher: props.patient.managingOrganization.display,
        });

        console.log('questionnaireRes', questionnaireRes);

        if (questionnaireRes) {
          if (setQuestionnaire) {
            setQuestionnaire(questionnaireRes);
          }
          const response = await medplum.searchOne('QuestionnaireResponse', {
            subject: getReferenceString(props.patient),
            questionnaire: getReferenceString(questionnaireRes),
            _sort: '-_lastUpdated',
          });
          // .read();
          console.log('response', response);
          setQuestionnaireResponse(response);
        }
      }

      // .then((intakeQuestionnaire: SetStateAction<Questionnaire | undefined>) => {
      //   console.log('intakeQuestionnaire', intakeQuestionnaire);
      //   setIntakeQuestionnaire(intakeQuestionnaire);
      // })

      // if (questionnaire) {
      //   console.log('patient', props.patient);
      //   try {
      //     const response = await medplum.searchOne('QuestionnaireResponse', {
      //       subject: getReferenceString(props.patient),
      //       questionnaire: getReferenceString(questionnaire),
      //       _sort: '-_lastUpdated',
      //     });
      //     // .read();

      //     setQuestionnaireResponse(response);
      //   } catch (error) {
      //     console.error('Error fetching questionnaire response:', error);
      //   }
      // }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchQuestionnaireResponse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medplum, props.patient]);

  function handleViewIntakeForm(): void {
    navigate(`/Patient/${props.patient.id}/intake/${questionnaireResponse?.id}`);
  }

  function handleEditIntakeForm(): void {
    navigate(`/Patient/${props.patient.id}/intake/${questionnaireResponse?.id}/edit`);
  }

  function handleCompleteIntakeForm(): void {
    navigate(`/Patient/${props.patient.id}/intake`);
  }

  return (
    <Stack p="xs" m="xs">
      <Typography>Patient Actions</Typography>
      {questionnaireResponse ? (
        <>
          <Button leftSection={<IconEdit size={16} />} onClick={handleViewIntakeForm}>
            View Existing Intake Form
          </Button>
          <Button leftSection={<IconEdit size={16} />} onClick={handleEditIntakeForm}>
            Edit Existing Intake Form
          </Button>
        </>
      ) : (
        <Button leftSection={<IconForms size={16} />} onClick={handleCompleteIntakeForm}>
          Complete Patient Intake
        </Button>
      )}
    </Stack>
  );
}
