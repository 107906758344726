import { SignUpForm } from './components/SignUpForm';
import Welcome from './pages/welcome/Welcome';

export function SignUpPage(): JSX.Element | null {
  return (
    <SignUpForm>
      {/* <Logo size={32} />
      <Title
        style={{
          marginTop: '20px',
        }}
      >
        Please Login or Register
      </Title> */}
      <Welcome />
    </SignUpForm>
  );
}
