export interface LogoProps {
  readonly size: number;
  readonly fill?: string;
}

export function Logo(props: LogoProps): JSX.Element {
  return (
    // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 491 491" style={{ width: props.size, height: props.size }}>
    //   <title>Medplum Logo</title>
    //   <path fill={props.fill ?? '#ad7136'} d="M282 67c6-16 16-29 29-40L289 0c-22 17-37 41-43 68l17 23 19-24z" />
    //   <path
    //     fill={props.fill ?? '#946af9'}
    //     d="M311 63c-17 0-33 4-48 11-16-7-32-11-49-11-87 0-158 96-158 214s71 214 158 214c17 0 33-4 49-11 15 7 31 11 48 11 87 0 158-96 158-214S398 63 311 63z"
    //   />
    //   <path
    //     fill={props.fill ?? '#7857c5'}
    //     d="M231 489l-17 2c-87 0-158-96-158-214S127 63 214 63l17 1c-39 12-70 102-70 213s31 201 70 212z"
    //   />
    //   <path
    //     fill={props.fill ?? '#40bc26'}
    //     d="M207 220a176 176 0 01-177 43A176 176 0 01251 43l1 5c17 59 2 125-45 172z"
    //   />
    //   <path fill={props.fill ?? '#33961e'} d="M252 48A421 421 0 0057 270l-27-7A176 176 0 01251 43l1 5z" />
    // </svg>

    <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 131 132" width={props.size} height={props.size}>
      <style>
        {`
          .a{fill:none}
          .b{fill:#006db1}
          .c{fill:#157cbf}
          .d{fill:#c8dbe9}
          .e{fill:#b6ddf5}
          .f{fill:#8ec3ea}
          .g{fill:#0c72ba}
          .h{fill:#90c4e1}
          .i{fill:#60b5e6}
          .j{fill:#49a6d5}
          .k{fill:none;stroke:#49a6d5;stroke-linecap:round;stroke-linejoin:round;stroke-width:.4}
        `}
      </style>
      <path fill-rule="evenodd" className="a" d="m68.5 48.8q0-0.1 0-0.3 0.3 0 0.7-0.1-0.4 0.1-0.7 0.1 0 0.2 0 0.3z" />
      <path
        fill-rule="evenodd"
        className="a"
        d="m56.3 41.7q-0.1 1-0.2 2.1-0.3 2-0.6 3.9 0.3-1.9 0.6-3.9 0.1-1.1 0.2-2.1z"
      />
      <path
        fill-rule="evenodd"
        className="a"
        d="m23.1 57.4c-4.9-1-9.2-3.1-13-6.1-0.4-0.3-0.9-0.5-1.3-0.7q-0.3-0.4-0.7-0.8 0.4 0.4 0.7 0.9c0.4 0.1 0.9 0.3 1.3 0.6 3.8 3 8.1 5.1 13 6.1z"
      />
      <path
        fill-rule="evenodd"
        className="a"
        d="m54.6 52.5q0 0 0 0-0.1 0.2-0.2 0.3-2.3 0.5-4.6 1.1 2.3-0.6 4.6-1.1 0.1-0.1 0.2-0.3z"
      />
      <path fill-rule="evenodd" className="a" d="m57.6 26.8q0.2 1 0.1 2.1 0.1-1.1-0.1-2.1z" />
      <path
        fill-rule="evenodd"
        className="a"
        d="m49.9 9.6q0.4 0.4 0.7 0.8c2.1 2.4 3.7 5.1 5 7.9-1.3-2.8-2.9-5.5-5-7.9q-0.3-0.4-0.7-0.8z"
      />
      <path
        fill-rule="evenodd"
        className="a"
        d="m58.3 24c-0.5-1.1-1.1-2.1-1.7-3.2q-0.1-0.2-0.1-0.3 0 0.1 0.1 0.3 0.1 0.9 0.3 1.9-0.2-1-0.3-1.9c0.6 1.1 1.2 2.1 1.7 3.2z"
      />
      <path
        fill-rule="evenodd"
        className="a"
        d="m48.4 8.2q-0.6-0.6-1.2-1.3 0.9 0.5 1.9 1-1-0.5-1.9-1c-2.4-1.7-4.9-3.2-7.6-4.3 2.7 1.1 5.2 2.6 7.6 4.3q0.6 0.7 1.2 1.3z"
      />
      <path
        className="b"
        d="m8.1 49.8q0.4 0.4 0.7 0.8c0.4 0.2 0.9 0.4 1.3 0.7 3.8 3 8.1 5.1 13 6.1q0.7 0.1 1.4 0.2c4.9 0.7 9.6-0.2 14.3-1.2q5.5-1.2 11-2.5 2.3-0.6 4.6-1.1 0.1-0.1 0.2-0.3 0.4-2.4 0.9-4.8 0.3-1.9 0.6-3.9 0.1-1.1 0.2-2.1c0.6-4.2 1.2-8.5 1.4-12.7q0-0.1 0-0.1 0.1-1.1-0.1-2.1c-0.1-1.4-0.4-2.7-0.7-4.1q-0.2-1-0.3-1.9-0.1-0.2-0.1-0.3 0-0.1 0-0.2-0.1-0.2-0.2-0.3-0.3-0.9-0.7-1.7c-1.3-2.8-2.9-5.5-5-7.9q-0.3-0.4-0.7-0.8-0.7-0.7-1.5-1.4-0.6-0.6-1.2-1.3c-2.4-1.7-4.9-3.2-7.6-4.3q-1.8-0.7-3.6-1.2-2-0.5-4.2-0.7c-0.1 0-0.2-0.1-0.3-0.2q-6.7-0.4-13 1.8-0.3 0.1-0.7 0.2-0.3 0.2-0.6 0.3-1.4 0.7-2.8 1.4-0.1 0.2-0.2 0.1-0.6 0.4-1.1 0.8-0.1 0.1-0.2 0.1-0.4 0.2-0.8 0.5l-0.1-0.1 0.1 0.1q-0.6 0.4-1.1 0.8v-0.1 0.1q-0.7 0.6-1.3 1.1-0.3 0.2-0.5 0.5-0.8 0.8-1.7 1.6-0.1 0.1-0.2 0.2c-0.8 1.3-1.7 2.6-2.6 3.9-3.3 5.2-4.8 11.1-4.5 17.3 0 0.6 0 1.1 0 1.7q0.2 2.4 0.4 4.8c0.2 0.2 0.5 0.5 0.6 0.7q0.6 1.8 1.4 3.4c1.3 2.7 3.1 5.1 5 7.4q0.3 0.3 0.5 0.7z"
      />
      <path
        className="c"
        d="m44.1 92.7c-0.7-0.5-1.4-1.1-2-1.7-2.2-2.4-4.4-4.8-6.5-7.3-2.8-3.2-5.6-6.4-8.3-9.6-3.8-4.5-7.5-9-11.1-13.6-2.6-3.2-5-6.6-7.4-9.8q-0.3-0.5-0.7-0.9-0.2-0.4-0.5-0.7c-1.9-2.3-3.7-4.7-5-7.4q-0.8-1.6-1.4-3.4c-0.1-0.2-0.4-0.5-0.6-0.7 0 0.5-0.1 1.1 0 1.6 0.6 6.9 1.1 13.9 1.8 20.8 1.3 13.4 3.3 26.6 6.1 39.8 1.7 7.9 3.6 15.7 7 23.1 1.1 2.4 2.6 4.8 4.3 6.8 2.4 2.9 5.3 3 8.1 0.6 1.5-1.3 2.9-3 4.1-4.8 3.1-4.6 5.3-9.7 7.1-14.9 2-5.6 3.8-11.2 5.7-16.8q0-0.2 0-0.5-0.3-0.3-0.7-0.6z"
      />
      <path
        className="d"
        d="m128.6 13.9c-2.7-2.8-6.2-4.1-9.8-5.3-6.9-2.2-14-3.7-21.2-4.6-6.7-0.8-13.4-1.7-20.1-2.3-5-0.5-10.1-0.8-15.2-1.1q-7.8-0.4-15.6-0.6c-4.4 0-8.7 0-13.1 0.1-0.7 0-1.4 0.3-2.1 0.4 0.1 0.1 0.2 0.2 0.3 0.2q2.2 0.2 4.2 0.7 1.8 0.5 3.6 1.2c2.7 1.1 5.2 2.6 7.6 4.3q0.9 0.5 1.9 1 0.9 0.5 1.8 1c10.8 6.9 21.2 14.4 31.4 22.2 3.6 2.7 7 5.6 10.6 8.4 0.2 0.2 0.4 0.5 0.7 0.8q0.2 0.1 0.5 0.2c0.2-0.2 0.4-0.4 0.7-0.5 3.5-1.4 7.1-2.8 10.6-4.3 6.3-2.6 12.5-5.5 18.2-9.5 2.2-1.6 4.3-3.2 5.7-5.6 1.5-2.4 1.3-4.7-0.7-6.7z"
      />
      <path
        className="e"
        d="m89 42.1c-6.4 2.1-12.8 4.1-19.2 6.2q-0.3 0.1-0.6 0.1-0.4 0.1-0.7 0.1 0 0.2 0 0.3 4.2 12.2 8.5 24.5c0 0.3 0 0.5 0.1 0.8q0 0 0 0 0.1 0.1 0.2 0.3c7.2 1.3 14.3 2.7 21.5 3.8 4.7 0.7 9.4 1 14 0.4 1.7-0.2 3.3-0.7 4.9-1.3 2.1-0.8 3.5-2.4 3.2-4.8-0.1-1.6-0.6-3.2-1.2-4.6-1.8-3.9-4.6-7.2-7.5-10.4-5.7-6.1-11.7-11.7-18.1-17q-0.3-0.1-0.5-0.2c-1.5 0.6-3 1.3-4.6 1.8z"
      />
      <path
        className="f"
        d="m77.1 74.1q0 0 0 0c-2-0.4-3.9-0.8-5.8-1.2q-1-0.3-1.9-0.5c-6-1.6-12-3.3-17.9-4.9q-3.2 12.6-6.5 25.2c0 0.2-0.1 0.4-0.2 0.6q0 0.3 0 0.5c0.3 0.3 0.7 0.6 1 0.9 6.5 6.7 13.1 13.3 20.8 18.7 3 2.1 6 4 9.6 4.8 3.6 0.8 6-0.5 7-4.1 0.4-1.4 0.7-2.9 0.7-4.4 0.3-5.5-0.5-10.9-1.7-16.2-1.5-6.4-3.2-12.7-4.9-19.1q-0.1-0.2-0.2-0.3 0 0 0 0z"
      />
      <path
        className="g"
        d="m54.4 52.8q-2.3 0.5-4.6 1.1-5.5 1.3-11 2.5c-4.7 1-9.4 1.9-14.3 1.2q-0.7-0.1-1.4-0.2c-4.9-1-9.2-3.1-13-6.1-0.4-0.3-0.9-0.5-1.3-0.6 2.4 3.2 4.8 6.6 7.4 9.8 3.6 4.6 7.3 9.1 11.1 13.6 2.7 3.2 5.5 6.4 8.3 9.6 2.1 2.5 4.3 4.9 6.5 7.3 0.6 0.6 1.3 1.2 2 1.7q0.4 0.3 0.7 0.6c0.1-0.2 0.2-0.4 0.2-0.6q3.3-12.6 6.5-25.2 1.4-6.7 2.7-13.4c0.1-0.4 0.1-0.9 0.2-1.3z"
      />
      <path
        className="h"
        d="m48.4 8.2q0.8 0.7 1.5 1.4 0.4 0.4 0.7 0.8c2.1 2.4 3.7 5.1 5 7.9q0.4 0.8 0.7 1.7 0.1 0.1 0.2 0.3 0 0.1 0 0.2 0 0.1 0.1 0.3c0.6 1.1 1.2 2.1 1.7 3.2q0.3 0.6 0.6 1.2c3.1 7.5 6.2 15.1 9.2 22.6q0.2 0.4 0.4 0.7 0.3 0 0.7-0.1 0.3 0 0.6-0.1c6.4-2.1 12.8-4.1 19.2-6.2 1.6-0.5 3.1-1.2 4.6-1.8-0.3-0.3-0.5-0.6-0.7-0.8-3.6-2.8-7-5.7-10.6-8.4-10.2-7.8-20.6-15.3-31.4-22.2q-0.9-0.5-1.8-1-1-0.5-1.9-1 0.6 0.7 1.2 1.3z"
      />
      <path
        className="i"
        d="m54.6 52.5q-0.1 0.2-0.2 0.3c-0.1 0.4-0.1 0.9-0.2 1.3q-1.3 6.7-2.7 13.4c5.9 1.6 11.9 3.3 17.9 4.9q0.9 0.2 1.9 0.5c1.9 0.4 3.8 0.8 5.8 1.2q0 0 0 0c-0.1-0.3-0.1-0.5-0.1-0.8q-4.3-12.3-8.5-24.5-5.8 1.6-11.6 3.2c-0.7 0.2-1.5 0.3-2.3 0.5z"
      />
      <path
        className="j"
        d="m68.1 47.8c-3-7.5-6.1-15.1-9.2-22.6q-0.3-0.6-0.6-1.2c-0.5-1.1-1.1-2.1-1.7-3.2q0.1 0.9 0.3 1.9c0.3 1.4 0.6 2.7 0.7 4.1q0.2 1 0.1 2.1 0 0 0 0.1c-0.2 4.2-0.8 8.5-1.4 12.7q-0.1 1-0.2 2.1-0.3 2-0.6 3.9-0.5 2.4-0.9 4.8 0 0 0 0c0.8-0.2 1.6-0.3 2.3-0.5q5.8-1.6 11.6-3.2 0-0.1 0-0.3-0.2-0.3-0.4-0.7z"
      />
      <path fill-rule="evenodd" className="k" d="m56.3 20q0.1 0.1 0.2 0.3" />
    </svg>
  );
}
