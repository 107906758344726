import { Grid, Group, AppShell as MantineAppShell, Menu, UnstyledButton } from '@mantine/core';
// import { formatHumanName } from '@medplum/core';
// import { HumanName } from '@medplum/fhirtypes';
import { useMedplumProfile } from '@medplum/react-hooks';
// import { IconChevronDown } from '@tabler/icons-react';
import cx from 'clsx';
import { ReactNode, useState } from 'react';
import { ResourceAvatar } from '../ResourceAvatar/ResourceAvatar';
import classes from './Header.module.css';
import { HeaderDropdown } from './HeaderDropdown';
import { Link, useLocation } from 'react-router-dom';
// import { HeaderSearchInput } from './HeaderSearchInput';

export interface HeaderProps {
  readonly pathname?: string;
  readonly searchParams?: URLSearchParams;
  readonly headerSearchDisabled?: boolean;
  readonly logo: ReactNode;
  readonly version?: string;
  readonly navbarToggle: () => void;
  readonly notifications?: ReactNode;
}

const links = [
  { link: '/home', label: 'Calendar' },
  { link: '/Patient', label: 'Patients' },
  { link: '/Practitioner', label: 'Surgeons' },
  { link: '/Location', label: 'Locations' },
];

export function Header(props: HeaderProps): JSX.Element {
  const profile = useMedplumProfile();
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  // const [active, setActive] = useState(links[0].link);
  const { pathname } = useLocation();

  const items = links.map((link) => (
    <Link key={link.label} to={link.link} className={classes.link} data-active={link.link === pathname || undefined}>
      {link.label}
    </Link>
  ));

  return (
    <MantineAppShell.Header pt={8} style={{ zIndex: 101 }}>
      {/* <Group justify="space-between"> */}
      <Grid justify="space-between" align="center" p={0}>
        <Grid.Col span={2}>
          <Group>
            <UnstyledButton className={classes.logoButton} onClick={props.navbarToggle}>
              {props.logo}
            </UnstyledButton>
            {/* {!props.headerSearchDisabled && (
            <HeaderSearchInput pathname={props.pathname} searchParams={props.searchParams} />
          )} */}
          </Group>
        </Grid.Col>
        <Grid.Col span={9}>
          <Group gap="sm" justify="flex-start">
            <Group>{items}</Group>
          </Group>
        </Grid.Col>
        <Grid.Col span={1}>
          <Group gap="lg" pr="sm" justify="flex-end">
            {props.notifications}
            <Menu
              width={260}
              shadow="xl"
              position="bottom-end"
              transitionProps={{ transition: 'pop-top-right' }}
              opened={userMenuOpened}
              onClose={() => setUserMenuOpened(false)}
            >
              <Menu.Target>
                <UnstyledButton
                  className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
                  onClick={() => setUserMenuOpened((o) => !o)}
                >
                  <Group gap={7}>
                    <ResourceAvatar value={profile} radius="xl" size={36} />
                    {/* <Text size="sm" className={classes.userName}>
                    {formatHumanName(profile?.name?.[0] as HumanName)}
                  </Text> */}
                    {/* <IconChevronDown size={16} stroke={1.5} /> */}
                  </Group>
                </UnstyledButton>
              </Menu.Target>
              <Menu.Dropdown>
                <HeaderDropdown version={props.version} />
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Grid.Col>
      </Grid>
      {/* </Group> */}
    </MantineAppShell.Header>
  );
}
