import { Avatar, AvatarGroup, Button, Text } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import dayjs from 'dayjs';

interface HeaderProps {
  selectedDate: Date;
  view: 'day' | 'week' | 'month';
}

const Header: React.FC<HeaderProps> = ({ selectedDate, view }): JSX.Element => {
  const start = dayjs(selectedDate).startOf('week').format('MMM DD, YYYY');
  const end = dayjs(selectedDate).endOf('week').format('MMM DD, YYYY');

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 10px',
      }}
    >
      {/* Title Section */}
      <div>
        <Text style={{ margin: 0, fontSize: '16px', fontWeight: 600 }}>
          {view === 'day' && `Schedules for ${dayjs(selectedDate).format('MMM DD, YYYY')}`}
          {view === 'week' && `Schedules for ${start} - ${end}`}
          {view === 'month' && `Schedules for ${dayjs(selectedDate).format('MMMM')}`}
        </Text>
        <p style={{ margin: 0, fontSize: '14px', color: '#666' }}>Create and complete tasks using boards</p>
      </div>

      {/* Avatars and Invite Button */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* Avatar Group */}
        <AvatarGroup spacing="sm">
          <Avatar src="https://randomuser.me/api/portraits/women/2.jpg" alt="User 1" radius="xl" size="md" />
          <Avatar src="https://randomuser.me/api/portraits/men/3.jpg" alt="User 2" radius="xl" size="md" />
          <Avatar
            radius="xl"
            size="md"
            style={{ backgroundColor: '#fff', color: '#000', fontSize: '14px', fontWeight: 600 }}
          >
            5
          </Avatar>
        </AvatarGroup>

        {/* Invite Button */}
        <Button
          variant="filled"
          color="dark"
          style={{
            marginLeft: '10px',
            backgroundColor: '#002238',
            padding: '2px 15px',
            fontSize: '14px',
            fontWeight: 600,
          }}
          leftSection={<IconPlus size={16} color="#fff" />}
        >
          Invite
        </Button>
      </div>
    </div>
  );
};

export { Header };
