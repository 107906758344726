// import { Button } from '@mui/material';
// import { Box } from '@mui/system';
import { DateTime } from 'luxon';
import { useState } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import { IntakeFlowPageRoute } from '../App';
// import { clockFullColor } from '@theme/icons';
// import { useAppointmentStore, useGetSchedule } from '../features/appointments';
// import { CustomContainer } from './features/common';
// import { useZapEHRAPIClient } from '../utils';
import Schedule from './components/Schedule';
import { ErrorDialog } from './components/ErrorDialog';
// import { clockFullColor } from '../../assets';

// interface Parameters {
//   'schedule-type': 'location' | 'provider';
//   slug: string;
//   'visit-type': 'prebook' | 'now';
//   'visit-service': 'in-person' | 'telemedicine';
// }

interface AppointmentState {
  appointmentID?: string;
  appointmentDate?: string;
  visitType?: 'prebook' | 'now';
  visitService?: 'in-person' | 'telemedicine';
  selectedSlot?: Date | string;
  locationID?: string;
  providerID?: string;
  groupID?: string;
  scheduleType?: 'location' | 'provider';
  timezone: string;
}

const ScheduleComponent = (props: {
  appointmentState: AppointmentState;
  setAppointment: any;
  hoursOfOperation: any;
}): JSX.Element => {
  // const navigate = useNavigate();
  // const apiClient = useZapEHRAPIClient({ tokenless: true });
  // const parameters = useParams<keyof Parameters>() as Parameters;
  // const { 'schedule-type': scheduleType, slug, 'visit-type': visitType, 'visit-service': visitService } = parameters;
  const [choiceErrorDialogOpen, setChoiceErrorDialogOpen] = useState(false);
  // const { selectedSlot, setAppointment } = useAppointmentStore((state) => state);
  const { t } = useTranslation();
  const { appointmentState, setAppointment } = props;
  // if (!slug) {
  //   throw new Error('slug is not defined');
  // }
  // if (!scheduleType) {
  //   throw new Error('schedule-type is not defined');
  // }

  // const { data: schedule, isFetching, isError } = useGetSchedule(apiClient, scheduleType, slug, Boolean(apiClient));

  // useEffect(() => {
  //   setAppointment({ scheduleType, visitType, visitService });
  //   if (visitType === 'now') {
  //     setAppointment({ selectedSlot: DateTime.now().toISO() });
  //   }
  // }, [visitService, setAppointment, visitType, scheduleType]);

  // const generateTimeSlots = (startHour: number, endHour: number, intervalMinutes: number): any => {
  //   const timeSlots = [];
  //   const now = DateTime.now().setZone('America/New_York').set({ second: 0, millisecond: 0 }); // Get the current date and reset seconds/milliseconds

  //   // Loop through the hours
  //   for (let hour = startHour; hour <= endHour; hour++) {
  //     for (let minute = 0; minute < 60; minute += intervalMinutes) {
  //       const slot = now.set({ hour, minute }); // Set the hour and minute for each slot
  //       timeSlots.push(slot.toISO()); // Convert to ISO string and store
  //     }
  //   }

  //   return timeSlots;
  // };

  // const startHour = 9; // Starting hour for the slots
  // const endHour = 16; // Ending hour for the slots
  // const intervalMinutes = 60; // Interval of 15 minutes

  // const todayTimeSlots = generateTimeSlots(startHour, endHour, intervalMinutes);
  // function generateTimeSlotsForDay(
  //   startHour: any,
  //   endHour: number,
  //   intervalMinutes: number,
  //   date: DateTime<true> | DateTime<false>
  // ): any {
  //   const timeSlots = [];

  //   // Loop through the hours and minutes to generate slots
  //   for (let hour = startHour; hour <= endHour; hour++) {
  //     for (let minute = 0; minute < 60; minute += intervalMinutes) {
  //       const slot = date.set({ hour, minute }); // Set the hour and minute for each slot
  //       timeSlots.push(slot.toISO()); // Convert to ISO string and store
  //     }
  //   }

  //   return timeSlots.filter((slot) => slot !== null);
  // }
  // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // function generateTimeSlots(startHour: number, endHour: number, intervalMinutes: number): any {
  //   const now = DateTime.now().setZone('America/New_York').set({ second: 0, millisecond: 0 }); // Get current date in New York timezone
  //   const todaySlots = generateTimeSlotsForDay(startHour.toString(), endHour, intervalMinutes, now); // Slots for today
  //   const tomorrow = now.plus({ days: 1 }).startOf('day'); // Get tomorrow's date starting at midnight
  //   const tomorrowSlots = generateTimeSlotsForDay(startHour, endHour, intervalMinutes, tomorrow); // Slots for tomorrow

  //   return [...todaySlots, ...tomorrowSlots]; // Combine slots for today and tomorrow
  // }

  // const startHour = 9; // Starting hour for the slots
  // const endHour = 16; // Ending hour for the slots
  // const intervalMinutes = 60; // Interval of 15 minutes

  // const slotsForTodayAndTomorrow = generateTimeSlots(startHour, endHour, intervalMinutes);

  // const schedule = {
  //   availableSlots: slotsForTodayAndTomorrow,
  //   name: 'name',
  //   available: true,
  //   locationID: '2869de6c-112c-4448-b67b-e683b692777b',
  //   providerID: '',
  // };

  // useEffect(() => {
  //   setAppointment({ locationID: schedule?.locationID, providerID: schedule?.providerID, groupID: schedule?.groupID });
  // }, [schedule?.groupID, schedule?.locationID, schedule?.providerID, setAppointment]);

  // const onSubmit = (): void => {
  //   if (!appointmentState.selectedSlot) {
  //     setChoiceErrorDialogOpen(true);
  //   } else {
  //     // navigate(IntakeFlowPageRoute.AuthPage.path);
  //   }
  // };

  // console.log('appointmentState', appointmentState.selectedSlot);

  if (!props.hoursOfOperation || props.hoursOfOperation.length === 0) {
    return <></>;
  }

  // Example hours of operation
  // const hoursOfOperation = [
  //   {
  //     daysOfWeek: ['mon', 'tue', 'wed', 'thu', 'fri'],
  //     availableStartTime: '08:00:00',
  //     availableEndTime: '04:00:00',
  //   },
  // ];

  // // Mapping of day names to Luxon weekday numbers
  // const daysMap: { [key: string]: number } = {
  //   mon: 1,
  //   tue: 2,
  //   wed: 3,
  //   thu: 4,
  //   fri: 5,
  //   sat: 6,
  //   sun: 7,
  // };

  // // Generate time slots for a specific day with overnight handling
  // function generateTimeSlotsForDay(
  //   startTime: string,
  //   endTime: string,
  //   intervalMinutes: number,
  //   date: DateTime
  // ): string[] {
  //   const timeSlots = [];

  //   const parseTime = (time: string): { hour: number; minute: number } => {
  //     const [hour, minute] = time.split(':').map(Number);
  //     return { hour, minute };
  //   };

  //   let start = date.set({ ...parseTime(startTime), second: 0, millisecond: 0 });
  //   let end = date.set({ ...parseTime(endTime), second: 0, millisecond: 0 });

  //   console.log("start", start)
  //   console.log("end", end)

  //   // If end time is earlier than start time, it means overnight availability
  //   // if (end <= start) {
  //   //   end = end.plus({ days: 1 });
  //   // }

  //   while (start < end) {
  //     timeSlots.push(start.toISO());
  //     start = start.plus({ minutes: intervalMinutes });
  //   }

  //   console.log("timeSlots", timeSlots)

  //   return timeSlots.filter((slot) => slot !== null);
  // }

  // function generateTodayAndTomorrowSlots(
  //   availableDays: string[],
  //   startTime: string,
  //   endTime: string,
  //   intervalMinutes: number
  // ): string[] {
  //   const allSlots = [];
  //   const now = DateTime.now().setZone(timezone);

  //   // Generate slots for today if it's an available day
  //   if (availableDays.includes(now.toFormat('ccc').toLowerCase())) {
  //     allSlots.push(...generateTimeSlotsForDay(startTime, endTime, intervalMinutes, now));
  //   }

  //   // Generate slots for tomorrow if it's an available day
  //   const tomorrow = now.plus({ days: 1 });
  //   if (availableDays.includes(tomorrow.toFormat('ccc').toLowerCase())) {
  //     allSlots.push(...generateTimeSlotsForDay(startTime, endTime, intervalMinutes, tomorrow));
  //   }

  //   console.log('allSlots', allSlots)

  //   return allSlots;
  // }

  // // Generate slots for today and tomorrow based on hours of operation
  // const slots = generateTodayAndTomorrowSlots(
  //   hoursOfOperation[0].daysOfWeek,
  //   hoursOfOperation[0].availableStartTime,
  //   hoursOfOperation[0].availableEndTime,
  //   60 // Interval of 60 minutes
  // );

  // console.log(slots);

  // Input array
  // const hoursOfOperation = [
  //   { openingTime: '11:00:00', closingTime: '21:00:00', daysOfWeek: ['mon'] },
  //   { openingTime: '11:00:00', closingTime: '21:00:00', daysOfWeek: ['tue'] },
  //   { openingTime: '11:00:00', closingTime: '21:00:00', daysOfWeek: ['wed'] },
  //   { openingTime: '11:00:00', closingTime: '21:00:00', daysOfWeek: ['thu'] },
  //   { openingTime: '11:00:00', closingTime: '21:00:00', daysOfWeek: ['fri'] },
  //   { openingTime: '11:00:00', closingTime: '21:00:00', daysOfWeek: ['sat'] },
  //   { openingTime: '11:00:00', closingTime: '21:00:00', daysOfWeek: ['sun'] },
  // ];

  // Generate function for today and tomorrow
  // function generateTodayAndTomorrowSlots(): string[] {
  //   // const timeZone = 'America/Los_Angeles'; // Adjust to your target time zone
  //   const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  //   const intervalMinutes = 60;
  //   const slots: string[] = [];

  //   // Get today and tomorrow dates
  //   const today = DateTime.now().setZone(timezone);
  //   const tomorrow = today.plus({ days: 1 });

  //   // Function to create slots for a given day
  //   function createSlotsForDay(date: DateTime<true> | DateTime<false>): void {
  //     const weekday = date.toFormat('ccc').toLowerCase(); // Get weekday as 'mon', 'tue', etc.

  //     props.hoursOfOperation.forEach(({ availableStartTime, availableEndTime, daysOfWeek }) => {
  //       if (daysOfWeek.includes(weekday)) {
  //         let start = date.set({
  //           hour: parseInt(availableStartTime.split(':')[0], 10),
  //           minute: parseInt(availableStartTime.split(':')[1], 10),
  //         });
  //         const end = date.set({
  //           hour: parseInt(availableEndTime.split(':')[0], 10),
  //           minute: parseInt(availableEndTime.split(':')[1], 10),
  //         });

  //         while (start < end) {
  //           slots.push(start.toISO());
  //           start = start.plus({ minutes: intervalMinutes });
  //         }
  //       }
  //     });
  //   }

  //   // Create slots for today and tomorrow
  //   createSlotsForDay(today);
  //   createSlotsForDay(tomorrow);

  //   return slots;
  // }

  // const slots: string[] = generateTodayAndTomorrowSlots();
  // console.log('slots', slots);

  // Get today's and tomorrow's days of the week in abbreviated form
  const today = DateTime.now();
  const tomorrow = today.plus({ days: 1 });
  const days = [
    { day: today, label: today.toFormat('ccc').toLowerCase() },
    { day: tomorrow, label: tomorrow.toFormat('ccc').toLowerCase() },
  ];

  // console.log('days', days);

  // Function to generate time slots within operating hours
  function generateDateTimeStrings(day: DateTime<true>, openingTime: string, closingTime: string): string[] {
    // console.log('openingTime', openingTime)
    // console.log('closingTime', closingTime)
    // const open = day.set({ hour: parseInt(openingTime.split(':')[0], 10), minute: 0 });
    // const close = day.set({ hour: parseInt(closingTime.split(':')[0], 10), minute: 0 });
    // const interval = Interval.fromDateTimes(open, close);
    // console.log('open', open)
    // console.log('close', close)
    // const slots = [];
    // for (let slot = open; slot < close; slot = slot.plus({ hours: 1 })) {
    //   console.log('slot1', slot)
    //   slots.push(slot.toISO()); // Generate ISO string for each time slot
    // }

    // console.log('slots', slots)
    // return slots;

    const open = day.set({
      hour: parseInt(openingTime.split(':')[0], 10),
      minute: parseInt(openingTime.split(':')[1], 10),
      second: 0,
      millisecond: 0,
    });
    const close = day.set({
      hour: parseInt(closingTime.split(':')[0], 10),
      minute: parseInt(closingTime.split(':')[1], 10),
      second: 0,
      millisecond: 0,
    });

    // Debugging: log open and close times to verify
    // console.log('Open time:', open.toISO());
    // console.log('Close time:', close.toISO());

    const slots = [];
    if (open < close) {
      for (let slot = open; slot < close; slot = slot.plus({ hours: 1 })) {
        slots.push(slot.toISO()); // Generate ISO string for each time slot
      }
    } else {
      console.log('Warning: Closing time is not after opening time.');
    }
    return slots;
  }

  // Generate date-time strings
  const dateTimeList: any[] = [];

  days.forEach(({ day, label }) => {
    props.hoursOfOperation.forEach((op: { daysOfWeek: string | string[]; openingTime: any; closingTime: any }) => {
      if (op.daysOfWeek.includes(label)) {
        dateTimeList.push(...generateDateTimeStrings(day, op.openingTime, op.closingTime));
      }
    });
  });

  // console.log('dateTimeList', dateTimeList);

  return (
    <>
      {/* // <CustomContainer
    //   title={`${t('welcome.title', { visitService })}`}
    //   subtitle={isFetching ? t('general.loading') : schedule?.name}
    //   img={t('welcome.imgAlt') ? clockFullColor : undefined}
    //   imgAlt={t('welcome.imgAlt')}
    //   imgWidth={120}
    //   // bgVariant={IntakeFlowPageRoute.NewUser.path}
    //   isFirstPage={true}
    // > */}
      {/* {!isFetching && schedule && schedule.available && ['in-person', 'telemedicine'].includes(visitService || '') && ( */}
      <>
        {/* <Typography variant="body1">{t('welcome.message')}</Typography>
        <div dangerouslySetInnerHTML={{ __html: t('welcome.html') }} /> */}

        <Schedule
          // slotData={schedule.availableSlots}
          slotData={dateTimeList}
          timezone={appointmentState.timezone}
          setAppointment={setAppointment}
          appointmentState={appointmentState}
        />
        {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            className="next-button"
            type="submit"
            sx={{
              mt: 2,
            }}
            onClick={onSubmit}
          >
            {t('general.button.continue')}
          </Button>
        </Box> */}
      </>
      {/* )} */}
      <ErrorDialog
        open={choiceErrorDialogOpen}
        title={t('welcome.dateError.title')}
        description={t('welcome.dateError.description')}
        closeButtonText={t('general.button.close')}
        handleClose={() => setChoiceErrorDialogOpen(false)}
      />
      {/* // </CustomContainer> */}
    </>
  );
};

export default ScheduleComponent;
