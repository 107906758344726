import { Button, Group, Stack, TextInput, Select, Anchor, Checkbox, Text } from '@mantine/core';
import {
  isValidDate,
  LoginAuthenticationResponse,
  normalizeErrorString,
  normalizeOperationOutcome,
} from '@medplum/core';
import { OperationOutcome } from '@medplum/fhirtypes';
import { getErrorsForInput, getIssuesForExpression, OperationOutcomeAlert, Form } from '@medplum/react';
import { useMedplum } from '@medplum/react-hooks';
import { FormControl, Grid, InputLabel, TextareaAutosize, TextField } from '@mui/material';
import { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { PatternFormat } from 'react-number-format';
import ScheduleComponent from './schedulecomponent';
import { notifications, showNotification } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';
// import { OperationOutcomeAlert } from '../OperationOutcomeAlert/OperationOutcomeAlert';
// import { getErrorsForInput, getIssuesForExpression } from '../utils/outcomes';

export interface NewUserFormProps {
  readonly children?: ReactNode;
  readonly handleAuthResponse: (response: LoginAuthenticationResponse) => void;
  organization?: string;
  locations?: any[];
  providers?: any[];
  setProviders?: Dispatch<SetStateAction<never[]>>;
  setChoiceErrorDialogOpen: any;
}

interface AppointmentState {
  appointmentID?: string;
  appointmentDate?: string;
  visitType?: 'prebook' | 'now';
  visitService?: 'in-person' | 'telemedicine';
  selectedSlot?: Date;
  locationID?: string;
  providerID?: string;
  groupID?: string;
  scheduleType?: 'location' | 'provider';
  timezone: string;
}

export function UserForm(props: NewUserFormProps): JSX.Element {
  const medplum = useMedplum();
  const [outcome, setOutcome] = useState<OperationOutcome>();
  const issues = getIssuesForExpression(outcome, undefined);
  const navigate = useNavigate();

  const { locations = [], providers = [] } = props;
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [location, setLocation] = useState('');
  const [provider, setProvider] = useState<string | null>(null);
  const [hoursOfOperation, setHoursOfOperation] = useState<any>([]);
  const [reason, setReason] = useState('');
  const [isAgreed, setIsAgreed] = useState(false);
  const [mobilePhone, setMobilePhone] = useState<string>('');
  const [errors, setErrors] = useState<{ submit?: boolean; phone?: boolean; search?: boolean }>({
    submit: false,
    phone: false,
    search: false,
  });

  const phoneNumberErrorMessage = 'Phone number must be 10 digits';

  const days = Array.from({ length: 31 }, (_, i) => (i + 1).toString().padStart(2, '0'));
  const months = [
    { label: 'January', value: '01' },
    { label: 'February', value: '02' },
    { label: 'March', value: '03' },
    { label: 'April', value: '04' },
    { label: 'May', value: '05' },
    { label: 'June', value: '06' },
    { label: 'July', value: '07' },
    { label: 'August', value: '08' },
    { label: 'September', value: '09' },
    { label: 'October', value: '10' },
    { label: 'November', value: '11' },
    { label: 'December', value: '12' },
  ];
  const years = Array.from({ length: 100 }, (_, i) => (new Date().getFullYear() - i).toString());

  const [appointmentState, setAppointment] = useState<AppointmentState>({
    appointmentID: '',
    appointmentDate: '',
    visitType: 'prebook',
    visitService: 'in-person',
    selectedSlot: undefined,
    locationID: '',
    providerID: '',
    groupID: '',
    scheduleType: 'location',
    timezone: 'America/New_York',
  });

  const updatedLocations = locations.map((loc) => {
    const { name } = loc;

    let locWaitingTime: number | string | null = null;

    if (loc?.extension) {
      const waitingTime = loc.extension.find((ext: { url: string }) => ext.url === 'average-waiting-time');
      locWaitingTime = waitingTime?.valueDuration?.value || null;
    }

    const label = `${name} ${locWaitingTime ? `- Est. Wait ${Math.round(Number(locWaitingTime))} Min` : ''}`;

    return {
      ...loc,
      name: label,
    };
  });

  const updatedProviders = providers.map((prov) => {
    const { name } = prov;

    let provSessionTime: number | string | null = null;

    if (prov?.extension) {
      const sessionTime = prov.extension.find((ext: { url: string }) => ext.url === 'average-session-time');
      provSessionTime = sessionTime?.valueDuration?.value || null;
    }

    const label = `${name?.[0]?.given?.[0]} ${name?.[0]?.family} ${
      provSessionTime ? `- Est. Session ${Math.round(Number(provSessionTime))} Min` : ''
    }`;

    return {
      ...prov,
      name: label,
    };
  });

  return (
    <Form
      onSubmit={async (formData: Record<string, string>): Promise<void> => {
        try {
          if (!day || !month || !year) {
            showNotification({ color: 'red', message: 'Please select a valid date of birth' });
            return;
          }

          if (!location) {
            showNotification({ color: 'red', message: 'Please select a location' });
            return;
          }

          // if (!provider) {
          //   showNotification({ color: 'red', message: 'Please select a provider' });
          //   return;
          // }

          if (!appointmentState.selectedSlot) {
            props.setChoiceErrorDialogOpen(true);
            return;
          }

          const dayStr = day.toString().length === 1 ? '0' + day.toString() : day;
          const dateOfBirth = `${year}-${month}-${dayStr}`;
          // let recaptchaToken = '';
          // if (recaptchaSiteKey) {
          //   recaptchaToken = await getRecaptcha(recaptchaSiteKey);
          // }
          // props.handleAuthResponse(
          // await medplum.startRegisterPatient({
          //   firstName: formData.firstName,
          //   lastName: formData.lastName,
          //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //   // @ts-ignore
          //   userName: formData.userName,
          //   phone: formData.phone,
          //   email: formData.email,
          //   password: formData.password,
          //   organization: props.organization,
          //   // recaptchaSiteKey,
          //   // recaptchaToken,
          // })
          // );

          const { patients = [] } = await medplum.post(`public/patients`, {
            phone: `+${mobilePhone}`,
          });

          if (patients.length > 0) {
            notifications.show({
              title: 'Patient Already Exists',
              message: 'Patient already exists with the provided phone number',
              color: 'red',
            });
          } else {
            // const { firstName, lastName, phone, email } = formData;
            const { firstName, lastName, email } = formData;

            // const utcDateTime = appointmentState.selectedSlot
            //   ? new Date(appointmentState.selectedSlot).toISOString()
            // : ''; // Outputs: 2024-10-21T18:00:00.000Z
            // console.log('appointmentState.selectedSlot', appointmentState.selectedSlot);
            let startDate: Date | string = appointmentState.selectedSlot
              ? new Date(appointmentState.selectedSlot)
              : new Date();
            if (appointmentState.selectedSlot) {
              const date = new Date(appointmentState.selectedSlot);
              if (!isValidDate(date)) {
                // If the input string was malformed, handle the error appropriately.
                throw new Error('Invalid date');
              }
              // console.log('date.toISOString()', date.toISOString());

              startDate = date.toISOString();
            }

            // console.log('startDate', startDate);
            // console.log('date.toISOString()', date.toISOString());

            await medplum.startRegisterPatientV2({
              firstName,
              lastName,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              userName: `${firstName} ${lastName}`,
              phone: `+${mobilePhone}`,
              email,
              birthDate: dateOfBirth,
              password: 'kdbjdsvjsdvjsdvjvgsdivsdgviug',
              selectedSlot: startDate,
              locationID: location,
              providerID: provider,
              visitType: appointmentState.visitType,
              reason,
              extension: [
                {
                  url: 'http://hl7.org/fhir/StructureDefinition/timezone', // Custom extension for time zone
                  valueString: appointmentState.timezone || 'America/New_York', // Time zone identifier
                },
              ],
              // organization: props.organization,
              // recaptchaSiteKey,
              // recaptchaToken,
            });

            showNotification({ color: 'green', message: 'Patient registration successful' });

            // setOutcome(undefined);
            // setDay('');
            // setMonth('');
            // setYear('');
            // setLocation('');
            // setProvider('');
            // setReason('');
            // setAppointment({
            //   appointmentID: '',
            //   appointmentDate: '',
            //   visitType: 'prebook',
            //   visitService: 'in-person',
            //   selectedSlot: '',
            //   locationID: '',
            //   providerID: '',
            //   groupID: '',
            //   scheduleType: 'location',
            //   timezone: '',
            // });
            navigate('/get-started');
          }
        } catch (err) {
          setOutcome(normalizeOperationOutcome(err));
          showNotification({ color: 'red', message: normalizeErrorString(err) });
        }
      }}
    >
      <OperationOutcomeAlert issues={issues} />
      <Stack gap="xl">
        <Grid container spacing={4} alignItems={'flex-end'}>
          <Grid item xs={12} md={6}>
            <TextInput
              name="firstName"
              type="text"
              label="First Name"
              placeholder="First Name"
              required={true}
              autoFocus={true}
              size="lg"
              error={getErrorsForInput(outcome, 'firstName')}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              name="lastName"
              type="text"
              label="Last name"
              placeholder="Last name"
              required={true}
              size="lg"
              error={getErrorsForInput(outcome, 'lastName')}
            />
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <TextInput
              name="userName"
              type="text"
              label="User name"
              placeholder="User name"
              required={true}
              error={getErrorsForInput(outcome, 'userName')}
            />
          </Grid> */}
          {/* <Grid container xs={12} md={12} spacing={2}> */}

          <Grid item xs={12} md={4}>
            <InputLabel>Date Of Birth</InputLabel>
            {/* <InputLabel>Date of Birth</InputLabel> */}
            <FormControl variant="outlined" fullWidth>
              {/* <InputLabel>Please select a month</InputLabel> */}
              <Select
                value={month}
                // onChange={(e) => setMonth(e.target.value)}
                onChange={(e) => setMonth(e || '')}
                label="Month"
                // fullWidth
                placeholder="Please select a month"
                data={months}
                size="lg"
              >
                {/* {months.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))} */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl variant="outlined" fullWidth>
              {/* <FormLabel>Day</FormLabel> */}
              {/* <InputLabel>Please select a day</InputLabel> */}
              <Select
                value={day}
                // onChange={(e) => setDay(e.target.value)}
                onChange={(e) => setDay(e || '')}
                label="Day"
                placeholder="Please select a month"
                // variant="outlined"
                data={days}
                size="lg"
              >
                {/* {days.map((d) => (
                  <MenuItem key={d} value={d}>
                    {d}
                  </MenuItem>
                ))} */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl variant="outlined" fullWidth>
              {/* <InputLabel>Please select a year</InputLabel> */}
              <Select
                value={year}
                //  onChange={(e) => setYear(e.target.value)}
                onChange={(e) => setYear(e || '')}
                label="Year"
                placeholder="Please select a year"
                // variant="outlined"
                data={years}
                size="lg"
              >
                {/* {years.map((y) => (
                  <MenuItem key={y} value={y}>
                    {y}
                  </MenuItem>
                ))} */}
              </Select>
            </FormControl>
          </Grid>
          {/* </Grid> */}
          <Grid item xs={12} md={6}>
            {/* <TextInput
              name="phone"
              type="text"
              label="Phone Number"
              placeholder="(000) 000-0000"
              required={true}
              size="lg"
              error={getErrorsForInput(outcome, 'phone')}
            /> */}

            <PatternFormat
              customInput={TextField}
              value={mobilePhone}
              format="+(###) ###-#####" // 177 028-24845
              mask=" "
              label="Mobile Phone"
              variant="outlined"
              placeholder="(XXX) XXX-XXXX"
              fullWidth
              required
              error={errors.phone}
              helperText={errors?.phone ? phoneNumberErrorMessage : ''}
              onValueChange={(values, sourceInfo) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
                if (sourceInfo.source === 'event') {
                  setMobilePhone(values.value);
                  if (errors.phone && values.value.length === 10) {
                    setErrors({ ...errors, phone: false });
                  }
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              name="email"
              type="email"
              label="Email"
              placeholder="ex: myname@example.com"
              required={true}
              size="lg"
              error={getErrorsForInput(outcome, 'email')}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth>
              {/* <InputLabel>Do you have a preferred location?</InputLabel> */}
              <Select
                value={location}
                onChange={(e, option) => {
                  setProvider(null);
                  setAppointment({
                    ...appointmentState,
                    locationID: e || '',
                    providerID: '',
                    timezone: (option as any)?.timezone,
                  });

                  const hoursOfOperation = (option as any)?.hoursOfOperation || [];
                  setHoursOfOperation(hoursOfOperation);

                  if (typeof props.setProviders === 'function') {
                    props.setProviders((option as any)?.practitioners || []);
                  }
                  // setLocation(e.target.value);
                  setLocation(e || '');
                }}
                label="Do you have a preferred location?"
                // variant="outlined"
                size="lg"
                data={updatedLocations.map(({ id, name, practitioners, hoursOfOperation = [], timezone }) => ({
                  value: id,
                  label: name,
                  practitioners,
                  hoursOfOperation,
                  timezone,
                }))}
              >
                {/* {locations.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))} */}
              </Select>
            </FormControl>
          </Grid>
          {/* {location && ( */}
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth>
              {/* <InputLabel>Do you have a preferred provider?</InputLabel> */}
              <Select
                value={provider}
                disabled={!location}
                // onChange={(e) => setProvider(e.target.value)}
                onChange={(e) => {
                  setProvider(e || null);
                }}
                label="Provider"
                // variant="outlined"
                size="lg"
                data={updatedProviders.map(({ id, name, hoursOfOperation = [] }) => ({
                  value: id,
                  label: name,
                  hoursOfOperation,
                }))}
              >
                {/* {providers.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name[0].given[0] + ' ' + name[0].family}
                    </MenuItem>
                  ))} */}
              </Select>
            </FormControl>
          </Grid>
          {/* )} */}
          {/* <Grid item xs={12} md={6}>
            <PasswordInput
              name="password"
              label="Password"
              autoComplete="off"
              required={true}
              size="lg"
              error={getErrorsForInput(outcome, 'password')}
            />
          </Grid> */}
          <Grid item md={12}>
            <ScheduleComponent
              appointmentState={appointmentState}
              setAppointment={setAppointment}
              hoursOfOperation={hoursOfOperation}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            {/* <FormControl variant="outlined" fullWidth> */}
            <InputLabel>Reason for your visit?</InputLabel>
            <TextareaAutosize
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              minRows={6}
              style={{ width: '100%' }}
            />
            {/* <Select
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                label="Location"
                variant="outlined"
                size="medium"
              >
                {locations.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select> */}
            {/* </FormControl> */}
          </Grid>
          <Grid item xs={12} md={12}>
            <Checkbox
              checked={isAgreed}
              onChange={(e) => setIsAgreed(e.target.checked)}
              name="remember"
              label="I agree to receive marketing messaging from YNDR at the phone number provided above. Data rates may apply, reply STOP to opt out."
              size="xs"
            />
            <br />
            <Text c="dimmed" size="xs" px={27}>
              By clicking submit you agree to the YNDR{' '}
              <Anchor href="/privacy-policy" target="_blank">
                Privacy&nbsp;Policy
              </Anchor>
              {/* {' and '} */}
              {/* <Anchor href="https://www.medplum.com/terms">Terms&nbsp;of&nbsp;Service</Anchor>. */}
            </Text>
          </Grid>
        </Grid>
      </Stack>
      <Group justify="end" mt="xl" wrap="nowrap">
        {/* <Checkbox name="remember" label="Remember me" size="xs" /> */}
        <Button type="submit" disabled={!isAgreed}>
          Submit
        </Button>
      </Group>
    </Form>
  );
}
