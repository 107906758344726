import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import dayjs from 'dayjs';
import { Calendar, dayjsLocalizer, Event } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Avatar, Button, Divider, Grid, Group, Text, Tooltip } from '@mantine/core';
import './calendar.css';
import { useDisclosure } from '@mantine/hooks';
import AppointmentModal from './appointmentsDetails';
import { Header } from './Header';
import AppointmentNotesDrawer from './notes';
import { unverifiedLogo } from '../../assets';

// const localizer = dayjsLocalizer(dayjs);

interface CalendarViewProps {
  // events: Event[];
  // onSelectEvent: Dispatch<SetStateAction<Event | null>>;
  slots: { title: string; start: Date; end: Date; resource: any }[];
  appointments: { title: string; start: Date; end: Date; resource: any }[];
  selectedEvent: Event | null;
  setSelectedEvent: Dispatch<SetStateAction<Event | null>>;
  view: 'day' | 'week' | 'month';
  setView: Dispatch<SetStateAction<'day' | 'month' | 'week'>>;
  selectedDate: Date;
  setSelectedDate: Dispatch<SetStateAction<Date>>;
  open: () => void;
  setFetchAppointments: Dispatch<SetStateAction<boolean>>;
}

const CalendarView: React.FC<CalendarViewProps> = ({
  // events,
  // onSelectEvent,
  slots,
  // appointments,
  selectedEvent,
  setSelectedEvent,
  view,
  setView,
  selectedDate,
  // setSelectedDate,
  open,
  setFetchAppointments,
}) => {
  const [createSlotOpened, createSlotHandlers] = useDisclosure(false);
  const [notesDrawerOpened, notesDrawerHandlers] = useDisclosure(false);
  // const navigate = useNavigate();

  useEffect(() => {
    if (!notesDrawerOpened || !createSlotOpened) {
      setFetchAppointments((prev) => !prev);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notesDrawerOpened, createSlotOpened]);

  // Converts Slot resources to big-calendar Event objects
  // Only show free and busy-unavailable slots
  // const slotEvents: Event[] = (slots ?? [])
  //   .filter((slot: { status: string }) => slot.status === 'free' || slot.status === 'busy-unavailable')
  //   .map((slot: { status: string; start: string | number | Date; end: string | number | Date }) => ({
  //     title: slot.status === 'free' ? 'Available' : 'Blocked',
  //     start: new Date(slot.start),
  //     end: new Date(slot.end),
  //     resource: slot,
  //   }));

  // Converts Appointment resources to big-calendar Event objects
  // const appointmentEvents: Event[] = (appointments ?? []).map(
  //   (appointment: { participant: any[]; status: string; start: string; end: string }) => {
  //     // Find the patient among the participants to use as title
  //     const patientParticipant = appointment?.participant?.find((p: any) => p.actor?.reference?.startsWith('Patient/'));
  //     const status = !['booked', 'arrived', 'fulfilled'].includes(appointment.status as string)
  //       ? ` (${appointment.status})`
  //       : '';

  //     return {
  //       title: `${patientParticipant?.actor?.display} ${status}`,
  //       start: new Date(appointment.start as string),
  //       end: new Date(appointment.end as string),
  //       resource: appointment,
  //     };
  //   }
  // );

  // When a slot is selected set the event object and open the modal
  const handleSelectSlot = useCallback(
    (event: Event) => {
      setSelectedEvent(event);
      createSlotHandlers.open();
    },
    [createSlotHandlers, setSelectedEvent]
  );

  // When an exiting event is selected set the event object and open the modal
  const handleSelectEvent = useCallback(
    (event: Event) => {
      if (event.resource.resourceType === 'Slot') {
        // If it's a slot open the management modal
        setSelectedEvent(event);
        createSlotHandlers.open();
      } else if (event.resource.resourceType === 'Appointment') {
        // If it's an appointment navigate to the appointment detail page
        // navigate(`/Appointment/${event.resource.id}`);
      }
    },
    [createSlotHandlers, setSelectedEvent]
  );

  // const localizer = dateFnsLocalizer({
  //   format: (date: string | number | Date | dayjs.Dayjs | null | undefined, format: string | undefined, culture: any) =>
  //     dayjs(date).format(format),
  //   parse: (
  //     value: string | number | Date | dayjs.Dayjs | null | undefined,
  //     format: dayjs.OptionType | undefined,
  //     culture: any
  //   ) => dayjs(value, format).toDate(),
  //   startOfWeek: () => dayjs().startOf('week').toDate(),
  //   getDay: (date: string | number | Date | dayjs.Dayjs | null | undefined) => dayjs(date).day(),
  // });

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const CustomDayView = ({ date, events }: { date: Date; events: Event[]; localizer: any }) => {
    // Generate hourly slots for the day
    // const hours = Array.from({ length: 24 }, (_, i) => dayjs(date).hour(i).minute(0).second(0).toDate());

    // Morning range: 9 AM to 12 PM
    const morningHours = Array.from({ length: 4 }, (_, i) =>
      dayjs(date)
        .hour(9 + i)
        .minute(0)
        .second(0)
        .toDate()
    );

    // console.log('morningHours', morningHours);

    // Afternoon-to-next-morning range: 1 PM to 8 AM
    const afternoonHours = Array.from({ length: 20 }, (_, i) => {
      if (i < 12) {
        i = 13 + i;
      } else if (i >= 8) {
        i = i - 11;
      }

      return dayjs(date).hour(i).minute(0).second(0).toDate();
    });

    // console.log('afternoonHours', afternoonHours);

    // Combine the two ranges
    const hours = [...morningHours, ...afternoonHours];

    // Filter events for the current day
    const dayEvents = events.filter((event: Event) => dayjs(event.start).isSame(date, 'day'));

    return (
      <div style={{ padding: '20px' }}>
        {hours.map((hour, index) => {
          const hourEvents = dayEvents.filter((event: Event) => event.start && dayjs(event.start).isSame(hour, 'hour'));
          return (
            <div
              key={index}
              style={{
                padding: '10px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div style={{ fontWeight: 'bold', display: 'flex', gap: '10px', height: 'auto' }}>
                <div className="time-div">{dayjs(hour).format('hA')}</div>
                <div className="add-btn-div">
                  {hourEvents.length === 0 && (
                    <button className="ActionButton" onClick={open}>
                      + Add Schedules
                    </button>
                  )}
                </div>
              </div>

              <div style={{ fontWeight: 'bold', display: 'flex', gap: '10px' }}>
                <div style={{ opacity: 0 }}>{dayjs(hour).format('hA')}</div>
                <div style={{ width: '100%' }}>
                  {hourEvents.map((event) => {
                    const { practitioner, patient, notes = [] } = event.resource;
                    const pracAvatarName = practitioner?.display
                      ?.split(' ')
                      .map((name: any[]) => name[0])
                      .join('');

                    const bgColor = event.resource?.practitioner?.backgroundColor;
                    const color = event.resource?.practitioner?.color;
                    // const isUnverified = patient?.insuranceStatus !== 'Verified';
                    // console.log('patient?.insuranceStatus', patient?.insuranceStatus, isUnverified)

                    return (
                      <Grid
                        bg={bgColor}
                        color={color}
                        my={10}
                        py={10}
                        px={5}
                        w="100%"
                        style={{
                          borderRadius: '5px',
                          border: '1px solid #006DB1',
                          cursor: 'pointer',
                        }}
                        justify="space-between"
                        grow
                      >
                        <Grid.Col span={10}>
                          <div
                            // key={event.id}
                            onClick={() => {
                              setSelectedEvent(event);
                              handleSelectSlot(event);
                            }}
                          >
                            <div style={{ fontWeight: 'bold', fontSize: '14px', color, display: 'inline-flex' }}>
                              {event.title}
                              {patient?.insuranceStatus === 'Unverified' && (
                                <Tooltip label="Unverified">
                                  <Avatar src={unverifiedLogo} size="xs" mt={4} ml={10} />
                                </Tooltip>
                              )}
                            </div>
                            <div
                              style={{
                                color,
                                fontSize: '13px',
                                fontWeight: '600',
                              }}
                            >
                              {`${dayjs(event.start).format('h:mm A')} - ${dayjs(event.end).format('h:mm A')}`}
                            </div>
                            <div>
                              <Group mt={10}>
                                <Avatar
                                  src={practitioner?.photo}
                                  alt={practitioner?.display}
                                  radius="xl"
                                  size="sm"
                                  // style={{ marginRight: '5px' }}
                                >
                                  {pracAvatarName}
                                </Avatar>
                                <Text
                                  fw={500}
                                  size="md"
                                  style={{
                                    color,
                                  }}
                                >
                                  {practitioner?.display}
                                </Text>
                              </Group>
                            </div>
                          </div>
                        </Grid.Col>
                        <Grid.Col span={2}>
                          <Button
                            variant="transparent"
                            color={color}
                            onClick={(e) => {
                              e.stopPropagation();
                              notesDrawerHandlers.open();
                              setSelectedEvent(event);
                            }}
                          >
                            {notes.length > 0 ? `View ${notes.length} Note${notes.length > 1 ? 's' : ''}` : `Add Note`}
                          </Button>
                        </Grid.Col>
                      </Grid>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  // Add the required static methods
  CustomDayView.title = (date: Date, { localizer }: any) => {
    return localizer.format(date, 'dayHeaderFormat');
  };

  CustomDayView.navigate = (date: Date, action: any) => {
    switch (action) {
      case 'PREV':
        return dayjs(date).subtract(1, 'day').toDate();
      case 'NEXT':
        return dayjs(date).add(1, 'day').toDate();
      default:
        return date;
    }
  };

  CustomDayView.allDay = false;

  return (
    <>
      {notesDrawerOpened && selectedEvent && (
        <AppointmentNotesDrawer
          appointment={selectedEvent}
          onClose={notesDrawerHandlers.close}
          onUpdate={notesDrawerHandlers.close}
          opened={notesDrawerOpened}
          setFetchAppointments={setFetchAppointments}
        />
      )}
      {createSlotOpened && selectedEvent && (
        <AppointmentModal
          appointment={selectedEvent}
          onClose={createSlotHandlers.close}
          onUpdate={createSlotHandlers.close}
          setFetchAppointments={setFetchAppointments}
        />
      )}
      <div style={{ flex: 1, padding: '20px' }}>
        <div style={{ border: '1px solid #5FB6E6', padding: '0px 16px', borderRadius: '10px' }}>
          <Header selectedDate={selectedDate} view={view} />
          <Divider my={20} />
          <Calendar
            defaultView="day"
            className={`custom-calendar-view-${view}`}
            view={view}
            // views={['day', 'week', 'month']}
            views={{
              day: CustomDayView,
              week: true,
              month: true,
            }}
            onView={(e) => setView(e as 'day' | 'week' | 'month')}
            localizer={dayjsLocalizer(dayjs)}
            events={slots}
            date={selectedDate}
            backgroundEvents={slots}
            onSelectSlot={handleSelectSlot}
            onSelectEvent={handleSelectEvent}
            style={{ minHeight: 600 }}
            selectable
            toolbar={false}
            // timeslots={1}
            startAccessor="start"
            endAccessor="end"
            eventPropGetter={(event) => {
              return {
                style: {
                  backgroundColor: event.resource?.practitioner?.backgroundColor,
                  color: event.resource?.practitioner?.color,
                },
              };
            }}
            // formats={{
            //   timeGutterFormat: (date, culture, localizer) => dayjs(date).format('h A'),
            // }}
          />
        </div>
      </div>
    </>
  );
};

export default CalendarView;
