import { createReference, normalizeErrorString } from '@medplum/core';
import { Patient, QuestionnaireResponse } from '@medplum/fhirtypes';
import { Document, QuestionnaireForm, useMedplum, useMedplumProfile, useResource } from '@medplum/react';
import { useCallback, useContext, useState } from 'react';
import { showNotification } from '@mantine/notifications';
import { Loading } from '../components/Loading';
import { useParams, useNavigate } from 'react-router-dom';
import { IconCircleCheck, IconCircleOff } from '@tabler/icons-react';
import ScheduleComponent from '../../pages/patient-intake-form-public/schedulecomponent';
import { IntakeQuestionnaireContext } from '../Questionnaire.context';
import { ErrorDialog } from '../../pages/patient-intake-form-public/components/ErrorDialog';
import { useTranslation } from 'react-i18next';

interface AppointmentState {
  appointmentID?: string;
  appointmentDate?: string;
  visitType?: 'prebook' | 'now';
  visitService?: 'in-person' | 'telemedicine';
  selectedSlot?: string;
  locationID?: string;
  providerID?: string;
  groupID?: string;
  scheduleType?: 'location' | 'provider';
  timezone: string;
}

export function IntakeFormPage(): JSX.Element {
  const navigate = useNavigate();
  const medplum = useMedplum();
  const profile = useMedplumProfile();

  const { patientId } = useParams();
  const patient = useResource<Patient>({ reference: `Patient/${patientId}` });
  const { questionnaire } = useContext(IntakeQuestionnaireContext);
  const [choiceErrorDialogOpen, setChoiceErrorDialogOpen] = useState(false);
  const { t } = useTranslation();

  const [appointmentState, setAppointment] = useState<AppointmentState>({
    appointmentID: '',
    appointmentDate: '',
    visitType: 'prebook',
    visitService: 'in-person',
    selectedSlot: '',
    locationID: '',
    providerID: '',
    groupID: '',
    scheduleType: 'location',
    timezone: '',
  });

  if (questionnaire?.item) {
    questionnaire.item[0].extension = [
      {
        url: 'http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl',
        valueCodeableConcept: {
          coding: [
            {
              system: 'http://hl7.org/fhir/questionnaire-item-control',
              code: 'page',
            },
          ],
        },
      },
    ];
  }

  const handleOnSubmit = useCallback(
    (response: QuestionnaireResponse) => {
      if (!questionnaire || !patient || !profile) {
        return;
      }

      const appointmentItem = {
        id: 'id-13',
        linkId: 'appointment-date',
        text: 'Appointment Date',
        answer: [
          {
            valueDateTime: appointmentState.selectedSlot?.toString(),
            id: appointmentState.timezone,
          },
        ],
        item: undefined,
      };

      const lastItem = response.item?.[0]?.item?.[response.item?.[0]?.item?.length - 1];

      if (lastItem?.linkId !== 'appointment-date') {
        response.item?.[0]?.item?.push(appointmentItem);
      }

      medplum
        .createResource<QuestionnaireResponse>({
          ...response,
          subject: createReference(patient),
          author: createReference(profile),
        })
        .then(() => {
          showNotification({
            icon: <IconCircleCheck />,
            title: 'Success',
            message: 'Answers recorded',
          });
          navigate(`/Patient/${patient.id}`);
          window.scrollTo(0, 0);
        })
        .catch((err) => {
          showNotification({
            color: 'red',
            icon: <IconCircleOff />,
            title: 'Error',
            message: normalizeErrorString(err),
          });
        });
    },
    // [medplum, navigate, questionnaire, profile, patient]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [medplum, navigate, profile, patient, appointmentState]
  );

  console.log('intake form questionnaire', questionnaire);

  if (!questionnaire) {
    return <Loading />;
  }

  return (
    <Document width={800}>
      <QuestionnaireForm
        questionnaire={questionnaire}
        onSubmit={handleOnSubmit}
        selectedSlot={appointmentState.selectedSlot}
        setChoiceErrorDialogOpen={setChoiceErrorDialogOpen}
        ScheduleComponent={
          <ScheduleComponent
            appointmentState={appointmentState}
            setAppointment={setAppointment}
            hoursOfOperation={[]}
          />
        }
      />
      <ErrorDialog
        open={choiceErrorDialogOpen}
        title={t('welcome.dateError.title')}
        description={t('welcome.dateError.description')}
        closeButtonText={t('general.button.close')}
        handleClose={() => setChoiceErrorDialogOpen(false)}
      />
    </Document>
  );
}
