import { Autocomplete, AutocompleteRenderInputParams, TextField } from '@mui/material';
import { ReactElement, useEffect, useMemo, useState } from 'react';

// import { FhirClient } from '@zapehr/sdk';
import { Location } from 'fhir/r4';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMedplum } from '@medplum/react';
import { MedplumClient } from '@medplum/core';
import { sortLocationsByLabel } from '../helpers';
// import { useApiClients } from '../hooks/useAppClients';

type CustomFormEventHandler = (event: React.FormEvent<HTMLFormElement>, value: any, field: string) => void;

interface LocationSelectProps {
  location?: Location | undefined;
  setLocation: (location: Location | undefined) => void;
  updateURL?: boolean;
  storeLocationInLocalStorage?: boolean;
  required?: boolean;
  queryParams?: URLSearchParams;
  handleSubmit?: CustomFormEventHandler;
  renderInputProps?: Partial<AutocompleteRenderInputParams>;
}

enum LoadingState {
  initial,
  loading,
  loaded,
}

export default function LocationSelect({
  queryParams,
  location,
  handleSubmit,
  setLocation,
  updateURL,
  storeLocationInLocalStorage,
  required,
  renderInputProps,
}: LocationSelectProps): ReactElement {
  // const { fhirClient } = useApiClients();
  const medplum = useMedplum();
  const [locations, setLocations] = useState<Location[]>([]);
  const [loadingState, setLoadingState] = useState(LoadingState.initial);
  const navigate = useNavigate();
  const locationHandler = useLocation();
  useEffect(() => {
    if (updateURL && localStorage.getItem('selectedLocation')) {
      queryParams?.set('location', JSON.parse(localStorage.getItem('selectedLocation') ?? '')?.id);
      navigate(`?${queryParams?.toString()}`);
    }
  }, [navigate, queryParams, updateURL]);

  useEffect(() => {
    async function getLocationsResults(medplum: MedplumClient): Promise<void> {
      if (!medplum) {
        return;
      }

      setLoadingState(LoadingState.loading);

      try {
        const locationsResults = await medplum.searchResources('Location', {
          _count: 100000,
          _sort: 'name',
        });

        setLocations(locationsResults);
      } catch (e) {
        console.error('error loading locations', e);
      } finally {
        setLoadingState(LoadingState.loaded);
      }
    }

    if (medplum && loadingState === LoadingState.initial) {
      // eslint-disable-next-line no-void
      void getLocationsResults(medplum);
    }
  }, [loadingState, medplum]);

  const options = useMemo(() => {
    const allLocations = locations.map((location) => {
      const locationWaitingExtension = location.extension?.find((ext) => ext.url === 'average-waiting-time');
      if (locationWaitingExtension) {
        const value = locationWaitingExtension?.valueDuration?.value ?? undefined;
        const averageWaitingTime = value !== undefined && !isNaN(value) ? Math.round(value) : 0;

        if (averageWaitingTime > 0) {
          return {
            label: `${location.name} - Est. Wait ${averageWaitingTime} min`,
            value: location.id,
          };
        }
      }
      return { label: location.name, value: location.id };
    });

    return sortLocationsByLabel(allLocations as { label: string; value: string }[]);
  }, [locations]);

  const handleLocationChange = (event: any, newValue: any): void => {
    const selectedLocation = newValue
      ? locations.find((locationTemp) => locationTemp.id === newValue.value)
      : undefined;
    console.log('selected location in handle location change', selectedLocation);
    if (selectedLocation?.id) {
      localStorage.setItem('selectedLocation', selectedLocation.id);
    } else {
      localStorage.removeItem('selectedLocation');
      localStorage.removeItem('selectedLocationID');
      const queryParams = new URLSearchParams(locationHandler.search);
      queryParams.delete('location');
      setTimeout(() => {
        navigate(`?${queryParams?.toString()}`);
      }, 1000);
    }
    setLocation(selectedLocation);

    if (storeLocationInLocalStorage) {
      if (newValue) {
        localStorage.setItem('selectedLocation', JSON.stringify(selectedLocation));
      } else {
        localStorage.removeItem('selectedLocation');
      }
    }

    if (handleSubmit) {
      handleSubmit(event, selectedLocation, 'location');
    }
  };

  return (
    <Autocomplete
      disabled={renderInputProps?.disabled}
      value={location ? { label: location.name, value: location?.id } : null}
      onChange={handleLocationChange}
      isOptionEqualToValue={(option, tempValue) => option.value === tempValue.value}
      options={options}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.value}>
            {option.label}
          </li>
        );
      }}
      fullWidth
      renderInput={(params) => (
        <TextField placeholder="Search office" name="location" {...params} label="Office" required={required} />
      )}
    />
  );
}
