import { Center } from '@mantine/core';
import { Button } from '@mui/material';

import { useNavigate } from 'react-router-dom';

export function UserRegisterForm(props: any): JSX.Element {
  const navigate = useNavigate();

  const handleNavigate = (location: string): void => {
    if (location === 'register') {
      navigate('/register');
    } else {
      // navigate('/');
      window.location.href = '/';
    }
  };

  return (
    <>
      <Center style={{ flexDirection: 'column' }}>{props.children}</Center>
      <div
        style={{
          margin: '30px 0px',
        }}
      >
        <Button onClick={() => handleNavigate('register')} fullWidth type="button" variant="outlined">
          REGISTER
        </Button>

        <Button
          variant="outlined"
          style={{
            margin: '24px 0px',
          }}
          onClick={() => handleNavigate('login')}
          fullWidth
          type="button"
        >
          LOGIN
        </Button>

        {/* <Button onClick={() => navigate('/select-organization')} fullWidth type="button" variant="outlined">
          INTAKE FORM
        </Button> */}

        <Button onClick={() => navigate('/intake-form')} fullWidth type="button" variant="outlined">
          INTAKE FORM
        </Button>
      </div>
    </>
  );
}
